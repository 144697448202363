import { IAppState } from '../app.state';

export const leaderboardgcigraph = (state: IAppState) => state.group.leaderboardgcigraph;
export const leaderboardgcigraphProcessing = (state: IAppState) => state.group.leaderboardgcigraphProcessing;
export const leaderboardnumberofsalesgraph = (state: IAppState) => state.group.leaderboardnumberofsalesgraph;
export const leaderboardnumberofsalesgraphProcessing = (state: IAppState) => state.group.leaderboardnumberofsalesgraphProcessing;
export const leaderboardgcistatestaff = (state: IAppState) => state.group.leaderboardgcistatestaff;
export const leaderboardgcistatestaffProcessing = (state: IAppState) => state.group.leaderboardgcistatestaffProcessing;
//export const leaderboardpdfupload = (state: IAppState) => state.group.leaderboardpdfupload;
export const leaderboardpdfuploadProcessing = (state: IAppState) => state.group.leaderboardpdfuploadProcessing;
export const leaderboardpdfdeleteProcessing = (state: IAppState) => state.group.leaderboardpdfdeleteProcessing;
export const leaderboardpdfshow = (state: IAppState) => state.group.leaderboardpdfshow;
export const leaderboardpdfshowProcessing = (state: IAppState) => state.group.leaderboardpdfshowProcessing;

export const leaderboardquotesaveProcessing = (state: IAppState) => state.group.leaderboardquotesaveProcessing;
export const leaderboardquotedeleteProcessing = (state: IAppState) => state.group.leaderboardquotedeleteProcessing;

export const leaderboardquoteshow = (state: IAppState) => state.group.leaderboardquoteshow;
export const leaderboardquoteshowProcessing = (state: IAppState) => state.group.leaderboardquoteshowProcessing;

export const leaderboardpdfall = (state: IAppState) => state.group.leaderboardpdfall;
export const leaderboardpdfallProcessing = (state: IAppState) => state.group.leaderboardpdfallProcessing;

export const leaderboardquoteall = (state: IAppState) => state.group.leaderboardquoteall;
export const leaderboardquoteallProcessing = (state: IAppState) => state.group.leaderboardquoteallProcessing;

export const statewisenumberofnewlistings = (state: IAppState) => state.group.statewisenumberofnewlistings;
export const statewisenumberofnewlistingsProcessing = (state: IAppState) => state.group.statewisenumberofnewlistingsProcessing;
export const newlistingdetails = (state: IAppState) => state.group.newlistingdetails;
export const newlistingdetailsProcessing = (state: IAppState) => state.group.newlistingdetailsProcessing;
export const statewisenumberofsoldlistings = (state: IAppState) => state.group.statewisenumberofsoldlistings;
export const statewisenumberofsoldlistingsProcessing = (state: IAppState) => state.group.statewisenumberofsoldlistingsProcessing;
export const soldlistingdetails = (state: IAppState) => state.group.soldlistingdetails;
export const soldlistingdetailsProcessing = (state: IAppState) => state.group.soldlistingdetailsProcessing;
export const actualvsbudget = (state: IAppState) => state.group.actualvsbudget;
export const actualvsbudgetProcessing = (state: IAppState) => state.group.actualvsbudgetProcessing;
export const errorMessage = (state: IAppState) => state.group.errorMessage;
