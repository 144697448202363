import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, concat, flatMap, map } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { resolve } from 'url';
import { AuthService, ILogoutResponse } from './auth.service';
import { ILocalStorage } from './../util/encryption/encryption.Service';
import { ServiceResponse } from '../app.state';

interface IAuthUser extends ILocalStorage {
}

@Injectable()
export class HttpAuthService implements AuthService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

  public login(
    agencyKey: string,
    username: string,
    password: string,
    group: string
  ): Observable<ILocalStorage> {
    console.log("this.activatedRoute =====> ", this.activatedRoute);
    console.log("http auth service");
    return this.httpClient
      .post<ServiceResponse<IAuthUser>>('/api/auth/Authenticate', {
        agencyKey: agencyKey,
        username: username,
        password: password,
        isGroup: (group ?? "").toLowerCase() == "group"
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          if (data.response.isAuthenticated) {
            return {
              sessionID: data.response.sessionID,
              group: group,
              agencyID: data.response.agencyID,
              agencyKey: data.response.agencyKey,
              agencyImageUrl: data.response.agencyImageUrl,
              userName: data.response.userName,
              logon: data.response.logon,
              marketingGroupDashboardID: data.response.marketingGroupDashboardID,
              isAuthenticated: data.response.isAuthenticated,
            }
          }
          else {
            throw new Error("Invalid username or password!!")
          }
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`Error in login: ${data}`);
        }
      })
      );
  }
  public logout(agencyKey: string, sessionID: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.httpClient
      .post<ServiceResponse<ILogoutResponse>>('/api/auth/Logout', { agencyKey: agencyKey, sessionID: sessionID }, options)
      .pipe(
        map((data) => {
          if (!!data && !data.isError && !!data.response) {
            if (data.response.success) {
              return data.response;
            }
            else {
              throw new Error(data.response.message);
            }
            /*if (!data) {
              throw new Error("Logout Failed!")
            }
            else if (!!data.errorCode) {
              throw new Error(data.message);
            }
            else {
              return data;
            }*/
          }
          else if (!!data && data.isError) {
            throw new Error(data.message);
          }
          else {
            throw new Error("Logout Failed!");
          }

        })
      );
  }

}
