import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable } from 'rxjs';
import { first, map } from "rxjs/operators";

import { IAppState } from '../app.state';
import IAuthState from '../auth/auth.state';
import { ComponentBase } from '../util/componentBase';
import { EncryptionService, enLoginMode } from '../util/encryption/encryption.Service';
import { isAuthenticated, logon, token } from "./../auth/auth.selectors";
import { AllowedReportAction } from './home.action';
import { allowedReports } from './home.selectors';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends ComponentBase implements OnInit {
  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
  ) {
    super(activatedRoute, snackBar, encryption);
  }
  private auth$: Observable<IAuthState>;
  private isAuthenticated$: Observable<boolean>;
  private logon$: Observable<enLoginMode>;
  private allowedReports$ = this.store.pipe(select(allowedReports));

  ngOnInit(): void {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.logon$ = this.encryption.logon$(auth.token);
      this.isAuthenticated$ = this.encryption.isAuthenticated$(auth.token);

      this.store.dispatch(
        new AllowedReportAction(
          this.agencyKey,
          this.sessionID,
          this.marketingGroupDashboardID
        )
      );

    }
  }
}
