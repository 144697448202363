import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from '../auth/auth.guard';
import { AgeOfListingsComponent } from './ageoflistings/ageoflistings.component';
import { SalesBudgetComponent } from './salesbudget/salesbudget.component';
import { AgeOfListingsChartComponent } from './ageoflistingschart/ageoflistingschart.component';
import { StaffPerformanceComponent } from './staffperformance/staffperformance.component';



const reportRoutes: Routes = [
  { path: 'ageoflistings', component: AgeOfListingsComponent, canActivate: [AuthGuard] },
  { path: 'group/salesbudget', component: SalesBudgetComponent, canActivate: [AuthGuard] },
  { path: 'agelistingschart', component: AgeOfListingsChartComponent, canActivate: [AuthGuard] },
  { path: 'staffperformance', component: StaffPerformanceComponent, canActivate: [AuthGuard] }
]

@NgModule({
  imports: [RouterModule.forChild(reportRoutes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
