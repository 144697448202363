import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth.guard';

const childRoutes: Routes = [];
const ROUTES: Routes = [
  /*{
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },*/
  {
    path: '**',
    redirectTo: '/'
  }
];
export { ROUTES };
export const AppRoutesModule = RouterModule.forRoot(ROUTES, {
  enableTracing: false
});
