import { IDNamePairExtra } from "../app.state";
import { ActionTypes, GroupAction } from "./group.action";
import { IGroupState } from "./group.state";

export const getInitialState = (): IGroupState => ({
  leaderboardgcigraph: [],
  leaderboardgcigraphProcessing: false,
  leaderboardnumberofsalesgraph: [],
  leaderboardnumberofsalesgraphProcessing: false,
  leaderboardgcistatestaff: [],
  leaderboardgcistatestaffProcessing: false,
  //leaderboardpdfupload: false,
  leaderboardpdfuploadProcessing: false,
  leaderboardpdfdeleteProcessing: false,
  leaderboardpdfshow: null,
  leaderboardpdfshowProcessing: false,

  leaderboardquotesaveProcessing: false,
  leaderboardquotedeleteProcessing: false,

  leaderboardquoteshow: null,
  leaderboardquoteshowProcessing: false,

  leaderboardpdfall: null,
  leaderboardpdfallProcessing: false,

  leaderboardquoteall: null,
  leaderboardquoteallProcessing: false,

  statewisenumberofnewlistings: null,
  statewisenumberofnewlistingsProcessing: false,
  newlistingdetails: null,
  newlistingdetailsProcessing: false,
  statewisenumberofsoldlistings: null,
  statewisenumberofsoldlistingsProcessing: false,
  soldlistingdetails: null,
  soldlistingdetailsProcessing: false,
  actualvsbudget: null,
  actualvsbudgetProcessing: false,
  errorMessage: null
});
export function groupReducer(
  state: IGroupState,
  action: GroupAction
): IGroupState {
  switch (action.type) {
    case ActionTypes.LeaderBoardGCIGraph: {
      return { ...state, leaderboardgcigraphProcessing: true };
    }
    case ActionTypes.LeaderBoardGCIGraphSuccess: {
      return {
        ...state,
        leaderboardgcigraph: action.data,
        leaderboardgcigraphProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardGCIGraphFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.LeaderBoardNumberOfSalesGraph: {
      return { ...state, leaderboardnumberofsalesgraphProcessing: true };
    }
    case ActionTypes.LeaderBoardNumberOfSalesGraphSuccess: {
      return {
        ...state,
        leaderboardnumberofsalesgraph: action.data,
        leaderboardnumberofsalesgraphProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardNumberOfSalesGraphFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.LeaderBoardGCIStateStaff: {
      return { ...state, leaderboardgcistatestaffProcessing: true };
    }
    case ActionTypes.LeaderBoardGCIStateStaffSuccess: {
      return {
        ...state,
        leaderboardgcistatestaff: action.data,
        leaderboardgcistatestaffProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardGCIStateStaffFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.LeaderBoardPdfUpload: {
      return { ...state, leaderboardpdfuploadProcessing: true };
    }
    case ActionTypes.LeaderBoardPdfUploadSuccess: {
      return {
        ...state,
        leaderboardpdfall: action.data,
        leaderboardpdfuploadProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardPdfUploadFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.LeaderBoardPdfDelete: {
      return { ...state, leaderboardpdfdeleteProcessing: true };
    }
    case ActionTypes.LeaderBoardPdfDeleteSuccess: {
      return {
        ...state,
        leaderboardpdfall: action.data,
        leaderboardpdfdeleteProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardPdfDeleteFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.LeaderBoardPdfShow: {
      return { ...state, leaderboardpdfshowProcessing: true };
    }
    case ActionTypes.LeaderBoardPdfShowSuccess: {
      return {
        ...state,
        leaderboardpdfshow: action.data,
        leaderboardpdfshowProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardPdfShowFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }


    case ActionTypes.LeaderBoardQuoteSave: {
      return { ...state, leaderboardquotesaveProcessing: true };
    }
    case ActionTypes.LeaderBoardQuoteSaveSuccess: {
      return {
        ...state,
        leaderboardquoteall: action.data,
        leaderboardquotesaveProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardQuoteSaveFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.LeaderBoardQuoteDelete: {
      return { ...state, leaderboardquotedeleteProcessing: true };
    }
    case ActionTypes.LeaderBoardQuoteDeleteSuccess: {
      return {
        ...state,
        leaderboardquoteall: action.data,
        leaderboardquotedeleteProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardQuoteDeleteFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.LeaderBoardQuoteShow: {
      return { ...state, leaderboardquoteshowProcessing: true };
    }
    case ActionTypes.LeaderBoardQuoteShowSuccess: {
      return {
        ...state,
        leaderboardquoteshow: action.data,
        leaderboardquoteshowProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardQuoteShowFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }

    case ActionTypes.LeaderBoardPdfAll: {
      return { ...state, leaderboardpdfallProcessing: true };
    }
    case ActionTypes.LeaderBoardPdfAllSuccess: {
      return {
        ...state,
        leaderboardpdfall: action.data,
        leaderboardpdfallProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardPdfAllFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }

    case ActionTypes.LeaderBoardQuoteAll: {
      return { ...state, leaderboardquoteallProcessing: true };
    }
    case ActionTypes.LeaderBoardQuoteAllSuccess: {
      return {
        ...state,
        leaderboardquoteall: action.data,
        leaderboardquoteallProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.LeaderBoardQuoteAllFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }

    case ActionTypes.StatewiseNumberOfNewListings: {
      return { ...state, statewisenumberofnewlistingsProcessing: true };
    }
    case ActionTypes.StatewiseNumberOfNewListingsSuccess: {
      return {
        ...state,
        statewisenumberofnewlistings: action.data,
        statewisenumberofnewlistingsProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.StatewiseNumberOfNewListingsFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.NewListingDetails: {
      return { ...state, newlistingdetailsProcessing: true };
    }
    case ActionTypes.NewListingDetailsSuccess: {
      return {
        ...state,
        newlistingdetails: action.data,
        newlistingdetailsProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.NewListingDetailsFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.StatewiseNumberOfSoldListings: {
      return { ...state, statewisenumberofsoldlistingsProcessing: true };
    }
    case ActionTypes.StatewiseNumberOfSoldListingsSuccess: {
      return {
        ...state,
        statewisenumberofsoldlistings: action.data,
        statewisenumberofsoldlistingsProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.StatewiseNumberOfSoldListingsFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.SoldListingDetails: {
      return { ...state, soldlistingdetailsProcessing: true };
    }
    case ActionTypes.SoldListingDetailsSuccess: {
      return {
        ...state,
        soldlistingdetails: action.data,
        soldlistingdetailsProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.SoldListingDetailsFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.ActualVsBudgetGraph: {
      return { ...state, actualvsbudgetProcessing: true };
    }
    case ActionTypes.ActualVsBudgetGraphSuccess: {
      return {
        ...state,
        actualvsbudget: action.data,
        actualvsbudgetProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.ActualVsBudgetGraphFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }


    // this is necessary since we are using local storage to hydrate part of the state
    case '@ngrx/store/update-reducers' as any: {
      return { ...getInitialState(), ...state };
    }

    case ActionTypes.RemoveErrorMessage:
    case '@ngrx/router-store/navigated' as any: {
      return { ...state, errorMessage: null };
    }

    default: {
      return state || getInitialState();
    }
  }
}
