import {
  Component,
  EventEmitter,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';

@Component({
  selector: 'multiarray-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true
    }
  ]
})
export class InputComponent implements OnInit, ControlValueAccessor {

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer?: ControlContainer
  ) { }

  @Input()
  public placeholder = '';
  @Input()
  public type = 'text';
  @Input()
  public formControlName: string;
  @Input()
  public autocomplete = 'off';
  @Input()
  public errors?: ValidationErrors;

  @Input()
  public set required(value: string | boolean) {
    this.isRequired = value !== 'false' && value !== false;
  }

  @Input()
  public set disabled(dis: boolean) {
    this.isDisabled = dis;
  }

  @Output()
  public valueChange = new EventEmitter<string>();

  public control?: AbstractControl;
  public isRequired = false;

  callback: (value: string) => void;
  touchedCallback: () => void;
  public isDisabled: boolean;

  @Input()
  public value = '';

  

  ngOnInit() {
    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer.control.get(this.formControlName);
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.callback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value || '';
    if (this.value !== '') {
      this.control.markAsDirty();
    }
  }

  onChanged(value: string, noEmit: boolean = false) {
    if (this.callback) {
      this.callback(value);
    }
    if (this.touchedCallback) {
      this.touchedCallback();
    }
    if (!noEmit) {
      this.valueChange.emit(value);
    }
  }

  focused() {
    if (this.touchedCallback) {
      this.touchedCallback();
    }
  }

  getError(unknown?: boolean): string {
    if (this.control && this.control.errors) {
      return Object.keys(this.control.errors)[0];
    } else if (this.errors) {
      return Object.keys(this.errors)[0];
    } else {
      return '';
    }
  }

  getPlaceholder(): string {
    if (!this.isRequired) {
      return this.placeholder;
    } else {
      return this.placeholder + '*';
    }
  }

}
