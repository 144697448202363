import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule, Actions } from '@ngrx/effects';

import { MaterialModule } from '../material.module';
import { ComponentsModule } from '../components/components.module';
import { ReportsService } from './reports.service';
import { HttpReportsService } from './http-reports.service';
import { ReportsEffects } from './reports.effects';
import { AgeOfListingsComponent } from './ageoflistings/ageoflistings.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { SalesBudgetComponent } from './salesbudget/salesbudget.component';
import { AgeOfListingsChartComponent } from './ageoflistingschart/ageoflistingschart.component';
import { StaffPerformanceComponent } from './staffperformance/staffperformance.component';


@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    ReportsRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    EffectsModule.forFeature([ReportsEffects])
  ],
  declarations: [
    AgeOfListingsComponent,
    SalesBudgetComponent,
    AgeOfListingsChartComponent,
    StaffPerformanceComponent
  ],  
  providers: [
    Actions,
    {
      provide: ReportsService,
      useClass: HttpReportsService
    }
  ]
})
export class ReportsModule { }
