import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { from, Observable, Observer, of } from "rxjs";
import { Action, select, Store } from "@ngrx/store";
import {
  catchError,
  filter,
  first,
  flatMap,
  map,
  mergeMap
} from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ActionTypes,
  AllowedReportAction,
  AllowedReportSuccessAction,
  AllowedReportFailAction,
  ReportDetailsAction,
  ReportDetailsSuccessAction,
  ReportDetailsFailAction,
  RemoveErrorMessageAction
} from "./home.action";
import { HomeService } from "./home.service";
import { IAppState } from "../app.state";

@Injectable()
export class HomeEffects {
  constructor(
    private store: Store<IAppState>,
    private homeService: HomeService,
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  @Effect()
  allowedReports$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.AllowedReport),
    mergeMap((action: AllowedReportAction) =>
      this.homeService
        .allowedReports(action.agencyKey, action.sessionID, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new AllowedReportSuccessAction(data)
          ),
          catchError(error => of(new AllowedReportFailAction(error.message)))
        )
    )
  );

  @Effect({ dispatch: false })
  loginSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.AllowedReportSuccess),
    mergeMap((action: AllowedReportAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new AllowedReportFailAction(error.message)))
  );

  @Effect()
  reportDetails$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.ReportDetails),
    mergeMap((action: ReportDetailsAction) =>
      this.homeService
        .reportDetails(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID, action.reportID)
        .pipe(
          map(
            data =>
              new ReportDetailsSuccessAction(action.reportID, data)
          ),
          catchError(error => of(new ReportDetailsFailAction(action.reportID, error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  reportDetailsSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.ReportDetailsSuccess),
    mergeMap((action: ReportDetailsSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new AllowedReportFailAction(error.message)))
  );

}
