import { Observable, of, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AppSettingService } from '../appsettings/appsettings.service';

@Injectable()
export class EncryptionService {
  private key: string = "multiarrayFree2015!";

  constructor(private apSettings: AppSettingService) { }

  public encryptLocalStorage(storage: ILocalStorage): string {
    let data = "sessionID: " + storage.sessionID
      + ", group: " + (!!storage.group ? storage.group : "")
      + ", agencyID: " + storage.agencyID
      + ", agencyKey: " + storage.agencyKey
      + ", agencyImageUrl: " + storage.agencyImageUrl
      + ", userName: " + storage.userName
      + ", logon: " + storage.logon
      + ", marketingGroupDashboardID: " + storage.marketingGroupDashboardID
      + ", isAuthenticated: " + storage.isAuthenticated
      + ", lastAccessed: " + (new Date().getTime());
    return CryptoJS.AES.encrypt(data, this.key).toString();
  }
  public decryptLocationStorage(token: string = undefined): ILocalStorage {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!token) {
      if (!!auth) {
        token = auth.token;
      }
    }
    if (!(!!token && !!auth && !!auth.token)) return;

    let data: string;
    try {
      data = CryptoJS.AES.decrypt(!!token ? token : auth.token, this.key).toString(CryptoJS.enc.Utf8);
    }
    catch (error) {
      // handel error here            
      console.error(error);
      return;
    }

    let properties: string[] = data.split(',');
    if (!!properties && properties.length != 10) {
      console.error("Error: Malformed UTF-8 data");
      localStorage.clear();
      return;
    }

    let sessionID: string;
    let group: string;
    let agencyID: string;
    let agencyKey: string;
    let agencyImageUrl: string;
    let userName: string;
    let logon: enLoginMode;
    let marketingGroupDashboardID: string;
    let isAuthenticated: boolean;
    let lastAccessed: Date;

    let properties0 = !!properties[0] ? properties[0].trim() : "";
    let properties1 = !!properties[1] ? properties[1].trim() : "";
    let properties2 = !!properties[2] ? properties[2].trim() : "";
    let properties3 = !!properties[3] ? properties[3].trim() : "";
    let properties4 = !!properties[4] ? properties[4].trim() : "";
    let properties5 = !!properties[5] ? properties[5].trim() : "";
    let properties6 = !!properties[6] ? properties[6].trim() : "";
    let properties7 = !!properties[7] ? properties[7].trim() : "";
    let properties8 = !!properties[8] ? properties[8].trim() : "";
    let properties9 = !!properties[9] ? properties[9].trim() : "";
    

    //sessionID
    if (properties0.startsWith("sessionID")) {
      sessionID = properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim());
    }
    else if (properties1.startsWith("sessionID")) {
      sessionID = properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim());
    }
    else if (properties2.startsWith("sessionID")) {
      sessionID = properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim());
    }
    else if (properties3.startsWith("sessionID")) {
      sessionID = properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim());
    }
    else if (properties4.startsWith("sessionID")) {
      sessionID = properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim());
    }
    else if (properties5.startsWith("sessionID")) {
      sessionID = properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim());
    }
    else if (properties6.startsWith("sessionID")) {
      sessionID = properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim());
    }
    else if (properties7.startsWith("sessionID")) {
      sessionID = properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim());
    }
    else if (properties8.startsWith("sessionID")) {
      sessionID = properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim());
    }
    else if (properties9.startsWith("sessionID")) {
      sessionID = properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim());
    }

    //group
    if (properties0.startsWith("group")) {
      sessionID = properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim());
    }
    else if (properties1.startsWith("group")) {
      group = properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim());
    }
    else if (properties2.startsWith("group")) {
      group = properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim());
    }
    else if (properties3.startsWith("group")) {
      group = properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim());
    }
    else if (properties4.startsWith("group")) {
      group = properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim());
    }
    else if (properties5.startsWith("group")) {
      group = properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim());
    }
    else if (properties6.startsWith("group")) {
      group = properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim());
    }
    else if (properties7.startsWith("group")) {
      group = properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim());
    }
    else if (properties8.startsWith("group")) {
      group = properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim());
    }
    else if (properties9.startsWith("group")) {
      group = properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim());
    }

    //agencyID
    if (properties0.startsWith("agencyID")) {
      agencyID = properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim());
    }
    else if (properties1.startsWith("agencyID")) {
      agencyID = properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim());
    }
    else if (properties2.startsWith("agencyID")) {
      agencyID = properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim());
    }
    else if (properties3.startsWith("agencyID")) {
      agencyID = properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim());
    }
    else if (properties4.startsWith("agencyID")) {
      agencyID = properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim());
    }
    else if (properties5.startsWith("agencyID")) {
      agencyID = properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim());
    }
    else if (properties6.startsWith("agencyID")) {
      agencyID = properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim());
    }
    else if (properties7.startsWith("agencyID")) {
      agencyID = properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim());
    }
    else if (properties8.startsWith("agencyID")) {
      agencyID = properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim());
    }
    else if (properties9.startsWith("agencyID")) {
      agencyID = properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim());
    }

    //agencyKey
    if (properties0.startsWith("agencyKey")) {
      agencyKey = properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim());
    }
    else if (properties1.startsWith("agencyKey")) {
      agencyKey = properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim());
    }
    else if (properties2.startsWith("agencyKey")) {
      agencyKey = properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim());
    }
    else if (properties3.startsWith("agencyKey")) {
      agencyKey = properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim());
    }
    else if (properties4.startsWith("agencyKey")) {
      agencyKey = properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim());
    }
    else if (properties5.startsWith("agencyKey")) {
      agencyKey = properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim());
    }
    else if (properties6.startsWith("agencyKey")) {
      agencyKey = properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim());
    }
    else if (properties7.startsWith("agencyKey")) {
      agencyKey = properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim());
    }
    else if (properties8.startsWith("agencyKey")) {
      agencyKey = properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim());
    }
    else if (properties9.startsWith("agencyKey")) {
      agencyKey = properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim());
    }

    //agencyImageUrl
    if (properties0.startsWith("agencyImageUrl")) {
      if (properties0.split(':').length > 1) {
        agencyImageUrl = "";
        properties0.split(':').forEach((value, index) => {
          if (index > 0) agencyImageUrl += ":" + value.trim();
        });
      }
      else {
        agencyImageUrl = properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim());
      }
    }
    else if (properties1.startsWith("agencyImageUrl")) {
      if (properties1.split(':').length > 1) {
        let arr: string[] = [];
        properties1.split(':').forEach((value, index) => {
          if (index > 0) arr.push(value.trim());
        });
        agencyImageUrl = arr.join(":").trim();
      }
      else {
        agencyImageUrl = properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim());
      }
    }
    else if (properties2.startsWith("agencyImageUrl")) {
      if (properties2.split(':').length > 1) {
        let arr: string[] = [];
        properties2.split(':').forEach((value, index) => {
          if (index > 0) arr.push(value.trim());
        });
        agencyImageUrl = arr.join(":").trim();
      }
      else {
        agencyImageUrl = properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim());
      }
    }
    else if (properties3.startsWith("agencyImageUrl")) {
      if (properties3.split(':').length > 1) {
        let arr: string[] = [];
        properties3.split(':').forEach((value, index) => {
          if (index > 0) arr.push(value.trim());
        });
        agencyImageUrl = arr.join(":").trim();
      }
      else {
        agencyImageUrl = properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim());
      }
    }
    else if (properties4.startsWith("agencyImageUrl")) {
      if (properties4.split(':').length > 1) {
        let arr: string[] = [];
        properties4.split(':').forEach((value, index) => {
          if (index > 0) arr.push(value.trim());
        });
        agencyImageUrl = arr.join(":").trim();
      }
      else {
        agencyImageUrl = properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim());
      }
    }
    else if (properties5.startsWith("agencyImageUrl")) {
      if (properties5.split(':').length > 1) {
        let arr: string[] = [];
        properties5.split(':').forEach((value, index) => {
          if (index > 0) arr.push(value.trim());
        });
        agencyImageUrl = arr.join(":").trim();
      }
      else {
        agencyImageUrl = properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim());
      }
    }
    else if (properties6.startsWith("agencyImageUrl")) {
      if (properties6.split(':').length > 1) {
        let arr: string[] = [];
        properties6.split(':').forEach((value, index) => {
          if (index > 0) arr.push(value.trim());
        });
        agencyImageUrl = arr.join(":").trim();
      }
      else {
        agencyImageUrl = properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim());
      }
    }
    else if (properties7.startsWith("agencyImageUrl")) {
      if (properties7.split(':').length > 1) {
        let arr: string[] = [];
        properties7.split(':').forEach((value, index) => {
          if (index > 0) arr.push(value.trim());
        });
        agencyImageUrl = arr.join(":").trim();
      }
      else {
        agencyImageUrl = properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim());
      }
    }
    else if (properties8.startsWith("agencyImageUrl")) {
      if (properties8.split(':').length > 1) {
        let arr: string[] = [];
        properties8.split(':').forEach((value, index) => {
          if (index > 0) arr.push(value.trim());
        });
        agencyImageUrl = arr.join(":").trim();
      }
      else {
        agencyImageUrl = properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim());
      }
    }
    else if (properties9.startsWith("agencyImageUrl")) {
      if (properties9.split(':').length > 1) {
        let arr: string[] = [];
        properties9.split(':').forEach((value, index) => {
          if (index > 0) arr.push(value.trim());
        });
        agencyImageUrl = arr.join(":").trim();
      }
      else {
        agencyImageUrl = properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim());
      }
    }

    //userName
    if (properties0.startsWith("userName")) {
      userName = properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim());
    }
    else if (properties1.startsWith("userName")) {
      userName = properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim());
    }
    else if (properties2.startsWith("userName")) {
      userName = properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim());
    }
    else if (properties3.startsWith("userName")) {
      userName = properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim());
    }
    else if (properties4.startsWith("userName")) {
      userName = properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim());
    }
    else if (properties5.startsWith("userName")) {
      userName = properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim());
    }
    else if (properties6.startsWith("userName")) {
      userName = properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim());
    }
    else if (properties7.startsWith("userName")) {
      userName = properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim());
    }
    else if (properties8.startsWith("userName")) {
      userName = properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim());
    }
    else if (properties9.startsWith("userName")) {
      userName = properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim());
    }

    //logon
    if (properties0.startsWith("logon")) {
      logon = JSON.parse(properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim()));
    }
    else if (properties1.startsWith("logon")) {
      logon = JSON.parse(properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim()));
    }
    else if (properties2.startsWith("logon")) {
      logon = JSON.parse(properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim()));
    }
    else if (properties3.startsWith("logon")) {
      logon = JSON.parse(properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim()));
    }
    else if (properties4.startsWith("logon")) {
      logon = JSON.parse(properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim()));
    }
    else if (properties5.startsWith("logon")) {
      logon = JSON.parse(properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim()));
    }
    else if (properties6.startsWith("logon")) {
      logon = JSON.parse(properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim()));
    }
    else if (properties7.startsWith("logon")) {
      logon = JSON.parse(properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim()));
    }
    else if (properties8.startsWith("logon")) {
      logon = JSON.parse(properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim()));
    }
    else if (properties9.startsWith("logon")) {
      logon = JSON.parse(properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim()));
    }

    //marketingGroupDashboardID
    if (properties0.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim());
    }
    else if (properties1.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim());
    }
    else if (properties2.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim());
    }
    else if (properties3.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim());
    }
    else if (properties4.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim());
    }
    else if (properties5.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim());
    }
    else if (properties6.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim());
    }
    else if (properties7.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim());
    }
    else if (properties8.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim());
    }
    else if (properties9.startsWith("marketingGroupDashboardID")) {
      marketingGroupDashboardID = properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim());
    }

    //isAuthenticated
    if (properties0.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim()));
    }
    else if (properties1.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim()));
    }
    else if (properties2.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim()));
    }
    else if (properties3.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim()));
    }
    else if (properties4.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim()));
    }
    else if (properties5.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim()));
    }
    else if (properties6.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim()));
    }
    else if (properties7.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim()));
    }
    else if (properties8.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim()));
    }
    else if (properties9.startsWith("isAuthenticated")) {
      isAuthenticated = JSON.parse(properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim()));
    }

    //lastAccessed
    if (properties0.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties0.split(':')[1].trim() == "undefined" ? undefined : (properties0.split(':')[1].trim() == "null" ? null : properties0.split(':')[1].trim())));
    }
    else if (properties1.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties1.split(':')[1].trim() == "undefined" ? undefined : (properties1.split(':')[1].trim() == "null" ? null : properties1.split(':')[1].trim())));
    }
    else if (properties2.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties2.split(':')[1].trim() == "undefined" ? undefined : (properties2.split(':')[1].trim() == "null" ? null : properties2.split(':')[1].trim())));
    }
    else if (properties3.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties3.split(':')[1].trim() == "undefined" ? undefined : (properties3.split(':')[1].trim() == "null" ? null : properties3.split(':')[1].trim())));
    }
    else if (properties4.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties4.split(':')[1].trim() == "undefined" ? undefined : (properties4.split(':')[1].trim() == "null" ? null : properties4.split(':')[1].trim())));
    }
    else if (properties5.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties5.split(':')[1].trim() == "undefined" ? undefined : (properties5.split(':')[1].trim() == "null" ? null : properties5.split(':')[1].trim())));
    }
    else if (properties6.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties6.split(':')[1].trim() == "undefined" ? undefined : (properties6.split(':')[1].trim() == "null" ? null : properties6.split(':')[1].trim())));
    }
    else if (properties7.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties7.split(':')[1].trim() == "undefined" ? undefined : (properties7.split(':')[1].trim() == "null" ? null : properties7.split(':')[1].trim())));
    }
    else if (properties8.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties8.split(':')[1].trim() == "undefined" ? undefined : (properties8.split(':')[1].trim() == "null" ? null : properties8.split(':')[1].trim())));
    }
    else if (properties9.startsWith("lastAccessed")) {
      lastAccessed = new Date(JSON.parse(properties9.split(':')[1].trim() == "undefined" ? undefined : (properties9.split(':')[1].trim() == "null" ? null : properties9.split(':')[1].trim())));
    }

    //idle time in minutes
    let idleTime = ((new Date()).getTime() - lastAccessed.getTime()) / 60000;
    let sessionTimeoutInMinute = (
      !!this.apSettings.AppSettings
      && !!this.apSettings.AppSettings
      && !!this.apSettings.AppSettings.SessionTimeoutInMinute
    )
      ? this.apSettings.AppSettings.SessionTimeoutInMinute : 0;
    if (sessionTimeoutInMinute > 0 && !!idleTime && idleTime > sessionTimeoutInMinute) {
      console.log("Session Timeout");
      return {
        sessionID: "",
        group: "",
        agencyID: !!agencyID ? agencyID.trim() : "",
        agencyKey: !!agencyKey ? agencyKey.trim() : "",
        agencyImageUrl: !!agencyImageUrl ? agencyImageUrl.trim() : "",
        userName: "",
        logon: enLoginMode.Undefined,
        marketingGroupDashboardID: "",
        isAuthenticated: false
      };
    }
    this.encryptLocalStorage({
      sessionID: sessionID,
      group: group,
      agencyID: agencyID,
      agencyKey: agencyKey,
      agencyImageUrl: agencyImageUrl,
      userName: userName,
      logon: logon,
      marketingGroupDashboardID: marketingGroupDashboardID,
      isAuthenticated: isAuthenticated
    });

    return {
      sessionID: !!sessionID ? sessionID.trim() : "",
      group: !!group ? group.trim() : "",
      agencyID: !!agencyID ? agencyID.trim() : "",
      agencyKey: !!agencyKey ? agencyKey.trim() : "",
      agencyImageUrl: !!agencyImageUrl ? agencyImageUrl.trim() : "",
      userName: !!userName ? userName.trim() : "",
      logon: !!logon ? logon : enLoginMode.Undefined,
      marketingGroupDashboardID: !!marketingGroupDashboardID ? marketingGroupDashboardID.trim() : "",
      isAuthenticated: isAuthenticated
    };
  }

  sessionID$(token: string = undefined): Observable<any> {
    let decrypted = this.decryptLocationStorage(token);
    return of(!!decrypted ? decrypted.sessionID : "");
  }
  group$(token: string = undefined): Observable<any> {
    let decrypted = this.decryptLocationStorage(token);
    return of(!!decrypted ? decrypted.group : "");
  }
  agencyID$(token: string = undefined): Observable<any> {
    let decrypted = this.decryptLocationStorage(token);
    return of(!!decrypted ? decrypted.agencyID : "");
  }
  agencyKey$(token: string = undefined): Observable<any> {
    let decrypted = this.decryptLocationStorage(token);
    return of(!!decrypted ? decrypted.agencyKey : "");
  }
  agencyImageUrl$(token: string = undefined): Observable<any> {
    let decrypted = this.decryptLocationStorage(token);
    return of(!!decrypted ? decrypted.agencyImageUrl : "");
  }
  userName$(token: string = undefined): Observable<any> {
    let decrypted = this.decryptLocationStorage(token);
    return of(!!decrypted ? decrypted.userName : "");
  }
  logon$(token: string = undefined): Observable<any> {
    let decrypted = this.decryptLocationStorage(token);
    return of(!!decrypted ? decrypted.logon : enLoginMode.Undefined);
  }
  marketingGroupDashboardID$(token: string = undefined): Observable<any> {
    let decrypted = this.decryptLocationStorage(token);
    return of(!!decrypted ? decrypted.marketingGroupDashboardID : "");
  }
  isAuthenticated$(token: string = undefined): Observable<any> {
    let decrypted = this.decryptLocationStorage(token);
    return of(!!decrypted ? decrypted.isAuthenticated : false);
  }
  
}
export enum enLoginMode {
  Undefined = 0,
  AdminUser = 1,
  NormalUser = 2,
  Failed = 3
}
export interface ILocalStorage {
  sessionID: string;
  group: string;
  agencyID: string;
  agencyKey: string;
  agencyImageUrl: string;
  userName: string;
  logon: enLoginMode;
  marketingGroupDashboardID: string;
  isAuthenticated: boolean;  
}
