import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable } from 'rxjs';
import { first, map } from "rxjs/operators";
import { DomSanitizer } from '@angular/platform-browser';

import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { IFileToUpload, ILeaderboardPdf } from '../group.state';
import { LeaderBoardPdfUploadAction, LeaderBoardPdfUploadFailAction, LeaderBoardPdfDeleteAction, LeaderBoardPdfAllAction, LeaderBoardPdfShowAction } from '../group.action';
import { /*leaderboardpdfupload,*/ leaderboardpdfuploadProcessing, leaderboardpdfdeleteProcessing, leaderboardpdfall, leaderboardpdfallProcessing, leaderboardpdfshow, leaderboardpdfshowProcessing } from '../group.selectors';
import { ConfirmDialogComponent } from '../../components/confirmdialog/confirmdialog.component';


@Component({
  selector: 'app-leaderboardpdf',
  templateUrl: './leaderboardpdf.component.html',
  styleUrls: ['./leaderboardpdf.component.scss']
})
export class LeaderBoardPdfComponent extends ComponentBase implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private store: Store<IAppState>,
    private encryption: EncryptionService
  ) {
    super(activatedRoute, snackBar, encryption);
  }
  private theFile: any = null;
  private messages: string[] = [];
  // Maximum file size allowed to be uploaded = 10MB
  private readonly MAX_SIZE: number = 0;//10485760;
  private selectedPdf: ILeaderboardPdf = null;

  //private leaderboardpdfupload$ = this.store.pipe(select(leaderboardpdfupload));
  private leaderboardpdfuploadProcessing$ = this.store.pipe(select(leaderboardpdfuploadProcessing));
  private leaderboardpdfdeleteProcessing$ = this.store.pipe(select(leaderboardpdfdeleteProcessing));
  private leaderboardpdfall$ = this.store.pipe(select(leaderboardpdfall));
  private leaderboardpdfallProcessing$ = this.store.pipe(select(leaderboardpdfallProcessing));

  ngOnInit(): void {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new LeaderBoardPdfAllAction(
          this.agencyKey,
          this.marketingGroupDashboardID
        )
      );
    }
  }

  onFileChange(event) {
    this.theFile = null;
    if (event.target.files && event.target.files.length > 0) {
      // Don't allow file sizes over 1MB
      if (this.MAX_SIZE > 0) {
        if (event.target.files[0].size < this.MAX_SIZE) {
          // Set theFile property
          this.theFile = event.target.files[0];
          this.onUploadClick();
        }
        else {
          // Display error message
          this.messages.push("File: " + event.target.files[0].name + " is too large to upload.");
        }
      }
      else {
        // Set theFile property
        this.theFile = event.target.files[0];
        this.onUploadClick();
      }
    }
    this.selectedPdf = null;
  }
  onUploadClick() {
    if (!(!!this.theFile && !!this.theFile)) {
      this.store.dispatch(new LeaderBoardPdfUploadFailAction("No file selected to upload"));
      return;
    }
    if ((!!this.theFile && this.theFile.type.toLowerCase() != "application/pdf")) {
      this.store.dispatch(new LeaderBoardPdfUploadFailAction("Only PDF file is allowed to upload"));
      return;
    }
    let file = {
      fileName: this.theFile.name,
      fileSize: this.theFile.size,
      fileType: this.theFile.type,
      lastModifiedTime: this.theFile.lastModified,
      lastModifiedDate: this.theFile.lastModifiedDate
    } as ILeaderboardPdf;

    if (!!this.selectedPdf && !!this.selectedPdf.id) {
      file.id = this.selectedPdf.id;
    }

    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    let reader = new FileReader();

    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result.toString();

      let auth = JSON.parse(localStorage.getItem("auth"));
      if (!!auth) {
        this.store.dispatch(
          new LeaderBoardPdfUploadAction(
            this.agencyKey,
            this.agencyID,
            this.sessionID,
            this.marketingGroupDashboardID,
            file as ILeaderboardPdf
          )
        );
        /*this.leaderboardpdfupload$.subscribe(result => {
          if (result) {
            this.store.dispatch(
              new LeaderBoardPdfAllAction(
                this.agencyKey,
                this.marketingGroupDashboardID
              )
            );
          }
        });*/
      }
    }

    // Read the file
    reader.readAsDataURL(this.theFile);

  }
  onChangePdfClick(pdf: ILeaderboardPdf) {
    this.selectedPdf = pdf;
    document.getElementById("uploadfile").click();
  }
  onViewPdfClick(pdf: ILeaderboardPdf) {
    var canDialogRef = this.dialog.open(LeaderBoardPdfViewComponent, {
      width: 'calc(100vw - 30px)',
      height: 'calc(100vh - 50px)',
      panelClass: 'leaderboardpdfview-dialog-container',
      data: {
        id: pdf.id,
        title: 'Leaderboard PDF'
      }
    });
  }
  onDeletePdfClick(pdf: ILeaderboardPdf) {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {

      var confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '350px',
        data: {          
          title: 'Confirm PDF Delete',
          message: 'Are you sure to delete this file?'
        }
      });
      confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {

          this.store.dispatch(
            new LeaderBoardPdfDeleteAction(
              this.agencyKey,
              this.agencyID,
              this.sessionID,
              this.marketingGroupDashboardID,
              pdf.id
            )
          );
        }        

      });     
      
    }
  }
}
@Component({
  selector: 'app-leaderboardpdfview',
  template: `<div class="modal_header">
                <h1 mat-dialog-title>{{data.title}}</h1>
                <div mat-dialog-actions class="cancel-booking">
                  <button mat-button mat-raised-button (click)="onDismiss()" class="colse_popup"><i class="cross"></i></button>
                </div>
              </div>
              <mat-spinner [diameter]="50" *ngIf="(leaderboardpdfshowProcessing$ | async)"></mat-spinner>
              <div *ngIf="!!(leaderboardpdfshow$ | async) && !!pdfUrl" class="pdf_box">
                  <object [data]="pdfUrl" type = "application/pdf" width="100%" height="100%" class= "internal" >
                    <embed [src]="pdfUrl" type = "application/pdf" />
                  </object>
              </div>
             `,
  styles: [`
    .pdf_box { height: calc(100% - 74px);; }
    .modal_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
    }
    .modal_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 24px;
        background: var(--BlackLightColor);
    }
    .mat-dialog-title{
      margin: 0 0 0;
      color: #fff;
     font-weight: 700;
      font-size: 25px;
    }
    .mat-dialog-actions.cancel-booking {
        padding: 0 0;
        margin: 0 0;
        min-height: auto;
    }
    .colse_popup {
        font-size: 0;
        box-shadow: none;
        background: none;
    }
    button.mat-focus-indicator.colse_popup.mat-button.mat-raised-button.mat-button-base  i.cross {
        width: 2px;
        height: 25px;
        background: #fff;
        display: inline-block;
        position: relative;
        transform: rotate(45deg);
    }
    button.mat-focus-indicator.colse_popup.mat-button.mat-raised-button.mat-button-base i.cross:after {
        content: '';
        width: 2px;
        height: 25px;
        background: #fff;
        position: absolute;
        left: 0;
        top: 0;
        transform: rotate(-90deg);
    }
    .mat-progress-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    embed{
      width: 100%;
      height: 100%;
    }
    .pagerect, div#layout-container {
      width: 100% !important;
    }
  @media screen and (max-width: 767px){
    .mat-dialog-title{
      font-size: 20px;
    }
  }
`]
})
export class LeaderBoardPdfViewComponent extends ComponentBase implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private store: Store<IAppState>,
    private encryption: EncryptionService,
    private dom: DomSanitizer,
    private dialogRef: MatDialogRef<LeaderBoardPdfViewComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IPdfViewerDialogData
  ) {
    super(activatedRoute, snackBar, encryption);
  }

  private pdfUrl: any;
  private leaderboardpdfshowProcessing$ = this.store.pipe(select(leaderboardpdfshowProcessing));
  private leaderboardpdfshow$ = this.store.pipe(select(leaderboardpdfshow));
  ngOnInit(): void {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new LeaderBoardPdfShowAction(
          this.agencyKey,
          this.marketingGroupDashboardID,
          this.data.id
        )
      );

      this.leaderboardpdfshow$
        .subscribe(file => {
          if (!!file) {
            this.pdfUrl = null
            if (!!file.fileAsBase64) {
              this.pdfUrl = this.dom.bypassSecurityTrustResourceUrl(`${file.pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`);
            }
          }
        });

    }
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
export interface IPdfViewerDialogData {
  id: string;
  title: string;
  message: string;
}
