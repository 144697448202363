import { IAppState } from '../app.state';

export const ageoflistings = (state: IAppState) => state.reports.ageoflistings;
export const isAgeOfListingsProcessing = (state: IAppState) => state.reports.isAgeOfListingsProcessing;
export const ageoflistingsgraphs = (state: IAppState) => state.reports.ageoflistingsgraphs;
export const agencies = (state: IAppState) => state.reports.agencies;
export const isAgenciesProcessing = (state: IAppState) => state.reports.isAgenciesProcessing;
export const staffperformances = (state: IAppState) => state.reports.staffperformances;
export const isAgeOfListingsGraphsProcessing = (state: IAppState) => state.reports.isAgeOfListingsGraphsProcessing;
export const isStaffPerformancesProcessing = (state: IAppState) => state.reports.isStaffPerformancesProcessing;
export const financialyearends = (state: IAppState) => state.reports.financialyearends;
export const financialyearendsProcessing = (state: IAppState) => state.reports.financialyearendsProcessing;
export const financialyearperformancetargets = (state: IAppState) => state.reports.financialyearperformancetargets;
export const financialyearperformancetargetsProcessing = (state: IAppState) => state.reports.financialyearperformancetargetsProcessing;
export const savecommissionbudget = (state: IAppState) => state.reports.savecommissionbudget;
export const savecommissionbudgetProcessing = (state: IAppState) => state.reports.savecommissionbudgetProcessing;
export const errorMessage = (state: IAppState) => state.reports.errorMessage;
