import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { from, Observable, Observer, of } from "rxjs";
import { Action, select, Store } from "@ngrx/store";
import {
  catchError,
  filter,
  first,
  flatMap,
  map,
  mergeMap
} from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ActionTypes,
  LeaderBoardPdfUploadAction,
  LeaderBoardPdfUploadSuccessAction,
  LeaderBoardPdfUploadFailAction,
  LeaderBoardPdfDeleteAction,
  LeaderBoardPdfDeleteSuccessAction,
  LeaderBoardPdfDeleteFailAction,
  RemoveErrorMessageAction,
  LeaderBoardPdfShowAction,
  LeaderBoardPdfShowSuccessAction,
  LeaderBoardPdfAllAction,

  LeaderBoardQuoteSaveAction,
  LeaderBoardQuoteSaveSuccessAction,
  LeaderBoardQuoteSaveFailAction,
  LeaderBoardQuoteDeleteAction,
  LeaderBoardQuoteDeleteSuccessAction,
  LeaderBoardQuoteDeleteFailAction,

  LeaderBoardQuoteShowAction,
  LeaderBoardQuoteShowSuccessAction,
  LeaderBoardQuoteShowFailAction,

  LeaderBoardPdfAllSuccessAction,
  LeaderBoardPdfAllFailAction,
  LeaderBoardPdfShowFailAction,

  LeaderBoardQuoteAllAction,
  LeaderBoardQuoteAllSuccessAction,
  LeaderBoardQuoteAllFailAction,

  LeaderBoardGCIGraphAction,
  LeaderBoardGCIGraphSuccessAction,
  LeaderBoardGCIGraphFailAction,
  LeaderBoardNumberOfSalesGraphAction,
  LeaderBoardNumberOfSalesGraphSuccessAction,
  LeaderBoardNumberOfSalesGraphFailAction,
  LeaderBoardGCIStateStaffAction,
  LeaderBoardGCIStateStaffSuccessAction,
  LeaderBoardGCIStateStaffFailAction,
  StatewiseNumberOfNewListingsAction,
  StatewiseNumberOfNewListingsSuccessAction,
  StatewiseNumberOfNewListingsFailAction,
  NewListingDetailsAction,
  NewListingDetailsSuccessAction,
  NewListingDetailsFailAction,
  StatewiseNumberOfSoldListingsAction,
  StatewiseNumberOfSoldListingsSuccessAction,
  StatewiseNumberOfSoldListingsFailAction,
  SoldListingDetailsAction,
  SoldListingDetailsSuccessAction,
  SoldListingDetailsFailAction,
  ActualVsBudgetGraphAction,
  ActualVsBudgetGraphSuccessAction,
  ActualVsBudgetGraphFailAction
} from "./group.action";
import { GroupService } from "./group.service";
import { IAppState } from "../app.state";

@Injectable()
export class GroupEffects {
  constructor(
    private store: Store<IAppState>,
    private groupService: GroupService,
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }


  @Effect()
  gciLeaderBoardGraph$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardGCIGraph),
    mergeMap((action: LeaderBoardGCIGraphAction) =>
      this.groupService
        .gciLeaderBoardGraph(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID, action.date)
        .pipe(
          map(
            data =>
              new LeaderBoardGCIGraphSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardGCIGraphFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  gciLeaderBoardGraphSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardGCIGraphSuccess),
    mergeMap((action: LeaderBoardGCIGraphSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardGCIGraphFailAction(error.message)))
  );

  @Effect()
  numberOfSalesLeaderBoardGraph$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardNumberOfSalesGraph),
    mergeMap((action: LeaderBoardNumberOfSalesGraphAction) =>
      this.groupService
        .numberOfSalesLeaderBoardGraph(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID, action.date)
        .pipe(
          map(
            data =>
              new LeaderBoardNumberOfSalesGraphSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardNumberOfSalesGraphFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  numberOfSalesLeaderBoardGraphSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardNumberOfSalesGraphSuccess),
    mergeMap((action: LeaderBoardNumberOfSalesGraphSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardNumberOfSalesGraphFailAction(error.message)))
  );


  @Effect()
  gciLeaderBoardStateStaff$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardGCIStateStaff),
    mergeMap((action: LeaderBoardGCIStateStaffAction) =>
      this.groupService
        .gciLeaderBoardStateStaff(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new LeaderBoardGCIStateStaffSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardGCIStateStaffFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  gciLeaderBoardStateStaffSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardGCIStateStaffSuccess),
    mergeMap((action: LeaderBoardGCIStateStaffSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardGCIStateStaffFailAction(error.message)))
  );


  @Effect()
  leaderBoardPdfUpload$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardPdfUpload),
    mergeMap((action: LeaderBoardPdfUploadAction) =>
      this.groupService
        .uploadLeaderBoardPdf(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID, action.file)
        .pipe(
          map(
            data =>
              new LeaderBoardPdfUploadSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardPdfUploadFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  leaderBoardPdfUploadSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardPdfUploadSuccess),
    mergeMap((action: LeaderBoardPdfUploadSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardPdfUploadFailAction(error.message)))
  );

  @Effect()
  leaderBoardPdfDelete$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardPdfDelete),
    mergeMap((action: LeaderBoardPdfDeleteAction) =>
      this.groupService
        .deleteLeaderBoardPdf(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID, action.id)
        .pipe(
          map(
            data =>
              new LeaderBoardPdfDeleteSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardPdfDeleteFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  leaderBoardPdfDeleteSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardPdfDeleteSuccess),
    mergeMap((action: LeaderBoardPdfDeleteSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardPdfDeleteFailAction(error.message)))
  );

  @Effect()
  leaderBoardPdfShow$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardPdfShow),
    mergeMap((action: LeaderBoardPdfShowAction) =>
      this.groupService
        .getLeaderBoardPdf(action.agencyKey, action.marketingGroupDashboardID, action.id)
        .pipe(
          map(
            data =>
              new LeaderBoardPdfShowSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardPdfShowFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  leaderBoardPdfShowSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardPdfShowSuccess),
    mergeMap((action: LeaderBoardPdfShowSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardPdfShowFailAction(error.message)))
  );


  @Effect()
  leaderboardQuoteSave$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardQuoteSave),
    mergeMap((action: LeaderBoardQuoteSaveAction) =>
      this.groupService
        .leaderboardQuoteSave(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID, action.quoteOfTheDay, action.quoteWrittenBy, action.sortOrder, action.id)
        .pipe(
          map(
            data =>
              new LeaderBoardQuoteSaveSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardPdfUploadFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  leaderboardQuoteSaveSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardQuoteSaveSuccess),
    mergeMap((action: LeaderBoardQuoteSaveSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardQuoteSaveFailAction(error.message)))
  );
  @Effect()
  leaderboardQuoteDelete$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardQuoteDelete),
    mergeMap((action: LeaderBoardQuoteDeleteAction) =>
      this.groupService
        .leaderboardQuoteDelete(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID, action.id)
        .pipe(
          map(
            data =>
              new LeaderBoardQuoteDeleteSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardQuoteDeleteFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  leaderboardQuoteDeleteSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardQuoteDeleteSuccess),
    mergeMap((action: LeaderBoardQuoteDeleteSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardQuoteDeleteFailAction(error.message)))
  );


  @Effect()
  getLeaderboardQuote$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardQuoteShow),
    mergeMap((action: LeaderBoardQuoteShowAction) =>
      this.groupService
        .getLeaderboardQuote(action.agencyKey, action.marketingGroupDashboardID, action.id)
        .pipe(
          map(
            data =>
              new LeaderBoardQuoteShowSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardQuoteShowFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  getLeaderboardQuoteSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardQuoteShowSuccess),
    mergeMap((action: LeaderBoardQuoteShowSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardQuoteShowFailAction(error.message)))
  );

  @Effect()
  leaderBoardPdfAll$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardPdfAll),
    mergeMap((action: LeaderBoardPdfAllAction) =>
      this.groupService
        .getLeaderboardPDFs(action.agencyKey, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new LeaderBoardPdfAllSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardPdfAllFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  leaderBoardPdfAllSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardPdfAllSuccess),
    mergeMap((action: LeaderBoardPdfAllSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardPdfAllFailAction(error.message)))
  );

  @Effect()
  leaderBoardQuoteAll$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardQuoteAll),
    mergeMap((action: LeaderBoardQuoteAllAction) =>
      this.groupService
        .getLeaderboardQuotes(action.agencyKey, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new LeaderBoardQuoteAllSuccessAction(data)
          ),
          catchError(error => of(new LeaderBoardQuoteAllFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  leaderBoardQuoteAllSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LeaderBoardQuoteAllSuccess),
    mergeMap((action: LeaderBoardQuoteAllSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new LeaderBoardQuoteAllFailAction(error.message)))
  );

  @Effect()
  statewiseNumberOfNewListings$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.StatewiseNumberOfNewListings),
    mergeMap((action: StatewiseNumberOfNewListingsAction) =>
      this.groupService
        .statewiseNumberOfNewListings(action.agencyKey, action.sessionID, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new StatewiseNumberOfNewListingsSuccessAction(data)
          ),
          catchError(error => of(new StatewiseNumberOfNewListingsFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  statewiseNumberOfNewListingsSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.StatewiseNumberOfNewListingsSuccess),
    mergeMap((action: StatewiseNumberOfNewListingsSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new StatewiseNumberOfNewListingsFailAction(error.message)))
  );
  @Effect()
  newListingDetails$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.NewListingDetails),
    mergeMap((action: NewListingDetailsAction) =>
      this.groupService
        .newListingDetails(action.agencyKey, action.sessionID, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new NewListingDetailsSuccessAction(data)
          ),
          catchError(error => of(new NewListingDetailsFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  newListingDetailsSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.NewListingDetailsSuccess),
    mergeMap((action: NewListingDetailsSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new NewListingDetailsFailAction(error.message)))
  );


  @Effect()
  statewiseNumberOfSoldListings$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.StatewiseNumberOfSoldListings),
    mergeMap((action: StatewiseNumberOfSoldListingsAction) =>
      this.groupService
        .statewiseNumberOfSoldListings(action.agencyKey, action.sessionID, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new StatewiseNumberOfSoldListingsSuccessAction(data)
          ),
          catchError(error => of(new StatewiseNumberOfSoldListingsFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  statewiseNumberOfSoldListingsSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.StatewiseNumberOfSoldListingsSuccess),
    mergeMap((action: StatewiseNumberOfSoldListingsSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new StatewiseNumberOfSoldListingsFailAction(error.message)))
  );
  @Effect()
  soldListingDetails$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.SoldListingDetails),
    mergeMap((action: SoldListingDetailsAction) =>
      this.groupService
        .soldListingDetails(action.agencyKey, action.sessionID, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new SoldListingDetailsSuccessAction(data)
          ),
          catchError(error => of(new SoldListingDetailsFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  soldListingDetailsSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.SoldListingDetailsSuccess),
    mergeMap((action: SoldListingDetailsSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new SoldListingDetailsFailAction(error.message)))
  );

  @Effect()
  actualVsBudgetThisMonth$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.ActualVsBudgetGraph),
    mergeMap((action: ActualVsBudgetGraphAction) =>
      this.groupService
        .actualVsBudgetThisMonth(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new ActualVsBudgetGraphSuccessAction(data)
          ),
          catchError(error => of(new ActualVsBudgetGraphFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  actualVsBudgetThisMonthSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.ActualVsBudgetGraphSuccess),
    mergeMap((action: ActualVsBudgetGraphSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new ActualVsBudgetGraphFailAction(error.message)))
  );

}
