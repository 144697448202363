import { Observable } from 'rxjs';

import { ILocalStorage } from './../util/encryption/encryption.Service';

export abstract class AuthService {
  /**
 * Logs in a user
 *
 * @agencyKey Agency key
 * @param username The users username
 * @param password The password
 */
  public abstract login(
    agencyKey: string,
    username: string,
    password: string,
    group: string
  ): Observable<ILocalStorage>;

  /**
   * Logs out a user
   * 
   * @param sessionID The session id
   */
  public abstract logout(agencyKey: string, sessionID: string): Observable<ILogoutResponse>;
}

export interface ILogoutResponse {
  errorCode: number;
  message: string;
  success: boolean;
}
