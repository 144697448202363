import IAuthState from './auth.state';
import { ActionTypes, AuthAction, LogoutSuccessAction } from './auth.action';
import { enLoginMode } from '../util/encryption/encryption.Service';

export const getInitialState = (): IAuthState => ({
  sessionID: null,
  group: null,
  agencyKey: null,
  agencyID: null,
  agencyImageUrl: null,
  userName: null,
  logon: enLoginMode.Undefined,
  marketingGroupDashboardID: null,
  isAuthenticated: false,
  isLoggingIn: false,
  isLoggingOut: false,
  errorMessage: null,
  token: null
});

export function authReducer(
  state: IAuthState,
  action: AuthAction
): IAuthState {
  switch (action.type) {

    case ActionTypes.Login: {
      return { ...state, isLoggingIn: true };
    }
    case ActionTypes.LoginSuccess: {
      return {
        ...state,
        sessionID: action.data.sessionID,
        group: action.data.group,
        agencyKey: action.data.agencyKey,
        agencyID: action.data.agencyID,
        agencyImageUrl: action.data.agencyImageUrl,
        userName: action.data.userName,
        logon: action.data.logon,
        marketingGroupDashboardID: action.data.marketingGroupDashboardID,
        isAuthenticated: action.data.isAuthenticated,
        isLoggingIn: false,
        errorMessage: null,
        token: action.token
      };
    }
    case ActionTypes.LoginFail: {
      return {
        ...state,
        isLoggingIn: false,
        errorMessage: action.message,
        isAuthenticated: false
      };
    }
    case ActionTypes.Logout: {
      return { ...state, isLoggingOut: true };
    }
    case ActionTypes.LogoutSuccess: {
      let auth = action.auth;
      if (!!auth) {
        return { ...state, isAuthenticated: action.auth.isAuthenticated, sessionID: action.auth.sessionID, isLoggingOut: false, token: action.token };
      }
      else {
        return getInitialState();
      }
    }
    case ActionTypes.LogoutFail: {
      return {
        ...state,
        isLoggingOut: false,
        errorMessage: action.message,
        isAuthenticated: false
      };
    }

    // this is necessary since we are using local storage to hydrate part of the state
    case '@ngrx/store/update-reducers' as any: {
      return { ...getInitialState(), ...state };
    }

    case ActionTypes.RemoveErrorMessage:
    case '@ngrx/router-store/navigated' as any: {
      return { ...state, errorMessage: null };
    }

    default: {
      return state || getInitialState();
    }

  }

}
export default authReducer;
