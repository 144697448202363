import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable, Subscription } from 'rxjs';
import { first, map } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";

import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService, enLoginMode } from '../../util/encryption/encryption.Service';
import { SalesBudgetFinancialYearEndAction, SalesBudgetFinancialYearStateWisePerformanceTargetsAction, SalesBudgetFinancialYearCommissionBudgetSaveAction } from '../reports.action';
import {
  financialyearends,
  financialyearendsProcessing,
  financialyearperformancetargets,
  financialyearperformancetargetsProcessing,
  savecommissionbudget,
  savecommissionbudgetProcessing
} from '../reports.selectors';
import { IFinancialYear, IStateWiseFYPerformanceTarget, IFinancialMonth } from '../reports.state';

@Component({
  selector: 'app-salesbudget',
  templateUrl: './salesbudget.component.html',
  styleUrls: ['./salesbudget.component.scss']
})
export class SalesBudgetComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
    ) {
    super(activatedRoute, snackBar, encryption);
  }
  private financialyearends$ = this.store.pipe(select(financialyearends));
  private financialyearendsProcessing$ = this.store.pipe(select(financialyearendsProcessing));
  private financialyearperformancetargets$ = this.store.pipe(select(financialyearperformancetargets));
  private financialyearperformancetargetsProcessing$ = this.store.pipe(select(financialyearperformancetargetsProcessing));
  private savecommissionbudget$ = this.store.pipe(select(savecommissionbudget));
  private savecommissionbudgetProcessing$ = this.store.pipe(select(savecommissionbudgetProcessing));

  /*
  //#region Form controls
  public readonly salesbudgetFrom = new FormGroup({
    selectedFinancialEndYear: new FormControl(null, Validators.required),
  });
  get selectedFinancialEndYear() {
    return !!this.salesbudgetFrom.get("selectedFinancialEndYear") ? this.salesbudgetFrom.get("selectedFinancialEndYear") as FormControl : null;
  }
  //#endregion
  */

  private financialYearPerformanceTargets: IStateWiseFYPerformanceTarget[] = [];
  private fyMonth01: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth02: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth03: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth04: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth05: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth06: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth07: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth08: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth09: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth10: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth11: IFinancialMonth = {} as IFinancialMonth;
  private fyMonth12: IFinancialMonth = {} as IFinancialMonth;
  private currentFinancialYear: IFinancialYear;
  private selectedFinancialYear: IFinancialYear;
  private selectedFinancialEndYear: number;


  private subscription_financialyearends$: Subscription;
  private subscription_financialyearperformancetargets$: Subscription;
  private subscription_savecommissionbudget$: Subscription;

  ngOnInit(): void {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new SalesBudgetFinancialYearEndAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID
        )
      );

      this.subscription_financialyearends$ =
        this.financialyearends$.subscribe(fye => {
          if (!!fye) {
            this.currentFinancialYear = fye.find(f => f.isCurrent);
            this.selectedFinancialEndYear = this.currentFinancialYear?.finYearEnd ?? null;
            this.selectedFinancialYear = this.currentFinancialYear;
            if (!!this.financialMonths && this.financialMonths.length == 12) {
              this.fyMonth01 = JSON.parse(JSON.stringify(this.financialMonths[0]));
              this.fyMonth02 = JSON.parse(JSON.stringify(this.financialMonths[1]));
              this.fyMonth03 = JSON.parse(JSON.stringify(this.financialMonths[2]));
              this.fyMonth04 = JSON.parse(JSON.stringify(this.financialMonths[3]));
              this.fyMonth05 = JSON.parse(JSON.stringify(this.financialMonths[4]));
              this.fyMonth06 = JSON.parse(JSON.stringify(this.financialMonths[5]));
              this.fyMonth07 = JSON.parse(JSON.stringify(this.financialMonths[6]));
              this.fyMonth08 = JSON.parse(JSON.stringify(this.financialMonths[7]));
              this.fyMonth09 = JSON.parse(JSON.stringify(this.financialMonths[8]));
              this.fyMonth10 = JSON.parse(JSON.stringify(this.financialMonths[9]));
              this.fyMonth11 = JSON.parse(JSON.stringify(this.financialMonths[10]));
              this.fyMonth12 = JSON.parse(JSON.stringify(this.financialMonths[11]));
            }

            if (!!this.selectedFinancialEndYear) {
              this.store.dispatch(
                new SalesBudgetFinancialYearStateWisePerformanceTargetsAction(
                  this.agencyKey,
                  this.agencyID,
                  this.sessionID,
                  this.marketingGroupDashboardID,
                  this.selectedFinancialEndYear
                )
              );

              this.subscription_financialyearperformancetargets$ =
                this.financialyearperformancetargets$.subscribe(fypt => {
                  if (!!fypt) {
                    this.financialYearPerformanceTargets = fypt;
                    this.monthwiseTargetPopulate();
                  }
                })
            }

          }
        });

    }
  }
  ngOnDestroy(): void {
    if (!!this.subscription_financialyearends$) this.subscription_financialyearends$.unsubscribe();
    if (!!this.subscription_financialyearperformancetargets$) this.subscription_financialyearperformancetargets$.unsubscribe();
  }

  //#region Events
  onTargetChange(fm: IFinancialMonth) {    
    fm.targetTotal = fm.targets.map(fypt => fypt.targetValue).reduce(function (a, b) {
      return (a ?? 0) + (b ?? 0);
    });
    //this.save(fm);
  }
  onTargetInput(value, target, fm: IFinancialMonth): number {
    target.targetValue = Number(!!value ? value.replace(/,/g, '') : value);
    this.onTargetChange(fm);
    return Number(target.targetValue);
  }
  onKeyNumericPress(event: any) {
    if (event.which != 8 && isNaN(Number(String.fromCharCode(event.which)))) {
      if (event.which === 46) {
        if (
          !!event.target.textContent &&
          (event.target.textContent + "").indexOf(".") >= 0
        ) {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }
    }
  }
  onFinancialYearSelectionChange(event) {
    console.log(this.selectedFinancialEndYear);
    if (!this.selectedFinancialEndYear) return;
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {

      this.subscription_financialyearends$ =
        this.financialyearends$.subscribe(fye => {
          if (!!fye) {
            this.selectedFinancialYear = fye.find(f => f.finYearEnd == this.selectedFinancialEndYear);
            if (!!this.financialMonths && this.financialMonths.length == 12) {
              this.fyMonth01 = JSON.parse(JSON.stringify(this.financialMonths[0]));
              this.fyMonth02 = JSON.parse(JSON.stringify(this.financialMonths[1]));
              this.fyMonth03 = JSON.parse(JSON.stringify(this.financialMonths[2]));
              this.fyMonth04 = JSON.parse(JSON.stringify(this.financialMonths[3]));
              this.fyMonth05 = JSON.parse(JSON.stringify(this.financialMonths[4]));
              this.fyMonth06 = JSON.parse(JSON.stringify(this.financialMonths[5]));
              this.fyMonth07 = JSON.parse(JSON.stringify(this.financialMonths[6]));
              this.fyMonth08 = JSON.parse(JSON.stringify(this.financialMonths[7]));
              this.fyMonth09 = JSON.parse(JSON.stringify(this.financialMonths[8]));
              this.fyMonth10 = JSON.parse(JSON.stringify(this.financialMonths[9]));
              this.fyMonth11 = JSON.parse(JSON.stringify(this.financialMonths[10]));
              this.fyMonth12 = JSON.parse(JSON.stringify(this.financialMonths[11]));
            }

            if (!!this.selectedFinancialEndYear) {
              this.store.dispatch(
                new SalesBudgetFinancialYearStateWisePerformanceTargetsAction(
                  this.agencyKey,
                  this.agencyID,
                  this.sessionID,
                  this.marketingGroupDashboardID,
                  this.selectedFinancialEndYear
                )
              );

              this.subscription_financialyearperformancetargets$ =
                this.financialyearperformancetargets$.subscribe(fypt => {
                  if (!!fypt) {
                    this.financialYearPerformanceTargets = fypt;
                    this.monthwiseTargetPopulate();
                  }
                })
            }

          }
        });

    }
  }
  onSaveClick() {
    this.save();
  }
  //#endregion

  //#region Properties
  get financialMonths(): IFinancialMonth[] {
    let fyMonths: IFinancialMonth[] = [];
    if (!this.selectedFinancialYear) return fyMonths;
    if (!this.selectedFinancialEndYear) return fyMonths;
    let dateCounter: Date = new Date(this.selectedFinancialYear.finStartDate);
    let endDate: Date = new Date(this.selectedFinancialYear.finEndDate);
    if (dateCounter.getFullYear() < endDate.getFullYear()) {
      dateCounter.setFullYear(this.selectedFinancialEndYear - 1);
      endDate.setFullYear(this.selectedFinancialEndYear);
    }
    else {
      dateCounter.setFullYear(this.selectedFinancialEndYear);
      endDate.setFullYear(this.selectedFinancialEndYear);
    }
    while (dateCounter <= endDate) {
      fyMonths.push({
        monthName: dateCounter.toLocaleString('default', { month: 'long' }),
        month: dateCounter.getMonth() + 1,
        year: dateCounter.getFullYear(),
        endYear: this.selectedFinancialEndYear,
        startDate: dateCounter,
        targets: [],
        targetTotal: 0
      });

      dateCounter.setMonth(dateCounter.getMonth() + 1);
    }

    return fyMonths;
  }
  //#endregion

  //#region Functions
  monthwiseTargetPopulate(): void {
    if (!(!!this.financialYearPerformanceTargets && !!this.financialYearPerformanceTargets.length)) return;
    this.fyMonth01.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth01.month)));
    this.fyMonth02.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth02.month)));
    this.fyMonth03.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth03.month)));
    this.fyMonth04.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth04.month)));
    this.fyMonth05.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth05.month)));
    this.fyMonth06.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth06.month)));
    this.fyMonth07.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth07.month)));
    this.fyMonth08.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth08.month)));
    this.fyMonth09.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth09.month)));
    this.fyMonth10.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth10.month)));
    this.fyMonth11.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth11.month)));
    this.fyMonth12.targets = JSON.parse(JSON.stringify(this.stateWiseFYPerformanceTargets(this.fyMonth12.month)));

    this.fyMonth01.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth01.month);
    this.fyMonth02.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth02.month);
    this.fyMonth03.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth03.month);
    this.fyMonth04.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth04.month);
    this.fyMonth05.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth05.month);
    this.fyMonth06.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth06.month);
    this.fyMonth07.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth07.month);
    this.fyMonth08.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth08.month);
    this.fyMonth09.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth09.month);
    this.fyMonth10.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth10.month);
    this.fyMonth11.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth11.month);
    this.fyMonth12.targetTotal = this.stateWiseFYPerformanceTargetTotal(this.fyMonth12.month);
    
  }
  stateWiseFYPerformanceTargets(month: number) {
    if (!(!!this.financialYearPerformanceTargets && !!this.financialYearPerformanceTargets.length)) return [] as IStateWiseFYPerformanceTarget[];
    let targets: IStateWiseFYPerformanceTarget[] = [];
    targets = this.financialYearPerformanceTargets.filter(fypt => fypt.month == month);
    return targets;
  }
  stateWiseFYPerformanceTargetTotal(month: number) {
    if (!(!!this.financialYearPerformanceTargets && !!this.financialYearPerformanceTargets.length)) return 0;
    let targetValue: number;
    targetValue = this.financialYearPerformanceTargets.filter(fypt => fypt.month == month).map(fypt => fypt.targetValue).reduce(function (a, b) {
      return (a ?? 0) + (b ?? 0);
    });
    return targetValue;
  }
  stateWisePerformanceTargetTotal(target: IStateWiseFYPerformanceTarget) {    
    let statewiseTarget: number = 0;
    if (!!this.fyMonth01 && !!this.fyMonth01.targets && !!this.fyMonth01.targets.length) {
      let monTarget = this.fyMonth01.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget = monTarget.targetValue;
      }
    }
    if (!!this.fyMonth02 && !!this.fyMonth02.targets && !!this.fyMonth02.targets.length) {
      let monTarget = this.fyMonth02.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth03 && !!this.fyMonth03.targets && !!this.fyMonth03.targets.length) {
      let monTarget = this.fyMonth03.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth04 && !!this.fyMonth04.targets && !!this.fyMonth04.targets.length) {
      let monTarget = this.fyMonth04.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth05 && !!this.fyMonth05.targets && !!this.fyMonth05.targets.length) {
      let monTarget = this.fyMonth05.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth06 && !!this.fyMonth06.targets && !!this.fyMonth06.targets.length) {
      let monTarget = this.fyMonth06.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth07 && !!this.fyMonth07.targets && !!this.fyMonth07.targets.length) {
      let monTarget = this.fyMonth07.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth08 && !!this.fyMonth08.targets && !!this.fyMonth08.targets.length) {
      let monTarget = this.fyMonth08.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth09 && !!this.fyMonth09.targets && !!this.fyMonth09.targets.length) {
      let monTarget = this.fyMonth09.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth10 && !!this.fyMonth10.targets && !!this.fyMonth10.targets.length) {
      let monTarget = this.fyMonth10.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth11 && !!this.fyMonth11.targets && !!this.fyMonth11.targets.length) {
      let monTarget = this.fyMonth11.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    if (!!this.fyMonth12 && !!this.fyMonth12.targets && !!this.fyMonth12.targets.length) {
      let monTarget = this.fyMonth12.targets.find(targ => targ.dashboardSubgroupID == target.dashboardSubgroupID)
      if (!!monTarget) {
        statewiseTarget += monTarget.targetValue ?? 0;
      }
    }
    return statewiseTarget;
  }
  get grandPerformanceTargetTotal(): number {
    return (
    (this.fyMonth01?.targetTotal ?? 0)
      + (this.fyMonth02?.targetTotal ?? 0)
      + (this.fyMonth03?.targetTotal ?? 0)
      + (this.fyMonth04?.targetTotal ?? 0)
      + (this.fyMonth05?.targetTotal ?? 0)
      + (this.fyMonth06?.targetTotal ?? 0)
      + (this.fyMonth07?.targetTotal ?? 0)
      + (this.fyMonth08?.targetTotal ?? 0)
      + (this.fyMonth09?.targetTotal ?? 0)
      + (this.fyMonth10?.targetTotal ?? 0)
      + (this.fyMonth11?.targetTotal ?? 0)
      + (this.fyMonth12?.targetTotal ?? 0)
      );
  }
  /*save(financialMonth: IFinancialMonth) {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new SalesBudgetFinancialYearCommissionBudgetSaveAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          financialMonth
        )
      );
      this.subscription_savecommissionbudget$ =
        this.savecommissionbudget$.subscribe(scb => {
          if (scb) {
            this.onFinancialYearSelectionChange(null);
          }
        });
    }
  }*/
  save() {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      let financialMonths: IFinancialMonth[] = [];
      financialMonths.push(this.fyMonth01);
      financialMonths.push(this.fyMonth02);
      financialMonths.push(this.fyMonth03);
      financialMonths.push(this.fyMonth04);
      financialMonths.push(this.fyMonth05);
      financialMonths.push(this.fyMonth06);
      financialMonths.push(this.fyMonth07);
      financialMonths.push(this.fyMonth08);
      financialMonths.push(this.fyMonth09);
      financialMonths.push(this.fyMonth10);
      financialMonths.push(this.fyMonth11);
      financialMonths.push(this.fyMonth12);

      this.store.dispatch(
        new SalesBudgetFinancialYearCommissionBudgetSaveAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          financialMonths
        )
      );
      /*this.subscription_savecommissionbudget$ =
        this.savecommissionbudget$.subscribe(scb => {
          if (scb) {
            this.onFinancialYearSelectionChange(null);
          }
        });*/
    }
  }
  //#endregion
}

