import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { MaterialModule } from './../material.module';
import { InputComponent } from './input/input.component';
import { PiechartComponent } from './piechart/piechart.component';
import { ConfirmDialogModule } from './confirmdialog/confirmdialog.module';
import { CommaSeparatedNumberPipe } from './pipes/commaseparatednumber.pipe ';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    MDBBootstrapModule.forRoot(),
    RouterModule.forChild([]),
  ],
  declarations: [
    InputComponent,
    PiechartComponent,
    CommaSeparatedNumberPipe
  ],
  exports: [
    InputComponent,
    PiechartComponent,
    CommaSeparatedNumberPipe
  ]
})
export class ComponentsModule { }
