import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { IAppState } from './../../app.state';
import { LoginAction } from './../auth.action';
import updateAndValidateForm from './../../util/update-and-validate-form';
import { EncryptionService } from './../../util/encryption/encryption.Service';
import { errorMessage, isAuthenticated, isLoggingIn } from './../auth.selectors';
import { ComponentBase } from '../../util/componentBase';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends ComponentBase implements OnInit {

  constructor
    (
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private store: Store<IAppState>,    
    private encryption: EncryptionService
  ) {
    super(activatedRoute, snackBar, encryption);
  }

  public readonly loginForm = new FormGroup({
    username: new FormControl("", [Validators.required, Validators.required]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(6)
    ])
  });
  private errorMessage$: any = this.store.pipe(select(errorMessage));
  private isAuthenticated$: any = this.store.pipe(select(isAuthenticated));
  private isLoggingIn$: any = this.store.pipe(select(isLoggingIn));

  ngOnInit(): void {    
  }
  login() {
    if (updateAndValidateForm(this.loginForm)) {
      console.log("localStorage.getItem('auth') =====> ", localStorage.getItem("auth"));
      //let auth = JSON.parse(localStorage.getItem("auth"));
      
      this.store.dispatch(
        new LoginAction(
          this.group,
          this.agencyKey,
          this.loginForm.value.username,
          this.loginForm.value.password,
          false,
          (!!this.group ? "group/dashboard" : "home")
        )
      );
    }
  }

}
