import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync, LocalStorageConfig } from 'ngrx-store-localstorage';
import * as CryptoJS from 'crypto-js';

import { IAppState } from './app.state';
import { authReducer } from './auth/auth.reducer';
import { homeReducer } from './home/home.reducer';
import { reportsReducer } from './reports/reports.reducer';
import { groupReducer } from './group/group.reducer';


export const AppReducer: ActionReducerMap<Partial<IAppState>> = {
  router: routerReducer,
  auth: authReducer,
  home: homeReducer,
  reports: reportsReducer,
  group: groupReducer
}
export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {

  return localStorageSync({
    keys: [{ auth: ['token'] }],
    rehydrate: true
  })(reducer);
}
export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer
];
