import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EffectsModule, Actions } from '@ngrx/effects';

import { HomeComponent } from './home.component';
import { HomeService } from './home.service';
import { HomeRoutingModule } from './home-routing.module';
import { HttpHomeService } from './http-home.service';
import { ComponentsModule } from '../components/components.module';
import { HomeEffects } from './home.effects';
//import { InvalidAgencyKeyComponent } from './auth.guard';
import { MaterialModule } from '../material.module';
import { NewListingsComponent } from './newlistings/newlistings.component';


@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    HomeRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    EffectsModule.forFeature([HomeEffects])
  ],
  declarations: [HomeComponent, NewListingsComponent],
  //entryComponents: [],
  providers: [
    Actions,
    {
      provide: HomeService,
      useClass: HttpHomeService
    }
  ]
})
export class HomeModule { }
