import { Observable } from 'rxjs';
import { IAllowedReport, IReportDetails } from './home.state';

export abstract class HomeService {
 /**
 * Logs in a user
 *
 * @agencyKey Agency key
 * @param sessionID Login session id
 * @param marketingGroupDashboardID MarketingGroupDashboard table's primary key
 */
  public abstract allowedReports(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IAllowedReport[]>;

  /**
  * Get the report details for a particular report
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param marketingGroupDashboardID MarketingGroupDashboard table's primary key
  * @param reportID Report id for which the details will be fetched
  */
  public abstract reportDetails(
    agencyKey: string,
    agencyID: string,
    sessionID: string,    
    marketingGroupDashboardID: string,
    reportID: number
  ): Observable<IReportDetails[]>;

}
