import { Component, Input, Output, OnInit, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable, of, Subscription } from 'rxjs';
import { first, map } from "rxjs/operators";

import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { ActualVsBudgetGraphAction } from '../group.action';
import { actualvsbudget, actualvsbudgetProcessing } from '../group.selectors';
import { IActualVsBudget } from '../group.state';
import { Data } from '../leaderboard/leaderboard.component';

@Component({
  selector: 'multiarray-actualvsbudget',
  templateUrl: './actualvsbudget.component.html',
  styleUrls: ['./actualvsbudget.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class ActualVsBudgetComponent extends ComponentBase implements OnInit, AfterViewInit, OnDestroy {

  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
    ) {
    super(activatedRoute, snackBar, encryption);
    this.customizeLabelCombined = this.customizeLabelCombined.bind(this);
    this.customizeLabel = this.customizeLabel.bind(this);
  }
  @Input() visible: boolean = false;
  @Output() readytonext = new EventEmitter<boolean>();
  private actualvsbudget$ = this.store.pipe(select(actualvsbudget));
  private actualvsbudgetProcessing$ = this.store.pipe(select(actualvsbudgetProcessing));

  private actualvsbudget$_Subscription: Subscription;

  private avb: IActualVsBudgetGraph[] = [];
  private avbCombined: Data[] = [];
  private palette: string[] = ['#EB5544', '#C4C4C4', '#6B7071', '#FFFFFF', '#282826'];
  private groupPalette: string[] = ['#C4C4C4', '#EB5544', '#6B7071', '#FFFFFF', '#282826'];
  private dataRefreshed: boolean = false;

  ngOnInit(): void {

    //#region Data refresh when slide is visible
    setInterval(() => {
      if (this.visible) {
        let auth = JSON.parse(localStorage.getItem("auth"));
        if (!!auth && !this.dataRefreshed) {
          this.fetchData();
          this.dataRefreshed = true;
        }
      }
      else {
        this.dataRefreshed = false;        
      }

    }, 1000);
    //#endregion

    this.fetchData();

  }
  ngAfterViewInit(): void {
    this.avbCombined = [];
    this.avb = [];
    let totalActual: number = 0;
    let totalBudget: number = 0;
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.actualvsbudget$_Subscription =
        this.actualvsbudget$.subscribe(abs => {          
          if (!!abs) {            
            (JSON.parse(JSON.stringify(abs)) as IActualVsBudget[]).forEach((ab, index) => {
              this.avb.push({
                id: ab.id,
                dashboardSubgroupID: ab.dashboardSubgroupID,
                departmentSubgroupCode: ab.departmentSubgroupCode,
                departmentSubgroupName: ab.departmentSubgroupName,
                performanceTargetAgencyID: ab.performanceTargetAgencyID,
                actual: ab.actual,
                budget: ab.budget,
                data: [{ name: `Actual~${ab.dashboardSubgroupID}`, value: ab.actual }, { name: `Budget~${ab.dashboardSubgroupID}`, value: ((ab.budget - ab.actual) >= 0 ? (ab.budget - ab.actual) : 0) }]
              });
              totalActual += ab.actual;
              totalBudget += ab.budget;
            });
            this.avbCombined = [{ name: "Actual", value: totalActual }, { name: "Budget", value: ((totalBudget - totalActual) >= 0 ? (totalBudget - totalActual) : 0) }];

          }
             
        });
    }
  }
  ngOnDestroy(): void {
    if (!!this.actualvsbudget$_Subscription) this.actualvsbudget$_Subscription.unsubscribe();
  }
  fetchData() {
    this.readytonext.emit(false);
    this.avbCombined = [];
    this.avb = [];
    let totalActual: number = 0;
    let totalBudget: number = 0;
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new ActualVsBudgetGraphAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          this.marketingGroupDashboardID
        )
      );



      this.actualvsbudget$_Subscription =
        this.actualvsbudget$.subscribe(abs => {
          if (!!abs) {
            this.avbCombined = [];
            this.avb = [];
            totalActual = 0;
            totalBudget= 0;
            (JSON.parse(JSON.stringify(abs)) as IActualVsBudget[]).forEach((ab, index) => {
              this.avb.push({
                id: ab.id,
                dashboardSubgroupID: ab.dashboardSubgroupID,
                departmentSubgroupCode: ab.departmentSubgroupCode,
                departmentSubgroupName: ab.departmentSubgroupName,
                performanceTargetAgencyID: ab.performanceTargetAgencyID,
                actual: ab.actual,
                budget: ab.budget,
                data: [{ name: `Actual~${ab.dashboardSubgroupID}`, value: ab.actual }, { name: `Budget~${ab.dashboardSubgroupID}`, value: ((ab.budget - ab.actual) >= 0 ? (ab.budget - ab.actual) : 0) }]
              });
              totalActual += ab.actual;
              totalBudget += ab.budget;
            });
            this.avbCombined = [{ name: "Actual", value: totalActual }, { name: "Budget", value: ((totalBudget - totalActual) >= 0 ? (totalBudget - totalActual) : 0) }];

            setTimeout(() => {
              this.readytonext.emit(true);
            }, 30000);
          }

        });

    }
  }
  customizeLabel(e) {
    if (!e.argumentText) return "";
    let argumentText = e.argumentText.split('~').length == 2 ? e.argumentText.split('~')[0] : "";
    let state = (e.argumentText.split('~').length == 2 ? e.argumentText.split('~')[1] : "") + "";
    if (!argumentText || !state) return "";

    if (argumentText.toLowerCase() == "budget")
      return "";//argumentText;
    else {
      if (!!this.avb) {
        /*let data = this.avb.find(a => a.dashboardSubgroupID.toLowerCase() == state.toLowerCase())?.data ?? [] as Data[];
        let budget = data.find(ac => ac.name.toLowerCase().startsWith("budget"))?.value ?? 0;
        let actual = data.find(ac => ac.name.toLowerCase().startsWith("actual"))?.value ?? 0;*/
        let data = this.avb.find(a => a.dashboardSubgroupID.toLowerCase() == state.toLowerCase());
        let budget = 0; let actual = 0;         
        if (!!data) {
          budget = data.budget ?? 0;
          actual = data.actual ?? 0
        }
        //return `${Number(((actual / budget) * 100).toFixed(2)).toLocaleString()}% of Budget`;
        return `${Number(((actual / budget) * 100).toFixed(0)).toLocaleString()}%`;
      }
      else
        return `${e.argumentText}\n${e.valueText}`;
    }
  }
  customizeLabelCombined(e) {
    if (!!e.argumentText && e.argumentText.toLowerCase() == "budget")
      return "";//e.argumentText;
    else {
      if (!!this.avb) {
        /*let budget = this.avbCombined.find(ac => ac.name.toLowerCase() == "budget")?.value ?? 0;
        let actual = this.avbCombined.find(ac => ac.name.toLowerCase() == "actual")?.value ?? 0;*/
        let budget = this.avb.sum("budget");
        let actual = this.avb.sum("actual");
        //return `${Number(((actual / budget) * 100).toFixed(2)).toLocaleString()}% of Budget`;
        return `${Number(((actual / budget) * 100).toFixed(0)).toLocaleString()}%`;
      }
      else
        return `${e.argumentText}\n${e.valueText}`;
    }
  }
  actual(value: IActualVsBudgetGraph): string {
    return `${Number((value?.data?.find(d => d.name.toLowerCase().startsWith("actual"))?.value ?? 0).toFixed(0)).toLocaleString()}`;
  }
  actualForCombined(value: Data[]): string {
    return `${Number((value?.find(d => d.name.toLowerCase().startsWith("actual"))?.value ?? 0).toFixed(0)).toLocaleString()}`;
  }
  get currentMonth(): string {
    return `${new Date().toLocaleString('default', { month: 'short' })} ${new Date().getFullYear()}`;
  }
}
interface IActualVsBudgetGraph extends IActualVsBudget {
  data: Data[];
}
