import { NgModule } from '@angular/core';
//import { AuthRoutingModule } from './auth-routing.module';
import { AuthService } from './auth.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EffectsModule, Actions } from '@ngrx/effects';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { HttpAuthService } from './http-auth.service';
import { ComponentsModule } from '../components/components.module';
import { AuthEffects } from './auth.effects';
import { InvalidAgencyKeyComponent } from './auth.guard';
import { MaterialModule } from '../material.module';


@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    EffectsModule.forFeature([AuthEffects])
  ],
  declarations: [LoginComponent, InvalidAgencyKeyComponent],
  entryComponents: [InvalidAgencyKeyComponent],
  providers: [
    Actions,
    {
      provide: AuthService,
      useClass: HttpAuthService
    }
  ]
})
export class AuthModule { }
