import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { LoginComponent } from './login/login.component';
import { LoginGuard } from './auth.guard';

const authRoutes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full', canActivate: [LoginGuard] },
  { path: 'login/:agencyKey', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'login/group/:agencyKey', component: LoginComponent, canActivate: [LoginGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
