import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
//import { Chart } from 'Chart.js';

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss']
})
export class PiechartComponent implements OnInit {
  constructor() { }

  @Input() chartType: "pie" | "line" | "radar" | "bar" = 'bar';

  @Input() datasets: Array<IDataset> = [
    { data: [300, 50, 100, 40, 120], label: 'My First dataset' }
  ];
  @Input() labels: Array<any> = ['Red', 'Green', 'Yellow', 'Grey', 'Dark Grey'];

  @Input() colors: Array<IColor> = [
    {
      backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
      hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
      borderWidth: 2,
    }
  ];
  @Input() style: string = "";

  @Output() chartHover = new EventEmitter<any>();
  @Output() chartClick = new EventEmitter<any>();

  @Input() options: IOption = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Custom Chart Title'
      }
    }
  };
  @Input() height: number;
  @Input() width: number;

  public chartClicked(e: any): void { this.chartClick.emit(e); }
  public chartHovered(e: any): void { this.chartHover.emit(e); }
 

  ngOnInit() {
    if (!this.chartType) this.chartType = 'pie';
    if (!(!!this.datasets && !!this.datasets.length)) this.datasets = [
      { data: [300, 50, 100, 40, 120], label: 'My First dataset' }
    ];
    if (!(!!this.labels && !!this.labels.length)) this.labels = ['Red', 'Green', 'Yellow', 'Grey', 'Dark Grey'];
    if (!(!!this.colors && !!this.colors.length)) this.colors = [
      {
        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
        borderWidth: 2,
      }
    ];
    if (!this.options) this.options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Custom Chart Title'
        }
      }
    };
    if (!(!!this.style)) {
      this.style = "display: inline-block;";
    }
    else {
      this.style = "display: inline-block;" + this.style;
    }
  }

}
interface IDataset {
  data: any[];
  label: string;
}
interface IColor {
  backgroundColor: any[];
  hoverBackgroundColor: any[];
  borderWidth: number;
}
interface IOption {
  responsive: boolean,
  plugins: {
    title: {
      display: boolean,
      text: string
    }
  }
}
