import { Action } from '@ngrx/store';
import { ILocalStorage } from '../util/encryption/encryption.Service';

export enum ActionTypes {
  Login = '[AUTH] Login',
  LoginSuccess = '[AUTH] Login Success',
  LoginFail = '[AUTH] Login Fail',
  Logout = '[AUTH] Logout',
  LogoutSuccess = '[AUTH] Logout Success',
  LogoutFail = '[AUTH] Logout Fail',
  RemoveErrorMessage = '[AUTH] Emptied Error Message'
}
export class LoginAction implements Action {
  readonly type = ActionTypes.Login;

  constructor(
    public readonly group: string,
    public readonly agencyKey: string,
    public readonly username: string,
    public readonly password: string,
    // TODO: Remove negation in naming. Those are dangerous :danger:
    public readonly dontNavigate: boolean = false,
    public readonly redirectUrl?: string
  ) { }
}
export class LoginSuccessAction implements Action {
  readonly type = ActionTypes.LoginSuccess;

  constructor(
    public readonly data: ILocalStorage,
    public readonly token: string,
    public readonly dontNavigate: boolean = false,
    public readonly redirectUrl?: string
  ) { }
}
export class LoginFailAction implements Action {
  readonly type = ActionTypes.LoginFail;

  constructor(public readonly message: string) { }
}
export class LogoutAction implements Action {
  readonly type = ActionTypes.Logout;

  constructor() { }
}
export class LogoutSuccessAction implements Action {
  readonly type = ActionTypes.LogoutSuccess;

  constructor(
    public readonly auth: ILocalStorage,
    public readonly token: string
  ) { }
}
export class LogoutFailAction implements Action {
  readonly type = ActionTypes.LogoutFail;

  constructor(public readonly message: string) { }
}
export class RemoveErrorMessageAction implements Action {
  readonly type = ActionTypes.RemoveErrorMessage;

  constructor() { }
}

export type AuthAction =
  | LoginAction
  | LoginSuccessAction
  | LoginFailAction
  | LogoutAction
  | LogoutSuccessAction
  | LogoutFailAction
  | RemoveErrorMessageAction;
