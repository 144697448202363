import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { from, Observable, Observer, of } from "rxjs";
import { Action, select, Store } from "@ngrx/store";
import {
  catchError,
  filter,
  first,
  flatMap,
  map,
  mergeMap
} from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ActionTypes,
  AgeOfListingsAction,
  AgeOfListingsSuccessAction,
  AgeOfListingsFailAction,
  AgeOfListingsGraphAction,
  AgeOfListingsGraphSuccessAction,
  AgeOfListingsGraphFailAction,
  StaffPerformancesAction,
  StaffPerformancesSuccessAction,
  StaffPerformancesFailAction,
  RemoveErrorMessageAction,
  StaffPerformancesAgenciesSuccessAction,
  StaffPerformancesAgenciesAction,
  StaffPerformancesAgenciesFailAction,
  SalesBudgetFinancialYearEndAction,
  SalesBudgetFinancialYearEndSuccessAction,
  SalesBudgetFinancialYearEndFailAction,
  SalesBudgetFinancialYearStateWisePerformanceTargetsAction,
  SalesBudgetFinancialYearStateWisePerformanceTargetsSuccessAction,
  SalesBudgetFinancialYearStateWisePerformanceTargetsFailAction,
  SalesBudgetFinancialYearCommissionBudgetSaveAction,
  SalesBudgetFinancialYearCommissionBudgetSaveSuccessAction,
  SalesBudgetFinancialYearCommissionBudgetSaveFailAction
} from "./reports.action";
import { ReportsService } from "./reports.service";
import { IAppState } from "../app.state";

@Injectable()
export class ReportsEffects {
  constructor(
    private store: Store<IAppState>,
    private reportsService: ReportsService,
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  @Effect()
  ageOfListings$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.AgeOfListings),
    mergeMap((action: AgeOfListingsAction) =>
      this.reportsService
        .ageOfListings(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new AgeOfListingsSuccessAction(data)
          ),
          catchError(error => of(new AgeOfListingsFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  ageOfListingsSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.AgeOfListingsSuccess),
    mergeMap((action: AgeOfListingsSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new AgeOfListingsFailAction(error.message)))
  );

  @Effect()
  ageOfListingsGraphs$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.AgeOfListingsGraph),
    mergeMap((action: AgeOfListingsGraphAction) =>
      this.reportsService
        .ageOfListingsGraphs(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID)
        .pipe(
          map(
            data =>
              new AgeOfListingsGraphSuccessAction(data)
          ),
          catchError(error => of(new AgeOfListingsGraphFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  ageOfListingsGraphsSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.AgeOfListingsGraphSuccess),
    mergeMap((action: AgeOfListingsGraphSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new AgeOfListingsGraphFailAction(error.message)))
  );

  @Effect()
  staffperformances$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.StaffPerformances),
    mergeMap((action: StaffPerformancesAction) =>
      this.reportsService
        .staffPerformanceSummary(action.agencyKey, action.agencyID, action.sessionID, action.year, action.quarter, action.agencyIDs, action.dateFrom, action.dateTo)
        .pipe(
          map(
            data =>
              new StaffPerformancesSuccessAction(data)
          ),
          catchError(error => of(new StaffPerformancesFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  staffperformancessuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.StaffPerformancesSuccess),
    mergeMap((action: StaffPerformancesSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new StaffPerformancesFailAction(error.message)))
  );

  @Effect()
  agencies$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.StaffPerformancesAgencies),
    mergeMap((action: StaffPerformancesAgenciesAction) =>
      this.reportsService
        .agencies(action.agencyKey, action.agencyID, action.sessionID)
        .pipe(
          map(
            data =>
              new StaffPerformancesAgenciesSuccessAction(data)
          ),
          catchError(error => of(new StaffPerformancesAgenciesFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  agenciessuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.StaffPerformancesAgenciesSuccess),
    mergeMap((action: StaffPerformancesAgenciesSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new StaffPerformancesAgenciesFailAction(error.message)))
  );

  @Effect()
  financialYearEnds$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.SalesBudgetFinancialYearEnd),
    mergeMap((action: SalesBudgetFinancialYearEndAction) =>
      this.reportsService
        .financialYearEnds(action.agencyKey, action.agencyID, action.sessionID)
        .pipe(
          map(
            data =>
              new SalesBudgetFinancialYearEndSuccessAction(data)
          ),
          catchError(error => of(new SalesBudgetFinancialYearEndFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  financialYearEndsSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.SalesBudgetFinancialYearEndSuccess),
    mergeMap((action: SalesBudgetFinancialYearEndSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new SalesBudgetFinancialYearEndFailAction(error.message)))
  );
  @Effect()
  stateWiseFYPerformanceTargets$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.SalesBudgetFinancialYearStateWisePerformanceTargets),
    mergeMap((action: SalesBudgetFinancialYearStateWisePerformanceTargetsAction) =>
      this.reportsService
        .stateWiseFYPerformanceTargets(action.agencyKey, action.agencyID, action.sessionID, action.marketingGroupDashboardID, action.finYearEnd)
        .pipe(
          map(
            data =>
              new SalesBudgetFinancialYearStateWisePerformanceTargetsSuccessAction(data)
          ),
          catchError(error => of(new SalesBudgetFinancialYearStateWisePerformanceTargetsFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  stateWiseFYPerformanceTargetsSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.SalesBudgetFinancialYearStateWisePerformanceTargetsSuccess),
    mergeMap((action: SalesBudgetFinancialYearStateWisePerformanceTargetsSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new SalesBudgetFinancialYearStateWisePerformanceTargetsFailAction(error.message)))
  );
  @Effect()
  saveCommissionBudget$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.SalesBudgetFinancialYearCommissionBudgetSave),
    mergeMap((action: SalesBudgetFinancialYearCommissionBudgetSaveAction) =>
      this.reportsService
        .saveCommissionBudget(action.agencyKey, action.agencyID, action.sessionID, action.monthTargets)
        .pipe(
          map(
            data =>
              new SalesBudgetFinancialYearCommissionBudgetSaveSuccessAction(data)
          ),
          catchError(error => of(new SalesBudgetFinancialYearCommissionBudgetSaveFailAction(error.message)))
        )
    )
  );
  @Effect({ dispatch: false })
  saveCommissionBudgetSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.SalesBudgetFinancialYearCommissionBudgetSaveSuccess),
    mergeMap((action: SalesBudgetFinancialYearCommissionBudgetSaveSuccessAction) => {
      return of(true);
    }),
    map(() => undefined),
    catchError(error => of(new SalesBudgetFinancialYearStateWisePerformanceTargetsFailAction(error.message)))
  );

}
