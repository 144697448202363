import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '../app.state';
import { IAuthState } from './auth.state';
import { IHomeState } from '../home/home.state';
import { IReportsState } from '../reports/reports.state';
import { IGroupState } from '../group/group.state';


export const isAuthenticated = (state: IAppState) => state.auth.isAuthenticated;
export const sessionID = (state: IAppState) => state.auth.sessionID;
export const agencyID = (state: IAppState) => state.auth.agencyID;
export const agencyKey = (state: IAppState) => state.auth.agencyKey;
export const agencyImageUrl = (state: IAppState) => state.auth.agencyImageUrl;
export const userName = (state: IAppState) => state.auth.userName;
export const logon = (state: IAppState) => state.auth.logon;
export const marketingGroupDashboardID = (state: IAppState) => state.auth.marketingGroupDashboardID;
export const isLoggingIn = (state: IAppState) => state.auth.isLoggingIn;
//export const errorMessage = (state: IAppState) => state.auth.errorMessage;
export const token = (state: IAppState) => state.auth.token;


const getAuthFeatureState = createFeatureSelector<IAuthState>('auth');
const getHomeFeatureState = createFeatureSelector<IHomeState>('home');
const getReportsFeatureState = createFeatureSelector<IReportsState>('reports');
const getGroupFeatureState = createFeatureSelector<IGroupState>('group');
export const errorMessage = createSelector(
  getAuthFeatureState,
  getHomeFeatureState,
  getReportsFeatureState,
  getGroupFeatureState,
  (auth, home, reports, group) => {
    if (!!auth.errorMessage)
      return auth.errorMessage;
    else if (!!home.errorMessage)
      return home.errorMessage;
    else if (!!reports.errorMessage)
      return reports.errorMessage;
    else if (!!group.errorMessage)
      return group.errorMessage;
    else
      return null;
  }
);
