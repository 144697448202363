import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAppState } from '../app.state';
import { LogoutAction, RemoveErrorMessageAction } from '../auth/auth.action';
import { RemoveErrorMessageAction as HomeRemoveErrorMessageAction }  from '../home/home.action';
import { errorMessage, isAuthenticated, logon, agencyKey, agencyImageUrl, isLoggingIn } from '../auth/auth.selectors';
import { EncryptionService } from '../util/encryption/encryption.Service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<IAppState>,
    private encryption: EncryptionService,
    private eref: ElementRef
  ) {
    this.getLoginStatus();
  }
  private errorMessage$ = this.store.pipe(select(errorMessage));
  private isAuthenticated$ = this.store.pipe(select(isAuthenticated));
  private agencyKey$ = this.store.pipe(select(agencyKey));
  private agencyImageUrl$ = this.store.pipe(select(agencyImageUrl));
  private logon$ = this.store.pipe(select(logon));
  private isLoggingIn$: Observable<boolean> = this.store.pipe(select(isLoggingIn));
  private userName$: any;
  private interval: any;
  @ViewChild("dvErrorMessage") dvErrorMessage: ElementRef;

  @ViewChild("drawer", { static: false }) public drawer: MatDrawer;

  private getLoginStatus(timespan: number = 1000, clear: boolean = false): void {
    this.interval = setInterval(() => {
      this.userName$ = this.encryption.userName$();
      this.isAuthenticated$ = this.encryption.isAuthenticated$();
      this.logon$ = this.encryption.logon$();
      this.agencyKey$ = this.encryption.agencyKey$();
      this.agencyImageUrl$ = this.encryption.agencyImageUrl$();
      if (clear) clearInterval(this.interval);
    }, timespan);
  }
  /*isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.drawer.toggle();
  }*/
  onCloseErrorClick() {
    this.store.dispatch(new RemoveErrorMessageAction);
    this.store.dispatch(new HomeRemoveErrorMessageAction);
  }
  logout() {
    this.clearAllIntervals();
    this.store.dispatch(
      new LogoutAction()
    );
    this.getLoginStatus(2000, true);
  }
  onDashboardClick(drawer) {    
    drawer.close();
    this.router.navigate(['/group/dashboard'])
      .then(() => {
        window.location.reload();
      });
  }
  get hasGroupInUrl(): boolean {
    return !!this.router.url && !!this.router.url.toLowerCase().includes("/group");
  }
  clearAllIntervals() {
    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  }
  get CurrentYear(): number {
    return new Date().getFullYear();
  }
}
