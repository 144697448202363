import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable } from 'rxjs';
import { first, map } from "rxjs/operators";

import { ComponentBase } from '../../util/componentBase';
import { IAppState, IDNamePairExtra } from '../../app.state';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { StaffPerformancesAction, StaffPerformancesListingAchieverShowHideAction, StaffPerformancesHighestNumberListingShowHideAction, StaffPerformancesFailAction, StaffPerformancesAgenciesAction } from '../reports.action';
import { staffperformances, isStaffPerformancesProcessing, agencies, isAgenciesProcessing } from '../reports.selectors';
import { IPerformanceSummary, IListingAchiever } from '../reports.state';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-staffperformance',
  templateUrl: './staffperformance.component.html',
  styleUrls: ['./staffperformance.component.scss']
})
export class StaffPerformanceComponent extends ComponentBase implements OnInit {

  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
    ) {
    super(activatedRoute, snackBar, encryption);
  }
  //#region Form controls
  public readonly staffperformanceForm = new FormGroup({
    fromDate: new FormControl(null),
    toDate: new FormControl(null),
    selectedAgencies: new FormControl(null)
  });
  get fromDate() {
    return this.staffperformanceForm.get("fromDate");
  }
  get toDate() {
    return this.staffperformanceForm.get("toDate");
  }
  get selectedAgencies() {
    return this.staffperformanceForm.get("selectedAgencies");
  }
  //#endregion
  private agencies$ = this.store.pipe(select(agencies));
  private isAgenciesProcessing$ = this.store.pipe(select(isAgenciesProcessing));
  private staffperformances$ = this.store.pipe(select(staffperformances));
  private isStaffPerformancesProcessing$ = this.store.pipe(select(isStaffPerformancesProcessing));

  ngOnInit(): void {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(new StaffPerformancesAgenciesAction(this.agencyKey, this.agencyID, this.sessionID));
      this.store.dispatch(
        new StaffPerformancesAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          null,
          null,
          null,
          null,
          null
        )
      );
    }
  }

  onShowHideListingAchieverClick(staffperformance: IPerformanceSummary) {
    this.store.dispatch(
      new StaffPerformancesListingAchieverShowHideAction(
        staffperformance.id,
        !staffperformance.showChild
      )
    );
  }
  onShowHideHighestNumberListingClick(listingachiever: IListingAchiever) {
    this.store.dispatch(
      new StaffPerformancesHighestNumberListingShowHideAction(
        listingachiever.typeID,
        listingachiever.staffID,
        listingachiever.departmentID,
        !listingachiever.showChild
      )
    );
  }
  onAgencyChange(event, agencies: IDNamePairExtra<string, string>[]) {
    this.agencies$.subscribe((agencies: IDNamePairExtra<string, string>[]) => {
      if (!!agencies && !!agencies.length) {
        if (event.source.value == "0" && event.source.selected) {
          this.selectedAgencies.setValue(agencies.map(agency => agency.id));
        }
        else if (event.source.value == "0" && !event.source.selected) {
          this.selectedAgencies.setValue(null);
        }
      }
    });
  }
  onRefreshClick() {
    if (!!this.fromDate.value && !!this.toDate.value) {
      if (new Date(this.fromDate.value) > new Date(this.toDate.value)) {
        this.store.dispatch(new StaffPerformancesFailAction("Invalid date range!"));
        return;
      }
    }
    this.store.dispatch(
      new StaffPerformancesAction(
        this.agencyKey,
        this.agencyID,
        this.sessionID,
        null,
        null,
        (!!this.selectedAgencies.value && !!this.selectedAgencies.value.length) ? this.selectedAgencies.value.filter(ag => ag != "0").join(',') : null,
        !!this.fromDate.value ? new Date(this.fromDate.value).toYYYYMMDD() : null,
        !!this.toDate.value ? new Date(this.toDate.value).toYYYYMMDD() : null,
      )
    );
  }
}
