import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Route } from "@angular/router";
import { EncryptionService, enLoginMode, ILocalStorage } from "./encryption/encryption.Service";

export class ComponentBase {
  protected agencyKey: string;
  protected group: string;
  protected agencyID: string;
  protected sessionID: string;
  protected marketingGroupDashboardID: string;
  constructor(private _route: ActivatedRoute, private _snackBar: MatSnackBar, private _encryption: EncryptionService) {
    this.agencyKey = this._route.snapshot.paramMap.get("agencyKey");
    if (!!_route.snapshot.url.filter(url => url.path.toLowerCase() == 'group').length) {
      this.group = 'group'
    }

    let auth = _encryption.decryptLocationStorage();
    if (!!auth) {
      if (!!this.agencyKey) {
        auth = Object.assign({}, auth, { agencyKey: this.agencyKey, group: "" });
      }
      else {
        this.agencyKey = auth.agencyKey;
      }
      if (!!this.group) {
        auth = Object.assign({}, auth, { group: this.group });
      }
      else {
        this.group = auth.group;
      }
      this.agencyID = auth.agencyID;
      this.sessionID = auth.sessionID;
      this.marketingGroupDashboardID = auth.marketingGroupDashboardID;
    }
    else {
      if (!!this.agencyKey) {
        //auth = Object.assign({} as ILocalStorage, { agencyKey: this.agencyKey });
        auth = {
          sessionID: "",
          group: "",
          agencyID: "",
          agencyKey: this.agencyKey,
          agencyImageUrl: "",
          userName: "",
          logon: enLoginMode.Undefined,
          marketingGroupDashboardID: "",
          isAuthenticated: false
        } as ILocalStorage;
      }
      auth = Object.assign({} as ILocalStorage, auth, { group: !!this.group ? this.group : "" });
    }    
    localStorage.setItem("auth", JSON.stringify({ token: _encryption.encryptLocalStorage(auth) }));
  }

}
