import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { ReportDetailsAction } from '../home.action';
import { IAllowedReport, IReportDetails } from '../home.state';

@Component({
  selector: 'app-newlistings',
  templateUrl: './newlistings.component.html',
  styleUrls: ['./newlistings.component.scss']
})
export class NewListingsComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() allowedreports: Observable<IAllowedReport[]>;

  //#region Subcription Variables
  private allowedreports_Subcription: Subscription;
  #endregion

  private isAllReportDetailsCalled: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private store: Store<IAppState>,
    private encryption: EncryptionService
  ) {
    super(activatedRoute, snackBar, encryption);
  }

  ngOnInit(): void {
    if (this.isAllReportDetailsCalled) return;
    this.allowedreports_Subcription = 
    this.allowedreports
      .subscribe(reports => {
        if (!!reports && !this.isAllReportDetailsCalled) {
          reports.forEach((report, index) => {
            this.store.dispatch(
              new ReportDetailsAction(
                this.agencyKey,
                this.agencyID,
                this.sessionID,
                this.marketingGroupDashboardID,
                report.id
              )
            );
            if (!!this.allowedreports_Subcription) this.allowedreports_Subcription.unsubscribe();
            this.isAllReportDetailsCalled = true;
          });
        }
        
      });
  }
  ngOnDestroy(): void {
    if (!!this.allowedreports_Subcription) this.allowedreports_Subcription.unsubscribe();
  }
  periods(reportDetails: IReportDetails[]) {
    let uniquePeriods: { periodID: number, periodName: string }[] = [];

    reportDetails.forEach((rd, index) => {
      if (index % 2 == 0) {
        if (uniquePeriods.filter(up => up.periodID == rd.periodID).length == 0) {
          uniquePeriods.push({ periodID: rd.periodID, periodName: rd.periodName });
        }
      }
    });
    return uniquePeriods;
  }
  periodValues(periodID: number, reportDetails: IReportDetails[]) {
    let values: { periodID: number; dataTypeID: number, itemName: string, currentPeriodName: string, lastPeriodName: string, currentPeriodValue: string, lastPeriodValue: string }[] = [];
    let summaryHeaderReport = reportDetails.filter(rd => (rd.periodID == periodID || rd.periodID == periodID * 2) && (rd.departmentSubGroupCode == 0 || !rd.departmentSubGroupName));
    if (!!summaryHeaderReport && !!summaryHeaderReport.length) {
      if (summaryHeaderReport[0].summaryHeaderComesFirst) {
        
        summaryHeaderReport.forEach((hdr, index) => {
          if ((index % 2) == 0) {
            values.push({
              periodID: hdr.periodID,
              dataTypeID: hdr.dataTypeID,
              itemName: hdr.summaryHeader,
              currentPeriodName: hdr.periodName,
              lastPeriodName: summaryHeaderReport[index + 1]?.periodName ?? "",
              currentPeriodValue: hdr.periodValue,
              lastPeriodValue: summaryHeaderReport[index + 1]?.periodValue ?? ""
            });
          }
        });

      }
    }
    let reports = reportDetails.filter(rd => (rd.periodID == periodID || rd.periodID == periodID * 2) && (rd.departmentSubGroupCode > 0 && !!rd.departmentSubGroupName));
    reports.forEach((report, index) => {
      if ((index % 2) == 0) {
        values.push({
          periodID: report.periodID,
          dataTypeID: report.dataTypeID,
          itemName: report.departmentSubGroupName,
          currentPeriodName: report.periodName,
          lastPeriodName: reports[index + 1].periodName,
          currentPeriodValue: report.periodValue,
          lastPeriodValue: reports[index + 1].periodValue
        });
      }
    });

    if (!!summaryHeaderReport && !!summaryHeaderReport.length) {
      if (!summaryHeaderReport[0].summaryHeaderComesFirst) {

        summaryHeaderReport.forEach((hdr, index) => {
          if ((index % 2) == 0) {
            values.push({
              periodID: hdr.periodID,
              dataTypeID: hdr.dataTypeID,
              itemName: hdr.summaryHeader,
              currentPeriodName: hdr.periodName,
              lastPeriodName: summaryHeaderReport[index + 1].periodName,
              currentPeriodValue: hdr.periodValue,
              lastPeriodValue: summaryHeaderReport[index + 1].periodValue
            });
          }
        });

      }
    }

    return values;
  }

}
