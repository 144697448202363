import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, concat, flatMap, map } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { HomeService } from './home.service';
import { IAllowedReport, IReportDetails } from './home.state';
import { ServiceResponse } from '../app.state';

@Injectable()
export class HttpHomeService implements HomeService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

  public allowedReports(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IAllowedReport[]> {
    console.log("http home service");
    return this.httpClient
      .post<ServiceResponse<IAllowedReport[]>>('/api/home/AllowedReports', {
        agencyKey: agencyKey,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }
  public reportDetails(
    agencyKey: string,
    agencyID: string,
    sessionID: string,    
    marketingGroupDashboardID: string,
    reportID: number
  ): Observable<IReportDetails[]> {
    console.log("http home service");
    return this.httpClient
      .post<ServiceResponse<IReportDetails[]>>('/api/home/ReportDetails', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID,
        reportID: reportID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }

}
