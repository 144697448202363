import { ActionTypes, HomeAction } from "./home.action";
import IHomeState from "./home.state";


export const getInitialState = (): IHomeState => ({
  allowedReports: [],
  isAllowedReportProcessing: false,
  errorMessage: null
});
export function homeReducer(
  state: IHomeState,
  action: HomeAction
): IHomeState {
  switch (action.type) {

    case ActionTypes.AllowedReport: {
      return { ...state, isAllowedReportProcessing: true };
    }
    case ActionTypes.AllowedReportSuccess: {
      return {
        ...state,
        allowedReports: action.data,
        isAllowedReportProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.AllowedReportFail: {
      return {
        ...state,
        isAllowedReportProcessing: false,
        errorMessage: action.message
      };
    }
    case ActionTypes.ReportDetails: {
      let allowedReports = JSON.parse(JSON.stringify(state.allowedReports));
      let allowedReport = allowedReports.find(ar => ar.id == action.reportID);
      if (!!allowedReport) {
        /*let index = state.allowedReports.indexOf(allowedReport);
        if (index >= 0) {
          allowedReport.isReportDetailsProcessing = true;
          state.allowedReports[index] = allowedReport;
        }*/
        //allowedReport = Object.assign({}, allowedReport, { isReportDetailsProcessing: true });
        allowedReport.isReportDetailsProcessing = true;
      }
      return {
        ...state,
        allowedReports: allowedReports
      };
    }
    case ActionTypes.ReportDetailsSuccess: {

      let allowedReports = JSON.parse(JSON.stringify(state.allowedReports));
      let allowedReport = allowedReports.find(ar => ar.id == action.reportID);
      if (!!allowedReport) {
        allowedReport.reportDetails = action.data;
        allowedReport.isReportDetailsProcessing = false;
      }
      return {
        ...state,
        allowedReports: allowedReports
      };
    }
    case ActionTypes.ReportDetailsFail: {
      return {
        ...state,
        errorMessage: !!state.errorMessage ? state.errorMessage + " " + action.message : state.errorMessage
      };
    }

    // this is necessary since we are using local storage to hydrate part of the state
    case '@ngrx/store/update-reducers' as any: {
      return { ...getInitialState(), ...state };
    }

    case ActionTypes.RemoveErrorMessage:
    case '@ngrx/router-store/navigated' as any: {
      return { ...state, errorMessage: null };
    }

    default: {
      return state || getInitialState();
    }

  }
}
