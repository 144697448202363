import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable } from 'rxjs';
import { first, map } from "rxjs/operators";
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';

import { ComponentBase } from '../util/componentBase';
import { IAppState } from '../app.state';
import { IAuthState } from '../auth/auth.state';
import { EncryptionService, enLoginMode } from '../util/encryption/encryption.Service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private store: Store<IAppState>,
    private encryption: EncryptionService
  ) {
    super(activatedRoute, snackBar, encryption);
  }
  @ViewChild("drawer", { static: false }) public drawer: MatDrawer;

  private currentSlide: number = 1;
  private lastSlide: number = 7;
  private sliderInvterval: any;
  private pdfloaded: boolean = false;
  private gciScrollReachedToEnd: boolean = false;
  private numberOfSalesScrollReachedToEnd: boolean = false;
  private staffScrollReachedToEnd: boolean = false;
  private newListingsScrollReachedToEnd: boolean = false;
  private soldListingsScrollReachedToEnd: boolean = false;
  private readyForMovingToNext: boolean = false;
  private showSlider: boolean = false;

  ngOnInit(): void {
    this.showSlider = false;
    setTimeout(() => {
      this.showSlider = true;
      /*let cs: number = parseInt(localStorage.getItem("currentSlide"));
      this.currentSlide = !!cs ? cs : 1;*/
      this.currentSlide = 1;
      this.sliderPlay();
    }, 2000);
  }
  ngOnDestroy(): void {
    clearInterval(this.sliderInvterval);
  }
  onPlayPauseClick(event) {
    let pause: boolean = event.target.classList.contains("fa-pause-circle-o");
    if (pause) {
      event.target.classList.remove("fa-pause-circle-o");
      event.target.classList.add("fa-play-circle-o");
      clearInterval(this.sliderInvterval);
    }
    else {
      event.target.classList.remove("fa-play-circle-o");
      event.target.classList.add("fa-pause-circle-o");
      this.sliderPlay();
    }
  }
  onSlideClick(slide) {
    this.currentSlide = slide;
    /*localStorage.setItem("currentSlide", this.currentSlide.toString());*/
    this.drawer.close();

    switch (slide) {
      case 1:
        this.pdfloaded = false;
        break;
      case 2:
        this.gciScrollReachedToEnd = false;
        break;
      case 3:
        this.numberOfSalesScrollReachedToEnd = false;
        break;
      case 4:
        this.staffScrollReachedToEnd = false;
        break;
      case 5:
        this.newListingsScrollReachedToEnd = false;
        break;
      case 6:
        this.soldListingsScrollReachedToEnd = false;
        break;
      case 7:
        this.readyForMovingToNext = false;
        break;
    }
  }
  onpdfloaded(event) {
    this.pdfloaded = event;
  }
  ongciscrollend(event) {
    this.gciScrollReachedToEnd = event;
  }
  onnumberofsalesscrollend(event) {
    this.numberOfSalesScrollReachedToEnd = event;
  }
  onStaffScrollend(event) {
    this.staffScrollReachedToEnd = event;
  }
  onNewListingsScrollend(event) {
    this.newListingsScrollReachedToEnd = event;
  }
  onSoldListingsScrollend(event) {
    this.soldListingsScrollReachedToEnd = event;
  }
  onReadyToNext(event) {
    this.readyForMovingToNext = event;
  }
  //#region Blocked
  /*
  sliderPlay() {
    this.sliderInvterval =
      setInterval(() => {
        if (this.currentSlide < this.lastSlide) {
          if (this.currentSlide == 2) {
            if (this.gciScrollReachedToEnd && this.numberOfSalesScrollReachedToEnd) {
              this.currentSlide += 1;
            }
          }
          else if (this.currentSlide == 3) {
            if (this.staffScrollReachedToEnd) {
              this.currentSlide += 1;
            }
          }
          else if (this.currentSlide == 4) {
            if (this.newListingsScrollReachedToEnd) {
              this.currentSlide += 1;
            }
          }
          else {
            this.currentSlide += 1;
          }
        }
        else {
          if (this.currentSlide == 5) {
            if (this.soldListingsScrollReachedToEnd) {
              this.currentSlide = 1;
            }
          }
        }
      }, 10000);
  }
  */
  //#endregion
  sliderPlay() {
    /*this.sliderInvterval =
      setInterval(() => {
        if (this.currentSlide < this.lastSlide) {
          if (this.currentSlide == 2) {
            if (this.gciScrollReachedToEnd && this.numberOfSalesScrollReachedToEnd) {
              this.currentSlide += 1;
            }
          }
          else {
            this.currentSlide += 1;
          }
        }
        else {
          if (this.currentSlide == 3) {
            if (this.staffScrollReachedToEnd) {
              this.currentSlide = 1;
            }
          }
          //this.currentSlide = 1;
        }
      }, 10000);*/

    this.sliderInvterval =
      setInterval(() => {
        if (this.currentSlide < this.lastSlide) {
          if (this.currentSlide == 1) {
            if (this.pdfloaded) {
              this.currentSlide += 1;
            }
          }
          else if (this.currentSlide == 2) {
            if (this.gciScrollReachedToEnd) {
              this.currentSlide += 1;
            }
          }
          else if (this.currentSlide == 3) {
            if (this.numberOfSalesScrollReachedToEnd) {
              this.currentSlide += 1;
            }
          }
          else if (this.currentSlide == 4) {
            if (this.staffScrollReachedToEnd) {
              this.currentSlide += 1;
            }
          }
          else if (this.currentSlide == 5) {
            if (this.newListingsScrollReachedToEnd) {
              this.currentSlide += 1;
            }
          }
          else if (this.currentSlide == 6) {
            if (this.soldListingsScrollReachedToEnd) {
              this.currentSlide += 1;
            }
          }
          else {
            this.currentSlide += 1;
          }
        }
        else {
          if (this.currentSlide == 7) {
            if (this.readyForMovingToNext) {
              this.currentSlide = 1;
            }
          }

          //this.currentSlide = 1;
        }

        /*localStorage.setItem("currentSlide", this.currentSlide.toString());*/

      }, 10000);
  }
}
