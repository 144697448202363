import { Observable } from 'rxjs';
import { IDNamePairExtra } from '../app.state';
import { IDaysOnMarketStaff, IDaysOnMarketGraph, IPerformanceSummary, IFinancialYear, IStateWiseFYPerformanceTarget, IFinancialMonth } from './reports.state';

export abstract class ReportsService {

  /**
  * Get the days on market details report
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param marketingGroupDashboardID MarketingGroupDashboard table's primary key
  */
  public abstract ageOfListings(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IDaysOnMarketStaff[]>;

  /**
  * Get the days on market details report graphs
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param marketingGroupDashboardID MarketingGroupDashboard table's primary key
  */
  public abstract ageOfListingsGraphs(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IDaysOnMarketGraph[]>;

  /**
  * Get agencies
  *
  * @param agencyKey
  * @param agencyID Agency id
  * @param sessionID Login session id
  */
  public abstract agencies(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
  ): Observable<IDNamePairExtra<string, string>[]>;

  /**
  * Get the staff performance summary report
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param year Selected Year
  * @param quarter Selected Quarter
  * @param agencyIDs Agency IDs
  * @param dateFrom From Date
  * @param dateTo To Date
  */
  public abstract staffPerformanceSummary(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    year: number,
    quarter: number,
    agencyIDs: string,
    dateFrom: string,
    dateTo: string
  ): Observable<IPerformanceSummary[]>;

  /**
  * Get the financial year list
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  */
  public abstract financialYearEnds(
    agencyKey: string,
    agencyID: string,
    sessionID: string
  ): Observable<IFinancialYear[]>;
  /**
  * Get the statewise financial year performance target
  *
  * @param agencyKey Agency key
  * @param sessionID Login session id
  * @param marketingGroupDashboardID MarketingGroupDashboard table's primary key
  */
  public abstract stateWiseFYPerformanceTargets(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    finYearEnd: number
  ): Observable<IStateWiseFYPerformanceTarget[]>;
  /**
  * Save commission budget
  *
  * @param agencyKey Agency key
  * @param sessionID Login session id
  * @param marketingGroupDashboardID MarketingGroupDashboard table's primary key
  */
  public abstract saveCommissionBudget(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    monthTargets: IFinancialMonth[]
  ): Observable<boolean>;

}
