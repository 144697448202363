import { Action } from '@ngrx/store';
import { IDNamePairExtra } from '../app.state';
import {
  IGroupState
  , IFileToUpload
  , ILeaderboardPdf
  , ILeaderBoardQuoteOfTheDayText
  , IGCILeader
  , INumberOfSalesLeader
  , IGCIStateStaffLeader
  , IGCIStateStaffDepartmentSubgroup
  , IStatewiseNumberOfNewListings
  , INewListing
  , IStatewiseNumberOfSoldListings
  , ISoldListing
  , IActualVsBudget
} from './group.state';

export enum ActionTypes {
  LeaderBoardGCIGraph = '[GROUP] Fetching LeaderBoard GCI Graph Data',
  LeaderBoardGCIGraphSuccess = '[GROUP] Fetching LeaderBoard GCI Graph Data Success',
  LeaderBoardGCIGraphFail = '[GROUP] Fetching LeaderBoard GCI Graph Data Fail',
  LeaderBoardNumberOfSalesGraph = '[GROUP] Fetching LeaderBoard Number Of Sales Graph Data',
  LeaderBoardNumberOfSalesGraphSuccess = '[GROUP] Fetching LeaderBoard Number Of Sales Graph Data Success',
  LeaderBoardNumberOfSalesGraphFail = '[GROUP] Fetching LeaderBoard Number Of Sales Graph Data Fail',
  LeaderBoardGCIStateStaff = '[GROUP] Fetching LeaderBoard GCI State Staff Data',
  LeaderBoardGCIStateStaffSuccess = '[GROUP] Fetching LeaderBoard GCI State Staff Data Success',
  LeaderBoardGCIStateStaffFail = '[GROUP] Fetching LeaderBoard GCI State Staff Data Fail',
  LeaderBoardPdfUpload = '[GROUP] Uploading LeaderBoard PDF',
  LeaderBoardPdfUploadSuccess = '[GROUP] LeaderBoard PDF Uploading Success',
  LeaderBoardPdfUploadFail = '[GROUP] LeaderBoard PDF Uploading Fail',
  LeaderBoardPdfDelete = '[GROUP] Deleting LeaderBoard PDF',
  LeaderBoardPdfDeleteSuccess = '[GROUP] LeaderBoard PDF Deleting Success',
  LeaderBoardPdfDeleteFail = '[GROUP] LeaderBoard PDF Deleting Fail',
  LeaderBoardPdfShow = '[GROUP] Show LeaderBoard PDF',
  LeaderBoardPdfShowSuccess = '[GROUP] LeaderBoard PDF Show Success',
  LeaderBoardPdfShowFail = '[GROUP] LeaderBoard PDF Show Fail',
  LeaderBoardPdfAll = '[GROUP] Get All LeaderBoard PDFs',
  LeaderBoardPdfAllSuccess = '[GROUP] All LeaderBoard PDF Fetching Success',
  LeaderBoardPdfAllFail = '[GROUP] All LeaderBoard PDF Fetching Fail',

  LeaderBoardQuoteSave = '[GROUP] Saving LeaderBoard Quote',
  LeaderBoardQuoteSaveSuccess = '[GROUP] LeaderBoard Quote Saving Success',
  LeaderBoardQuoteSaveFail = '[GROUP] LeaderBoard Quote Saving Fail',
  LeaderBoardQuoteDelete = '[GROUP] Deleting LeaderBoard Quote',
  LeaderBoardQuoteDeleteSuccess = '[GROUP] LeaderBoard Quote Deleting Success',
  LeaderBoardQuoteDeleteFail = '[GROUP] LeaderBoard Quote Deleting Fail',

  LeaderBoardQuoteShow = '[GROUP] Show LeaderBoard Quote of the day',
  LeaderBoardQuoteShowSuccess = '[GROUP] LeaderBoard Quote of the day Show Success',
  LeaderBoardQuoteShowFail = '[GROUP] LeaderBoard Quote of the day Show Fail',  

  LeaderBoardQuoteAll = '[GROUP] Get All LeaderBoard Quotes',
  LeaderBoardQuoteAllSuccess = '[GROUP] All LeaderBoard Quotes Fetching Success',
  LeaderBoardQuoteAllFail = '[GROUP] All LeaderBoard Quotes Fetching Fail',

  StatewiseNumberOfNewListings = '[GROUP] Fetching State Wise Number of New Listings',
  StatewiseNumberOfNewListingsSuccess = '[GROUP] Fetching State Wise Number of New Listings Success',
  StatewiseNumberOfNewListingsFail = '[GROUP] Fetching State Wise Number of New Listings Fail',
  NewListingDetails = '[GROUP] Fetching New Listings Details',
  NewListingDetailsSuccess = '[GROUP] Fetching New Listings Details Success',
  NewListingDetailsFail = '[GROUP] Fetching New Listings Details Fail',
  StatewiseNumberOfSoldListings = '[GROUP] Fetching State Wise Number of Sold Listings',
  StatewiseNumberOfSoldListingsSuccess = '[GROUP] Fetching State Wise Number of Sold Listings Success',
  StatewiseNumberOfSoldListingsFail = '[GROUP] Fetching State Wise Number of Sold Listings Fail',
  SoldListingDetails = '[GROUP] Fetching Sold Listings Details',
  SoldListingDetailsSuccess = '[GROUP] Fetching Sold Listings Details Success',
  SoldListingDetailsFail = '[GROUP] Fetching Sold Listings Details Fail',
  ActualVsBudgetGraph = '[GROUP] Fetching Actual vs Budget Graph Data',
  ActualVsBudgetGraphSuccess = '[GROUP] Fetching Actual vs Budget Graph Data Success',
  ActualVsBudgetGraphFail = '[GROUP] Fetching Actual vs Budget Graph Data Fail',
  RemoveErrorMessage = '[GROUP] Emptied Error Message'
}

export class LeaderBoardGCIGraphAction implements Action {
  readonly type = ActionTypes.LeaderBoardGCIGraph;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
    public readonly date: Date | null = null
  ) { }
}
export class LeaderBoardGCIGraphSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardGCIGraphSuccess;

  constructor(
    public readonly data: IGCILeader[]
  ) { }
}
export class LeaderBoardGCIGraphFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardGCIGraphFail;

  constructor(
    public readonly message: string
  ) { }
}
export class LeaderBoardNumberOfSalesGraphAction implements Action {
  readonly type = ActionTypes.LeaderBoardNumberOfSalesGraph;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
    public readonly date: Date | null = null
  ) { }
}
export class LeaderBoardNumberOfSalesGraphSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardNumberOfSalesGraphSuccess;

  constructor(
    public readonly data: INumberOfSalesLeader[]
  ) { }
}
export class LeaderBoardNumberOfSalesGraphFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardNumberOfSalesGraphFail;

  constructor(
    public readonly message: string
  ) { }
}
export class LeaderBoardGCIStateStaffAction implements Action {
  readonly type = ActionTypes.LeaderBoardGCIStateStaff;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string
  ) { }
}
export class LeaderBoardGCIStateStaffSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardGCIStateStaffSuccess;

  constructor(
    public readonly data: IGCIStateStaffDepartmentSubgroup[]
  ) { }
}
export class LeaderBoardGCIStateStaffFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardGCIStateStaffFail;

  constructor(
    public readonly message: string
  ) { }
}
export class LeaderBoardPdfUploadAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfUpload;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
    public readonly file: ILeaderboardPdf
  ) { }
}
export class LeaderBoardPdfUploadSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfUploadSuccess;

  constructor(
    public readonly data: ILeaderboardPdf[]
  ) { }
}
export class LeaderBoardPdfUploadFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfUploadFail;

  constructor(
    public readonly message: string
  ) { }
}
export class LeaderBoardPdfDeleteAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfDelete;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
    public readonly id: string
  ) { }
}
export class LeaderBoardPdfDeleteSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfDeleteSuccess;

  constructor(
    public readonly data: ILeaderboardPdf[]
  ) { }
}
export class LeaderBoardPdfDeleteFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfDeleteFail;

  constructor(
    public readonly message: string
  ) { }
}
export class LeaderBoardPdfShowAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfShow;

  constructor(
    public readonly agencyKey: string,
    public readonly marketingGroupDashboardID: string,
    public readonly id: string = null,
  ) { }
}
export class LeaderBoardPdfShowSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfShowSuccess;

  constructor(
    public readonly data: ILeaderboardPdf
  ) { }
}
export class LeaderBoardPdfShowFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfShowFail;

  constructor(
    public readonly message: string
  ) { }
}


export class LeaderBoardQuoteSaveAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteSave;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
    public readonly quoteOfTheDay: string,
    public readonly quoteWrittenBy: string,
    public readonly sortOrder: number,
    public readonly id: string,
  ) { }
}
export class LeaderBoardQuoteSaveSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteSaveSuccess;

  constructor(
    public readonly data: ILeaderBoardQuoteOfTheDayText[]
  ) { }
}
export class LeaderBoardQuoteSaveFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteSaveFail;

  constructor(
    public readonly message: string
  ) { }
}
export class LeaderBoardQuoteDeleteAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteDelete;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
    public readonly id: string
  ) { }
}
export class LeaderBoardQuoteDeleteSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteDeleteSuccess;

  constructor(
    public readonly data: ILeaderBoardQuoteOfTheDayText[]
  ) { }
}
export class LeaderBoardQuoteDeleteFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteDeleteFail;

  constructor(
    public readonly message: string
  ) { }
}
export class LeaderBoardQuoteShowAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteShow;

  constructor(
    public readonly agencyKey: string,
    public readonly marketingGroupDashboardID: string,
    public readonly id: string = null,
  ) { }
}
export class LeaderBoardQuoteShowSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteShowSuccess;

  constructor(
    public readonly data: ILeaderBoardQuoteOfTheDayText
  ) { }
}
export class LeaderBoardQuoteShowFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteShowFail;

  constructor(
    public readonly message: string
  ) { }
}


export class LeaderBoardPdfAllAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfAll;

  constructor(
    public readonly agencyKey: string,
    public readonly marketingGroupDashboardID: string
  ) { }
}
export class LeaderBoardPdfAllSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfAllSuccess;

  constructor(
    public readonly data: ILeaderboardPdf[]
  ) { }
}
export class LeaderBoardPdfAllFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardPdfAllFail;

  constructor(
    public readonly message: string
  ) { }
}

export class LeaderBoardQuoteAllAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteAll;

  constructor(
    public readonly agencyKey: string,
    public readonly marketingGroupDashboardID: string
  ) { }
}
export class LeaderBoardQuoteAllSuccessAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteAllSuccess;

  constructor(
    public readonly data: ILeaderBoardQuoteOfTheDayText[]
  ) { }
}
export class LeaderBoardQuoteAllFailAction implements Action {
  readonly type = ActionTypes.LeaderBoardQuoteAllFail;

  constructor(
    public readonly message: string
  ) { }
}

export class StatewiseNumberOfNewListingsAction implements Action {
  readonly type = ActionTypes.StatewiseNumberOfNewListings;

  constructor(
    public readonly agencyKey: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string
  ) { }
}
export class StatewiseNumberOfNewListingsSuccessAction implements Action {
  readonly type = ActionTypes.StatewiseNumberOfNewListingsSuccess;

  constructor(
    public readonly data: IStatewiseNumberOfNewListings[]
  ) { }
}
export class StatewiseNumberOfNewListingsFailAction implements Action {
  readonly type = ActionTypes.StatewiseNumberOfNewListingsFail;

  constructor(
    public readonly message: string
  ) { }
}
export class NewListingDetailsAction implements Action {
  readonly type = ActionTypes.NewListingDetails;

  constructor(
    public readonly agencyKey: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string
  ) { }
}
export class NewListingDetailsSuccessAction implements Action {
  readonly type = ActionTypes.NewListingDetailsSuccess;

  constructor(
    public readonly data: INewListing[]
  ) { }
}
export class NewListingDetailsFailAction implements Action {
  readonly type = ActionTypes.NewListingDetailsFail;

  constructor(
    public readonly message: string
  ) { }
}
export class StatewiseNumberOfSoldListingsAction implements Action {
  readonly type = ActionTypes.StatewiseNumberOfSoldListings;

  constructor(
    public readonly agencyKey: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string
  ) { }
}
export class StatewiseNumberOfSoldListingsSuccessAction implements Action {
  readonly type = ActionTypes.StatewiseNumberOfSoldListingsSuccess;

  constructor(
    public readonly data: IStatewiseNumberOfSoldListings[]
  ) { }
}
export class StatewiseNumberOfSoldListingsFailAction implements Action {
  readonly type = ActionTypes.StatewiseNumberOfSoldListingsFail;

  constructor(
    public readonly message: string
  ) { }
}
export class SoldListingDetailsAction implements Action {
  readonly type = ActionTypes.SoldListingDetails;

  constructor(
    public readonly agencyKey: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string
  ) { }
}
export class SoldListingDetailsSuccessAction implements Action {
  readonly type = ActionTypes.SoldListingDetailsSuccess;

  constructor(
    public readonly data: ISoldListing[]
  ) { }
}
export class SoldListingDetailsFailAction implements Action {
  readonly type = ActionTypes.SoldListingDetailsFail;

  constructor(
    public readonly message: string
  ) { }
}
export class ActualVsBudgetGraphAction implements Action {
  readonly type = ActionTypes.ActualVsBudgetGraph;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string
  ) { }
}
export class ActualVsBudgetGraphSuccessAction implements Action {
  readonly type = ActionTypes.ActualVsBudgetGraphSuccess;

  constructor(
    public readonly data: IActualVsBudget[]
  ) { }
}
export class ActualVsBudgetGraphFailAction implements Action {
  readonly type = ActionTypes.ActualVsBudgetGraphFail;

  constructor(
    public readonly message: string
  ) { }
}
export class RemoveErrorMessageAction implements Action {
  readonly type = ActionTypes.RemoveErrorMessage;

  constructor() { }
}

export type GroupAction =
  LeaderBoardGCIGraphAction
  | LeaderBoardGCIGraphSuccessAction
  | LeaderBoardGCIGraphFailAction
  | LeaderBoardNumberOfSalesGraphAction
  | LeaderBoardNumberOfSalesGraphSuccessAction
  | LeaderBoardNumberOfSalesGraphFailAction
  | LeaderBoardGCIStateStaffAction
  | LeaderBoardGCIStateStaffSuccessAction
  | LeaderBoardGCIStateStaffFailAction
  | LeaderBoardPdfUploadAction
  | LeaderBoardPdfUploadSuccessAction
  | LeaderBoardPdfUploadFailAction
  | LeaderBoardPdfDeleteAction
  | LeaderBoardPdfDeleteSuccessAction
  | LeaderBoardPdfDeleteFailAction
  | LeaderBoardPdfShowAction
  | LeaderBoardPdfShowSuccessAction
  | LeaderBoardPdfShowFailAction

  | LeaderBoardQuoteSaveAction
  | LeaderBoardQuoteSaveSuccessAction
  | LeaderBoardQuoteSaveFailAction
  | LeaderBoardQuoteDeleteAction
  | LeaderBoardQuoteDeleteSuccessAction
  | LeaderBoardQuoteDeleteFailAction

  | LeaderBoardQuoteShowAction
  | LeaderBoardQuoteShowSuccessAction
  | LeaderBoardQuoteShowFailAction

  | LeaderBoardPdfAllAction
  | LeaderBoardPdfAllSuccessAction
  | LeaderBoardPdfAllFailAction

  | LeaderBoardQuoteAllAction
  | LeaderBoardQuoteAllSuccessAction
  | LeaderBoardQuoteAllFailAction

  | StatewiseNumberOfNewListingsAction
  | StatewiseNumberOfNewListingsSuccessAction
  | StatewiseNumberOfNewListingsFailAction
  | NewListingDetailsAction
  | NewListingDetailsSuccessAction
  | NewListingDetailsFailAction
  | StatewiseNumberOfSoldListingsAction
  | StatewiseNumberOfSoldListingsSuccessAction
  | StatewiseNumberOfSoldListingsFailAction
  | SoldListingDetailsAction
  | SoldListingDetailsSuccessAction
  | SoldListingDetailsFailAction
  | ActualVsBudgetGraphAction
  | ActualVsBudgetGraphSuccessAction
  | ActualVsBudgetGraphFailAction
  | RemoveErrorMessageAction;
