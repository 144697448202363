import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, concat, flatMap, map } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { ReportsService } from './reports.service';
import { IDaysOnMarketStaff, IDaysOnMarketGraph, IPerformanceSummary, IFinancialYear, IStateWiseFYPerformanceTarget, IFinancialMonth } from './reports.state';
import { IDNamePairExtra, ServiceResponse } from '../app.state';

@Injectable()
export class HttpReportsService implements ReportsService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

  public ageOfListings(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IDaysOnMarketStaff[]> {
    console.log("http report service");
    return this.httpClient
      .post<ServiceResponse<IDaysOnMarketStaff[]>>('/api/home/AgeOfListings', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }
  public ageOfListingsGraphs(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IDaysOnMarketGraph[]> {
    console.log("http report service");
    return this.httpClient
      .post<ServiceResponse<IDaysOnMarketGraph[]>>('/api/home/AgeOfListingsGraphs', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }

  public agencies(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
  ): Observable<IDNamePairExtra<string, string>[]> {
    console.log("http report service");
    return this.httpClient
      .post<ServiceResponse<IDNamePairExtra<string, string>[]>>('/api/home/Agencies', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }

  public staffPerformanceSummary(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    year: number,
    quarter: number,
    agencyIDs: string,
    dateFrom: string,
    dateTo: string
  ): Observable<IPerformanceSummary[]> {
    console.log("http report service");
    return this.httpClient
      .post<ServiceResponse<IPerformanceSummary[]>>('/api/home/StaffPerformanceSummary', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        year: year,
        quarter: quarter,
        agencyIDs: agencyIDs,
        dateFrom: dateFrom,
        dateTo: dateTo
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }

  public financialYearEnds(
    agencyKey: string,
    agencyID: string,
    sessionID: string
  ): Observable<IFinancialYear[]> {
    console.log("http report service");
    return this.httpClient
      .post<ServiceResponse<IFinancialYear[]>>('/api/home/FinancialYearEnds', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }
  public stateWiseFYPerformanceTargets(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    finYearEnd: number
  ): Observable<IStateWiseFYPerformanceTarget[]> {
    console.log("http report service");
    return this.httpClient
      .post<ServiceResponse<IStateWiseFYPerformanceTarget[]>>('/api/home/StateWiseFYPerformanceTargets', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID,
        finYearEnd: finYearEnd
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }
  public saveCommissionBudget(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    monthTargets: IFinancialMonth[]
  ): Observable<boolean> {
    console.log("http report service");
    return this.httpClient
      .post<ServiceResponse<boolean>>('/api/home/SaveCommissionBudget', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        monthTargets: monthTargets
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }

}
