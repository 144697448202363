import { Component, Inject, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { IDialogData } from './confirmdialog.state';

@Component({
  selector: 'confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmDialogComponent implements AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private encryption: EncryptionService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData) { }

  private agencyKey: string;
  ngAfterViewInit(): void {
    setTimeout(() => {
      let auth = JSON.parse(localStorage.getItem("auth"));
      if (!!auth) {
        this.encryption.agencyKey$(auth.token).subscribe(ag => {
          this.agencyKey = ag;
        })
      }
    }, 2000);
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }
  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
