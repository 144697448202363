import { Action } from '@ngrx/store';
import { IDNamePairExtra } from '../app.state';
import { IDaysOnMarketGraph, IDaysOnMarketStaff, IPerformanceSummary, IFinancialYear, IStateWiseFYPerformanceTarget, IFinancialMonth } from './reports.state';

export enum ActionTypes {
  AgeOfListings = '[REPORTS] Fetching Age of Listings Report',
  AgeOfListingsSuccess = '[REPORTS] Age of Listings Report Fetching Success',
  AgeOfListingsFail = '[REPORTS] Age of Listings Report Fetching Fail',
  AgeOfListingsAddressShowHide = '[REPORTS] Age of Listings Address Show/Hide',
  AgeOfListingsGraph = '[REPORTS] Age of Listings Pie Chart',
  AgeOfListingsGraphSuccess = '[REPORTS] Age of Listings Pie Chart Success',
  AgeOfListingsGraphFail = '[REPORTS] Age of Listings Pie Chart Fail',
  StaffPerformancesAgencies = '[REPORTS] Staff Performances Agencies DropDown Data',
  StaffPerformancesAgenciesSuccess = '[REPORTS] Staff Performances Agencies DropDown Data Success',
  StaffPerformancesAgenciesFail = '[REPORTS] Staff Performances Agencies DropDown Data Fail',
  StaffPerformances = '[REPORTS] Staff Performances Report',
  StaffPerformancesSuccess = '[REPORTS] Staff Performances Report Success',
  StaffPerformancesFail = '[REPORTS] Staff Performances Report Fail',
  StaffPerformancesListingAchieverShowHide = '[REPORTS] Staff Performances Listings Achiever Show/Hide',
  StaffPerformancesHighestNumberListingShowHide = '[REPORTS] Staff Performances Highest Number Listing Show/Hide',
  SalesBudgetFinancialYearEnd = '[REPORTS] Fetching Financial Years List',
  SalesBudgetFinancialYearEndSuccess = '[REPORTS] Fetching Financial Years List Success',
  SalesBudgetFinancialYearEndFail = '[REPORTS] Fetching Financial Years List Fail',
  SalesBudgetFinancialYearStateWisePerformanceTargets = '[REPORTS] Fetching State Wise Financial Year Performance Tragets',
  SalesBudgetFinancialYearStateWisePerformanceTargetsSuccess = '[REPORTS] Fetching State Wise Financial Year Performance Tragets Success',
  SalesBudgetFinancialYearStateWisePerformanceTargetsFail = '[REPORTS] Fetching State Wise Financial Year Performance Tragets Fail',
  SalesBudgetFinancialYearCommissionBudgetSave = '[REPORTS] Saving State Wise Financial Year Commission Budget',
  SalesBudgetFinancialYearCommissionBudgetSaveSuccess = '[REPORTS]  Saving State Wise Financial Year Commission Budget Success',
  SalesBudgetFinancialYearCommissionBudgetSaveFail = '[REPORTS]  Saving State Wise Financial Year Commission Budget Fail',
  RemoveErrorMessage = '[HOME] Emptied Error Message'
}
export class AgeOfListingsAction implements Action {
  readonly type = ActionTypes.AgeOfListings;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
  ) { }
}
export class AgeOfListingsSuccessAction implements Action {
  readonly type = ActionTypes.AgeOfListingsSuccess;

  constructor(
    public readonly data: IDaysOnMarketStaff[]
  ) { }
}
export class AgeOfListingsFailAction implements Action {
  readonly type = ActionTypes.AgeOfListingsFail;

  constructor(
    public readonly message: string
  ) { }
}
export class AgeOfListingsAddressShowHideAction implements Action {
  readonly type = ActionTypes.AgeOfListingsAddressShowHide;

  constructor(
    public readonly staffID: string,
    public readonly show: boolean,
  ) { }
}
export class AgeOfListingsGraphAction implements Action {
  readonly type = ActionTypes.AgeOfListingsGraph;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string
  ) { }
}
export class AgeOfListingsGraphSuccessAction implements Action {
  readonly type = ActionTypes.AgeOfListingsGraphSuccess;

  constructor(
    public readonly data: IDaysOnMarketGraph[]
  ) { }
}
export class AgeOfListingsGraphFailAction implements Action {
  readonly type = ActionTypes.AgeOfListingsGraphFail;

  constructor(
    public readonly message: string
  ) { }
}
export class StaffPerformancesAgenciesAction implements Action {
  readonly type = ActionTypes.StaffPerformancesAgencies;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string
  ) { }
}
export class StaffPerformancesAgenciesSuccessAction implements Action {
  readonly type = ActionTypes.StaffPerformancesAgenciesSuccess;

  constructor(
    public readonly data: IDNamePairExtra<string, string>[]
  ) { }
}
export class StaffPerformancesAgenciesFailAction implements Action {
  readonly type = ActionTypes.StaffPerformancesAgenciesFail;

  constructor(
    public readonly message: string
  ) { }
}
export class StaffPerformancesAction implements Action {
  readonly type = ActionTypes.StaffPerformances;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly year: number = 0,
    public readonly quarter: number = 0,
    public readonly agencyIDs: string = null,
    public readonly dateFrom: string = null,
    public readonly dateTo: string = null
  ) { }
}
export class StaffPerformancesSuccessAction implements Action {
  readonly type = ActionTypes.StaffPerformancesSuccess;

  constructor(
    public readonly data: IPerformanceSummary[]
  ) { }
}
export class StaffPerformancesFailAction implements Action {
  readonly type = ActionTypes.StaffPerformancesFail;

  constructor(
    public readonly message: string
  ) { }
}
export class StaffPerformancesListingAchieverShowHideAction implements Action {
  readonly type = ActionTypes.StaffPerformancesListingAchieverShowHide;

  constructor(
    public readonly typeID: string,
    public readonly show: boolean,
  ) { }
}
export class StaffPerformancesHighestNumberListingShowHideAction implements Action {
  readonly type = ActionTypes.StaffPerformancesHighestNumberListingShowHide;

  constructor(
    public readonly typeID: string,
    public readonly staffID: string,
    public readonly departmentID: string,
    public readonly show: boolean,
  ) { }
}
export class SalesBudgetFinancialYearEndAction implements Action {
  readonly type = ActionTypes.SalesBudgetFinancialYearEnd;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string
  ) { }
}
export class SalesBudgetFinancialYearEndSuccessAction implements Action {
  readonly type = ActionTypes.SalesBudgetFinancialYearEndSuccess;

  constructor(
    public readonly data: IFinancialYear[]
  ) { }
}
export class SalesBudgetFinancialYearEndFailAction implements Action {
  readonly type = ActionTypes.SalesBudgetFinancialYearEndFail;

  constructor(
    public readonly message: string
  ) { }
}
export class SalesBudgetFinancialYearStateWisePerformanceTargetsAction implements Action {
  readonly type = ActionTypes.SalesBudgetFinancialYearStateWisePerformanceTargets;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
    public readonly finYearEnd: number
  ) { }
}
export class SalesBudgetFinancialYearStateWisePerformanceTargetsSuccessAction implements Action {
  readonly type = ActionTypes.SalesBudgetFinancialYearStateWisePerformanceTargetsSuccess;

  constructor(
    public readonly data: IStateWiseFYPerformanceTarget[]
  ) { }
}
export class SalesBudgetFinancialYearStateWisePerformanceTargetsFailAction implements Action {
  readonly type = ActionTypes.SalesBudgetFinancialYearStateWisePerformanceTargetsFail;

  constructor(
    public readonly message: string
  ) { }
}
export class SalesBudgetFinancialYearCommissionBudgetSaveAction implements Action {
  readonly type = ActionTypes.SalesBudgetFinancialYearCommissionBudgetSave;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly monthTargets: IFinancialMonth[]
  ) { }
}
export class SalesBudgetFinancialYearCommissionBudgetSaveSuccessAction implements Action {
  readonly type = ActionTypes.SalesBudgetFinancialYearCommissionBudgetSaveSuccess;

  constructor(
    public readonly data: boolean
  ) { }
}
export class SalesBudgetFinancialYearCommissionBudgetSaveFailAction implements Action {
  readonly type = ActionTypes.SalesBudgetFinancialYearCommissionBudgetSaveFail;

  constructor(
    public readonly message: string
  ) { }
}
export class RemoveErrorMessageAction implements Action {
  readonly type = ActionTypes.RemoveErrorMessage;

  constructor() { }
}
export type HomeAction =
  | AgeOfListingsAction
  | AgeOfListingsSuccessAction
  | AgeOfListingsFailAction
  | AgeOfListingsAddressShowHideAction
  | AgeOfListingsGraphAction
  | AgeOfListingsGraphSuccessAction
  | AgeOfListingsGraphFailAction
  | StaffPerformancesAgenciesAction
  | StaffPerformancesAgenciesSuccessAction
  | StaffPerformancesAgenciesFailAction
  | StaffPerformancesAction
  | StaffPerformancesSuccessAction
  | StaffPerformancesFailAction
  | StaffPerformancesListingAchieverShowHideAction
  | StaffPerformancesHighestNumberListingShowHideAction
  | SalesBudgetFinancialYearEndAction
  | SalesBudgetFinancialYearEndSuccessAction
  | SalesBudgetFinancialYearEndFailAction
  | SalesBudgetFinancialYearStateWisePerformanceTargetsAction
  | SalesBudgetFinancialYearStateWisePerformanceTargetsSuccessAction
  | SalesBudgetFinancialYearStateWisePerformanceTargetsFailAction
  | SalesBudgetFinancialYearCommissionBudgetSaveAction
  | SalesBudgetFinancialYearCommissionBudgetSaveSuccessAction
  | SalesBudgetFinancialYearCommissionBudgetSaveFailAction
  | RemoveErrorMessageAction;
