import { Component, Input, Output, OnInit, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable, of, Subscription } from 'rxjs';
import { first, map } from "rxjs/operators";

import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { StatewiseNumberOfNewListingsAction, NewListingDetailsAction } from '../group.action';
import { statewisenumberofnewlistings, statewisenumberofnewlistingsProcessing, newlistingdetails, newlistingdetailsProcessing } from '../group.selectors';
import { IStatewiseNumberOfNewListings, INewListing } from '../group.state';

@Component({
  selector: 'multiarray-newlistings',
  templateUrl: './newlistings.component.html',
  styleUrls: ['./newlistings.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class NewListingsComponent extends ComponentBase implements OnInit, AfterViewInit, OnDestroy {

  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
    ) {
    super(activatedRoute, snackBar, encryption);
  }
  @Input() visible: boolean = false;
  @Output() scrollend = new EventEmitter<boolean>();
  private statewisenumberofnewlistings$ = this.store.pipe(select(statewisenumberofnewlistings));
  private statewisenumberofnewlistingsProcessing$ = this.store.pipe(select(statewisenumberofnewlistingsProcessing));
  private newlistingdetails$ = this.store.pipe(select(newlistingdetails));
  private newlistingdetailsProcessing$ = this.store.pipe(select(newlistingdetailsProcessing));

  private statewisenumberofnewlistings: IStatewiseNumberOfNewListings[] = [];
  private newlistingdetails: INewListing[] = [];
  private scrollNewListingPos: number = 0;
  private scrollNLInterval: any;
  private scrollInitialWaitTimout: any;
  private scrollEndWaitTimout: any;
  private statewiseMonthlyNOLShowInterval: any;
  private statewiseMonthlyNOLShowIndex: number = 0;
  private dataNLRefreshed: boolean = false;
  private dataScrollNLEnabled: boolean = false;

  private statewisenumberofnewlistings$_Subscription: Subscription;
  private newlistingdetails$_Subscription: Subscription;

  ngOnInit(): void {

    //#region Data refresh when slide is visible
    setInterval(() => {
      if (this.visible) {
        let auth = JSON.parse(localStorage.getItem("auth"));
        if (!!auth && !this.dataNLRefreshed) {
          this.fetchData();
          this.dataNLRefreshed = true;
        }
        else if (this.dataNLRefreshed && !this.dataScrollNLEnabled) {
          //if (!!this.newlistingdetails && !!this.newlistingdetails.length) {
            this.newListingScroll();
            this.dataScrollNLEnabled = true;
          //}
        }
      }
      else {
        this.dataNLRefreshed = false;
        this.dataScrollNLEnabled = false;
        this.statewisenumberofnewlistings = [];
        this.newlistingdetails = [];
        if (!!this.statewisenumberofnewlistings$_Subscription) this.statewisenumberofnewlistings$_Subscription.unsubscribe();
        if (!!this.newlistingdetails$_Subscription) this.newlistingdetails$_Subscription.unsubscribe();
        clearInterval(this.scrollNLInterval);
      }

    }, 1000);
    //#endregion

    //this.fetchData();
    
  }
  ngAfterViewInit(): void {
    //this.newListingScroll();
    this.statewiseMonthlyNOLShow();
  }
  ngOnDestroy(): void {
    if (!!this.statewisenumberofnewlistings$_Subscription) this.statewisenumberofnewlistings$_Subscription.unsubscribe();
    if (!!this.newlistingdetails$_Subscription) this.newlistingdetails$_Subscription.unsubscribe();
  }
  fetchData() {
    this.statewisenumberofnewlistings = [];
    this.newlistingdetails = [];
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new StatewiseNumberOfNewListingsAction(
          this.agencyKey,
          this.sessionID,
          this.marketingGroupDashboardID
        )
      );
      this.store.dispatch(
        new NewListingDetailsAction(
          this.agencyKey,
          this.sessionID,
          this.marketingGroupDashboardID
        )
      );

      this.statewisenumberofnewlistings$_Subscription =
        this.statewisenumberofnewlistings$.subscribe(swnol => {
          this.statewisenumberofnewlistings = JSON.parse(JSON.stringify(swnol)) as IStatewiseNumberOfNewListings[];
          this.statewiseMonthlyNOLShowIndex = 0;
        });

      this.newlistingdetails$_Subscription =
        this.newlistingdetails$.subscribe(nld => {
          if (!!nld && !!nld.length) {
            console.log("nld.length: ", nld.length);
            this.newlistingdetails = nld;//Object.assign([] as INewListing[], nld);
            /*for (var i = nld.length; i <= 33; i++) {

              this.newlistingdetails.push({
                id: null,
                dashboardSubgroupID: null,
                departmentSubgroupCode: null,
                departmentSubgroupName: null,
                listerStaffID: null,
                listerStaffName: null,
                addressID: null,
                textFormatLS: null,
                saleMethodID: null,
                saleMethodName: null,
                currentPriceFrom: null,
                dateListed: null,
                weekStart: null,
                weekEnd: null,
              } as INewListing);

            }*/
            console.log("nld.length: ", nld.length);
          }
        });
    }
  }
  private height: number;
  newListingScroll() {
    let scrollEndFlagReturnInterval;
    this.scrollNLInterval =
      setInterval(() => {        
        if (!this.visible) {
          this.scrollNewListingPos = 0;
          clearInterval(scrollEndFlagReturnInterval);
          return;
        }
        if (!!document.getElementById("dvNewListings")) {
          let scrollHeight = document.getElementById("dvNewListings").scrollHeight;
          this.height = scrollHeight;
          if (this.scrollNewListingPos < scrollHeight) {
            if (this.scrollNewListingPos == 0) {
              this.scrollInitialWaitTimout =
                setTimeout(() => {
                  if (!this.visible) {
                    this.scrollNewListingPos = 0;
                    return;
                  }
                  this.scrollNewListingPos += 1;
                  this.scrollend.emit(false);
                  if (!!document.getElementById("dvNewListings")) {
                    document.getElementById("dvNewListings").scrollTop = this.scrollNewListingPos;
                  }
                  clearTimeout(this.scrollInitialWaitTimout);

                }, 5000);
            }
            else if (this.scrollNewListingPos == (scrollHeight - 475)) {
              this.scrollNewListingPos = scrollHeight;
              this.scrollend.emit(false);
            }
            else {
              this.scrollNewListingPos += 1;
              this.scrollend.emit(false);
              document.getElementById("dvNewListings").scrollTop = this.scrollNewListingPos;
            }
          }
          else {
            this.scrollend.emit(true);
            this.scrollEndWaitTimout =
              setTimeout(() => {
                if (!this.visible) {
                  this.scrollNewListingPos = 0;
                  return;
                }
                //this.scrollNewListingPos = 0;
                this.scrollend.emit(true);
                //if (!!this.newlistingdetails && this.newlistingdetails.length < 33) {
                //  clearInterval(this.scrollNLInterval);
                //}

                scrollEndFlagReturnInterval =
                  setInterval(() => {
                    this.scrollend.emit(true);
                    //if (!!this.newlistingdetails && this.newlistingdetails.length < 33) {
                    //  clearInterval(this.scrollNLInterval);
                    //}
                  }, 2000);
                //setTimeout(() => { this.visible = false; }, 5000);
                /*if (!!document.getElementById("dvNewListings")) {
                  document.getElementById("dvNewListings").scrollTop = this.scrollNewListingPos;
                }*/
                clearTimeout(this.scrollEndWaitTimout);

              }, 5000);
          }
        }
      }, 25);
  }
  statewiseMonthlyNOLShow() {
    this.statewiseMonthlyNOLShowInterval =
      setInterval(() => {
        if (!this.visible) {
          this.statewiseMonthlyNOLShowIndex = 0;
          if (!!this.statewisenumberofnewlistings && !!this.statewisenumberofnewlistings.length) {
            this.statewisenumberofnewlistings.forEach((value, index) => {
              value.showNumberOfListings = false;
            });            
          }
          return;
        }

        if (!!this.statewisenumberofnewlistings && !!this.statewisenumberofnewlistings.length) {
          if (this.statewiseMonthlyNOLShowIndex < this.statewisenumberofnewlistings.length) {
            this.statewisenumberofnewlistings[this.statewiseMonthlyNOLShowIndex++].showNumberOfListings = true;
          }
          else {
            /*this.statewisenumberofnewlistings.forEach((value, index) => {
              value.showNumberOfListings = false;
            });*/
            this.statewiseMonthlyNOLShowIndex = 0;
          }
        }

      }, 1000);
    
  }
  //#region Properties
  get weekStartDate(): Date {
    if (!!this.newlistingdetails && !!this.newlistingdetails.length) {
      return this.newlistingdetails[0].weekStart;
    }
    else {
      return null;
    }
  }
  get weekEndDate(): Date {
    if (!!this.newlistingdetails && !!this.newlistingdetails.length) {
      return this.newlistingdetails[0].weekEnd;
    }
    else {
      return null;
    }
  }
  get newlistingCountThisWeek(): number {
    if (!!this.newlistingdetails && !!this.newlistingdetails.length) {
      return this.newlistingdetails.length;
    }
    else {
      return null;
    }
  }
  //#endregion
}
