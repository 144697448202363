import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppSettings } from './appSettings';


@Injectable()
export class AppSettingService {

  private _AppSettings: AppSettings;
  public get AppSettings() {
    return this._AppSettings;
  }

  constructor(private http: HttpClient) { }
  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get("./../assets/appsettings.json")
        .pipe(map((res: any) => {
          var result: AppSettings;
          if (!!res && !!res.AppSettings) {
            result = res.AppSettings as AppSettings;
          }
          return result;
        }),
          catchError(err => {
            resolve(err);
            console.log("error in AppSettingService.load(): ", err);
            return err;
          }))
        .subscribe((response: AppSettings) => {
          this._AppSettings = response;
          resolve(true);
        })
    });
  }
}
