import { Component, AfterViewInit } from '@angular/core';
import { EncryptionService } from './util/encryption/encryption.Service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {
  constructor(
    private encryption: EncryptionService
  ) {
  }
  title = 'app';
  private agencyKey: string;

  ngAfterViewInit(): void {
    setTimeout(() => {
      let auth = JSON.parse(localStorage.getItem("auth"));
      if (!!auth) {
        this.encryption.agencyKey$(auth.token).subscribe(ag => {          
          this.agencyKey = ag == "2737" ? ag : "basetheme";
        })
      }
    }, 2000);
  }
}
