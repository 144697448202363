import { Component, Input, Output, OnInit, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable, of, Subscription } from 'rxjs';
import { first, map } from "rxjs/operators";

import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { LeaderBoardGCIStateStaffAction } from '../group.action';
import { leaderboardgcistatestaff, leaderboardgcistatestaffProcessing } from '../group.selectors';
import { IGCIStateStaffDepartmentSubgroup, IGCIStateStaffLeader } from '../group.state';

@Component({
  selector: 'multiarray-stateleaderboard',
  templateUrl: './stateleaderboard.component.html',
  styleUrls: ['./stateleaderboard.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class StateLeaderboardComponent extends ComponentBase implements OnInit, AfterViewInit, OnDestroy {

  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
    ) {
    super(activatedRoute, snackBar, encryption);
  }
  @Input() visible: boolean = false;
  @Output() scrollend = new EventEmitter<boolean>();
  private leaderboardgcistatestaff$ = this.store.pipe(select(leaderboardgcistatestaff));
  private leaderboardgcistatestaffProcessing$ = this.store.pipe(select(leaderboardgcistatestaffProcessing));

  private leaderboardgcistatestaff: IGCIStateStaffSplitted[] = [];
  private scrollStateLeaderboardPos: number = 0;
  private scrollInterval: any;
  private scrollInitialWaitTimout: any;
  private scrollEndWaitTimout: any;
  private currentStateIndex: number = 0;
  private state: IGCIStateStaffSplitted = {
    departmentSubgroupID: "",
    departmentSubgroupName: "",
    selectedStaff: {} as IGCIStateStaffLeader,
    splittedStaffs: [[]]
  } as IGCIStateStaffSplitted;
  private dataRefreshed: boolean = false;

  private leaderboardgcistatestaff$_Subscription: Subscription;

  ngOnInit(): void {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.fetchData();
    }
    setInterval(() => {      
      if (!this.visible) {
        this.currentStateIndex = 0;
        if (!!this.leaderboardgcistatestaff && !!this.leaderboardgcistatestaff.length) {
          this.state = this.leaderboardgcistatestaff[0];
          if (!!this.leaderboardgcistatestaff$_Subscription) this.leaderboardgcistatestaff$_Subscription.unsubscribe();
        }
      }      
    }, 1000);

    //#region Data refresh when slide is visible
    /*setInterval(() => {
      //if (this.visible) {
      //  let auth = JSON.parse(localStorage.getItem("auth"));
      //  if (!!auth && !this.dataRefreshed) {
      //    this.fetchData();
      //    this.dataRefreshed = true;
      //  }
      //}
      //else {
      //  this.dataRefreshed = false;
      //  this.leaderboardgcistatestaff = [];
      //  this.state = {
      //    departmentSubgroupID: "",
      //    departmentSubgroupName: "",
      //    selectedStaff: {} as IGCIStateStaffLeader,
      //    splittedStaffs: [[]]
      //  } as IGCIStateStaffSplitted;
      //  clearInterval(this.scrollInterval);
      //  this.currentStateIndex = 0;
      //}
      let auth = JSON.parse(localStorage.getItem("auth"));
      if (!!auth) {
        this.fetchData();
      }

    }, (600 * 1000));*/
    //#endregion

    //this.fetchData();

  }
  ngAfterViewInit(): void {
    /*setTimeout(() => {
      setInterval(() => {

        if (!!document.getElementById("dvStateLeaderboard")) {

          let scrollHeight = document.getElementById("dvStateLeaderboard").scrollHeight;
          if (this.scrollStateLeaderboardPos < scrollHeight) {
            this.scrollStateLeaderboardPos += 1;
          }
          else {
            this.scrollStateLeaderboardPos = 0;
          }
          document.getElementById("dvStateLeaderboard").scrollTop = this.scrollStateLeaderboardPos;
        }

      }, 100);


    }, 4000);*/
    //this.staffScroll();

  }
  ngOnDestroy(): void {
    if (!!this.leaderboardgcistatestaff$_Subscription) this.leaderboardgcistatestaff$_Subscription.unsubscribe();
  }
  fetchData() {
    this.scrollend.emit(false);
    clearInterval(this.scrollInterval);
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.leaderboardgcistatestaff = [];
      this.state = {
        departmentSubgroupID: "",
        departmentSubgroupName: "",
        selectedStaff: {} as IGCIStateStaffLeader,
        splittedStaffs: [[]]
      } as IGCIStateStaffSplitted;
      this.store.dispatch(
        new LeaderBoardGCIStateStaffAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          this.marketingGroupDashboardID
        )
      );
      this.leaderboardgcistatestaff$_Subscription =
        this.leaderboardgcistatestaff$.subscribe(lb => {
          this.leaderboardgcistatestaff = [];
          let lgss: IGCIStateStaffSplitted[] = []
          this.state = {
            departmentSubgroupID: "",
            departmentSubgroupName: "",
            selectedStaff: {} as IGCIStateStaffLeader,
            splittedStaffs: [[]]
          } as IGCIStateStaffSplitted;
          if (!!lb && !!lb.length) {
            lb.forEach(l => {
              lgss.push({
                departmentSubgroupID: l.departmentSubgroupID,
                departmentSubgroupName: l.departmentSubgroupName,
                selectedStaff: l.selectedStaff,
                splittedStaffs: this.getFiveSplittedStaffs(l.gciStateStaffs)
              });
            });
            this.leaderboardgcistatestaff = lgss;
            setTimeout(() => {
              if (!!this.leaderboardgcistatestaff && !!this.leaderboardgcistatestaff.length) {
                this.state = this.leaderboardgcistatestaff[0];
                this.currentStateIndex = 0;
              }
              this.staffScroll();
              if (!!this.leaderboardgcistatestaff$_Subscription) this.leaderboardgcistatestaff$_Subscription.unsubscribe();
            }, 5000);
          }
        });
    }
  }

  getFiveSplittedStaffs(gciStateStaffs: IGCIStateStaffLeader[]): [IGCIStateStaffLeader[]] {
    let staffs: [IGCIStateStaffLeader[]] = [[]];
    let gciStateStaffsExceptFirst = gciStateStaffs.slice(1, gciStateStaffs.length);
    let splitLength = Math.ceil(gciStateStaffsExceptFirst.length / 5);
    staffs.pop();
    for (let index = 0; index < splitLength; index++) {
      let staffarray = gciStateStaffsExceptFirst.slice(5 * index, (5 * index + 5));
      for (let index = staffarray.length; index < 5; index++) {
        staffarray.push({} as IGCIStateStaffLeader);
      }
      staffs.push(staffarray);
    }
    for (let index = staffs.length; index < 2; index++) {
      let staffarray = gciStateStaffsExceptFirst.slice(5 * index, (5 * index + 5));
      for (let index = staffarray.length; index < 5; index++) {
        staffarray.push({} as IGCIStateStaffLeader);
      }
      staffs.push(staffarray);
    }

    return staffs;
  }
  //private height: number;
  /*staffScroll() {
    this.scrollInterval =
      setInterval(() => {        
        if (!this.visible) {
          this.scrollStateLeaderboardPos = 0;
          return;
        }
        if (!!document.getElementById("dvStateLeaderboard")) {
          let scrollHeight = document.getElementById("dvStateLeaderboard").scrollHeight;
          //this.height = scrollHeight;
          if (this.scrollStateLeaderboardPos < scrollHeight) {
            if (this.scrollStateLeaderboardPos == 0) {
              this.scrollInitialWaitTimout =
                setTimeout(() => {
                  if (!this.visible) {
                    this.scrollStateLeaderboardPos = 0;
                    return;
                  }
                  this.scrollStateLeaderboardPos += 1;
                  this.scrollend.emit(false);
                  if (!!document.getElementById("dvStateLeaderboard")) {
                    document.getElementById("dvStateLeaderboard").scrollTop = this.scrollStateLeaderboardPos;
                  }
                  clearTimeout(this.scrollInitialWaitTimout);

                }, 5000);
            }
            else if (this.scrollStateLeaderboardPos == (scrollHeight - 500)) {
              this.scrollStateLeaderboardPos = scrollHeight;
              this.scrollend.emit(false);
            }
            else {
              this.scrollStateLeaderboardPos += 1;
              this.scrollend.emit(false);
              document.getElementById("dvStateLeaderboard").scrollTop = this.scrollStateLeaderboardPos;
            }
          }
          else {
            this.scrollend.emit(true);
            this.scrollEndWaitTimout =
              setTimeout(() => {
                if (!this.visible) {
                  this.scrollStateLeaderboardPos = 0;
                  return;
                }
                this.scrollStateLeaderboardPos = 0;
                this.scrollend.emit(true);
                //if (!!document.getElementById("dvStateLeaderboard")) {
                //  document.getElementById("dvStateLeaderboard").scrollTop = this.scrollStateLeaderboardPos;
                //}
                clearTimeout(this.scrollEndWaitTimout);

              }, 5000);
          }
        }
      }, 25);
  }*/
  staffScroll() {
    this.currentStateIndex = 0;
    this.scrollend.emit(false);
    this.scrollInterval =
      setInterval(() => {        
        if (!this.visible) {
          this.currentStateIndex = 0;
          return;
        }
        if (!!(this.leaderboardgcistatestaff?.length ?? 0)) {
          if (this.currentStateIndex < this.leaderboardgcistatestaff.length) {
            this.state = this.leaderboardgcistatestaff[this.currentStateIndex];
            this.currentStateIndex += 1;
            this.scrollend.emit(false);
          }
          else {
            this.currentStateIndex = 0;
            this.scrollend.emit(true);
          }
        }
        else {
          this.currentStateIndex = 0;
          this.scrollend.emit(true);
        }

      }, 20000);
  }
}
interface IGCIStateStaffSplitted {
  departmentSubgroupID: string;
  departmentSubgroupName: string;
  selectedStaff: IGCIStateStaffLeader;
  splittedStaffs: [IGCIStateStaffLeader[]];
}
