import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EffectsModule, Actions } from '@ngrx/effects';

import { MaterialModule } from '../material.module';
import { DevExtremeModule } from 'devextreme-angular';
import { ComponentsModule } from '../components/components.module';
import { GroupRoutingModule } from './group-routing.module';
/*import { GroupService } from './group.service';
import { HttpGroupService } from './http-group.service';
import { GroupEffects } from './group.effects';*/
import { GroupComponent } from './group.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { StateLeaderboardComponent } from './stateleaderboard/stateleaderboard.component';
import { LeaderBoardPdfComponent, LeaderBoardPdfViewComponent } from './leaderboardpdf/leaderboardpdf.component';
import { GroupService } from './group.service';
import { HttpGroupService } from './http-group.service';
import { GroupEffects } from './group.effects';
import { LeaderboardShowPdfComponent } from './leaderboardshowpdf/leaderboardshowpdf.component';
import { NewListingsComponent } from './newlistings/newlistings.component';
import { SoldListingsComponent } from './soldlistings/soldlistings.component';
import { ActualVsBudgetComponent } from './actualvsbudget/actualvsbudget.component';
import { LeaderboardNumberOfSalesComponent } from './leaderboardnumberofsales/leaderboardnumberofsales.component';
import { LeaderboardQuoteOfTheDayComponent } from './leaderboardquoteoftheday/leaderboardquoteoftheday.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    GroupRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DevExtremeModule,
    EffectsModule.forFeature([GroupEffects])
  ],
  declarations: [
    GroupComponent,
    LeaderboardComponent,
    StateLeaderboardComponent,
    LeaderBoardPdfComponent,
    LeaderBoardPdfViewComponent,
    LeaderboardShowPdfComponent,
    NewListingsComponent,
    SoldListingsComponent,
    ActualVsBudgetComponent,
    LeaderboardNumberOfSalesComponent,
    LeaderboardQuoteOfTheDayComponent
  ],
  //entryComponents: [],
  providers: [
    Actions,
    {
      provide: GroupService,
      useClass: HttpGroupService
    }
  ]
})
export class GroupModule { }
