import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, concat, flatMap, map } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { GroupService } from './group.service';
import { IDNamePairExtra, ServiceResponse } from '../app.state';
import {
  IFileToUpload
  , IGCILeader
  , IGCIStateStaffLeader
  , IGCIStateStaffDepartmentSubgroup
  , ILeaderboardPdf
  , ILeaderBoardQuoteOfTheDayText
  , INumberOfSalesLeader
  , IStatewiseNumberOfNewListings
  , INewListing
  , IStatewiseNumberOfSoldListings
  , ISoldListing
  , IActualVsBudget
} from './group.state';

@Injectable()
export class HttpGroupService implements GroupService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

  public gciLeaderBoardGraph(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    date: Date | null
  ): Observable<IGCILeader[]> {
    
    return this.httpClient
      .post<ServiceResponse<IGCILeader[]>>('/api/home/GCILeaderGraph', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID,
        date: date
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }

  public numberOfSalesLeaderBoardGraph(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    date: Date | null
  ): Observable<INumberOfSalesLeader[]> {

    return this.httpClient
      .post<ServiceResponse<INumberOfSalesLeader[]>>('/api/home/NumberOfSalesLeaderGraph', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID,
        date: date
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }

  public gciLeaderBoardStateStaff(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IGCIStateStaffDepartmentSubgroup[]> {

    return this.httpClient
      .post<ServiceResponse<IGCIStateStaffDepartmentSubgroup[]>>('/api/home/StateStaffLeaderboard', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }

  public uploadLeaderBoardPdf(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    file: ILeaderboardPdf
  ): Observable<ILeaderboardPdf[]> {
    //https://stackoverflow.com/questions/40214772/file-upload-in-angular
    //https://www.codemag.com/article/1901061/Upload-Small-Files-to-a-Web-API-Using-Angular
    return this.httpClient
      .post<ServiceResponse<ILeaderboardPdf[]>>('/api/home/LeaderBoardPdfUpload', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID,
        file: file
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }
  public deleteLeaderBoardPdf(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    id: string
  ): Observable<ILeaderboardPdf[]> {    
    return this.httpClient
      .post<ServiceResponse<ILeaderboardPdf[]>>('/api/home/LeaderboardPdfDelete', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID,
        id: id
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }
  public getLeaderBoardPdf(
    agencyKey: string,
    marketingGroupDashboardID: string,
    id: string = null
  ): Observable<ILeaderboardPdf> {
    return this.httpClient
      .post<ServiceResponse<ILeaderboardPdf>>('/api/home/GetLeaderboardPdfToShow', {
        agencyKey: agencyKey,
        marketingGroupDashboardID: marketingGroupDashboardID,
        id: id
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else if (!!data && !data.response) {
          return null;
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }
  public getLeaderboardPDFs(
    agencyKey: string,
    marketingGroupDashboardID: string
  ): Observable<ILeaderboardPdf[]> {
    return this.httpClient
      .post<ServiceResponse<ILeaderboardPdf[]>>('/api/home/GetLeaderboardPDFs', {
        agencyKey: agencyKey,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }


  public leaderboardQuoteSave(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    quoteOfTheDay: string,
    quoteWrittenBy: string,
    sortOrder: number,
    id: string
  ): Observable<ILeaderBoardQuoteOfTheDayText[]> {
    return this.httpClient
      .post<ServiceResponse<ILeaderBoardQuoteOfTheDayText[]>>('/api/home/LeaderboardQuoteSave', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID,
        quoteOfTheDay: quoteOfTheDay,
        quoteWrittenBy: quoteWrittenBy,
        sortOrder: sortOrder,
        id: id
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }
  public leaderboardQuoteDelete(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    id: string
  ): Observable<ILeaderBoardQuoteOfTheDayText[]> {
    return this.httpClient
      .post<ServiceResponse<ILeaderBoardQuoteOfTheDayText[]>>('/api/home/LeaderboardQuoteDelete', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID,
        id: id
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }
  public getLeaderboardQuote(
    agencyKey: string,
    marketingGroupDashboardID: string,
    id: string = null
  ): Observable<ILeaderBoardQuoteOfTheDayText> {
    return this.httpClient
      .post<ServiceResponse<ILeaderBoardQuoteOfTheDayText>>('/api/home/GetLeaderboardQuoteToShow', {
        agencyKey: agencyKey,
        marketingGroupDashboardID: marketingGroupDashboardID,
        id: id
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else if (!!data && !data.response) {
          return null;
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }
  public getLeaderboardQuotes(
    agencyKey: string,
    marketingGroupDashboardID: string
  ): Observable<ILeaderBoardQuoteOfTheDayText[]> {
    return this.httpClient
      .post<ServiceResponse<ILeaderBoardQuoteOfTheDayText[]>>('/api/home/GetLeaderboardQuotes', {
        agencyKey: agencyKey,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));
  }

  public statewiseNumberOfNewListings(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IStatewiseNumberOfNewListings[]> {

    return this.httpClient
      .post<ServiceResponse<IStatewiseNumberOfNewListings[]>>('/api/home/StatewiseNumberOfNewListings', {
        agencyKey: agencyKey,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }
  public newListingDetails(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<INewListing[]> {

    return this.httpClient
      .post<ServiceResponse<INewListing[]>>('/api/home/NewListingDetails', {
        agencyKey: agencyKey,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }

  public statewiseNumberOfSoldListings(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IStatewiseNumberOfSoldListings[]> {

    return this.httpClient
      .post<ServiceResponse<IStatewiseNumberOfSoldListings[]>>('/api/home/StatewiseNumberOfSoldListings', {
        agencyKey: agencyKey,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }
  public soldListingDetails(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<ISoldListing[]> {

    return this.httpClient
      .post<ServiceResponse<ISoldListing[]>>('/api/home/SoldListingDetails', {
        agencyKey: agencyKey,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }

  public actualVsBudgetThisMonth(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IActualVsBudget[]> {

    return this.httpClient
      .post<ServiceResponse<IActualVsBudget[]>>('/api/home/ActualVsBudgetThisMonth', {
        agencyKey: agencyKey,
        agencyID: agencyID,
        sessionID: sessionID,
        marketingGroupDashboardID: marketingGroupDashboardID
      })
      .pipe(map((data) => {
        if (!!data && !data.isError && !!data.response) {
          return data.response;
        }
        else if (!!data && data.isError) {
          throw new Error(data.message);
        }
        else {
          throw new Error(`${data}`);
        }
      }));

  }

}
