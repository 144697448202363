import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'commaSeparatedNumber' })
export class CommaSeparatedNumberPipe implements PipeTransform {
  transform(value: number, args: string[]): any {
    if (!!value)
      return Number(value.toFixed(2)).toLocaleString();
    else
      return value;
  }
}
