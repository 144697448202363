import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DatePipe } from '@angular/common';
import { MATERIAL_SANITY_CHECKS } from "@angular/material/core";

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
//import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { MaterialModule } from './material.module';
import { DevExtremeModule } from 'devextreme-angular';
import { AppRoutesModule } from './app.routes';
import { AppReducer, metaReducers } from './app.reducer';
import { AppSettingService } from './util/appsettings/appsettings.service';
import { EncryptionService } from './util/encryption/encryption.Service';
import { AuthModule } from './auth/auth.module';
import { ComponentsModule } from './components/components.module';
import { LoginGuard } from './auth/auth.guard';
import { HomeModule } from './home/home.module';
import { ReportsModule } from './reports/reports.module';
import { GroupModule } from './group/group.module';

declare global {
  interface Array<T> {
    sum(prop): number;
    shuffle(): T[];
  }
  interface Date {
    toUtcYYYYMMDD: () => string;
    toYYYYMMDD: () => string;
  }
  interface Number {
    padLeft: (totalLength: number, repeatChar: string) => string;
  }
}
if (!Array.prototype.sum) {
  Array.prototype.sum = function (prop) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop]
    }
    return total
  };
}
if (!Array.prototype.shuffle) {
  Array.prototype.shuffle = function () {
    let prop = this;
    var currentIndex = prop.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = prop[currentIndex];
      prop[currentIndex] = prop[randomIndex];
      prop[randomIndex] = temporaryValue;
    }
    return prop;
  }
}
if (!Date.prototype.toUtcYYYYMMDD) {
  Date.prototype.toUtcYYYYMMDD = function () {
    let dateString: string = "";
    dateString = this.getUTCFullYear()
      + "-"
      + (this.getUTCMonth() + 1).toString().padStart(2, "0")
      + "-"
      + this.getUTCDate().toString().padStart(2, "0");
    return dateString;
  }
}
if (!Date.prototype.toYYYYMMDD) {
  Date.prototype.toYYYYMMDD = function () {
    let dateString: string = "";
    dateString = this.getFullYear()
      + "-"
      + (this.getMonth() + 1).toString().padStart(2, "0")
      + "-"
      + this.getDate().toString().padStart(2, "0");
    return dateString;
  }
}
if (!Number.prototype.padLeft) {
  Number.prototype.padLeft = function (totalLength, repeatChar) {
    var len = (totalLength - String(this).length) + 1;
    return len > 0 ? new Array(len).join(repeatChar || '0') + this : this;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    //HomeComponent,
    CounterComponent,
    FetchDataComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MaterialModule,
    DevExtremeModule,
    ComponentsModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(AppReducer, { metaReducers }),
    AppRoutesModule,
    AuthModule,
    HomeModule,
    ReportsModule,
    GroupModule
  ],
  providers: [
    EncryptionService,
    AppSettingService,
    LoginGuard,
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appsetting: AppSettingService) => () => appsetting.load(),
      deps: [AppSettingService],
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
