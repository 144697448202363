import { Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable, Subscription } from 'rxjs';
import { first, map } from "rxjs/operators";
import { DomSanitizer } from '@angular/platform-browser';

import { ConfirmDialogComponent } from '../../components/confirmdialog/confirmdialog.component';
import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { IFileToUpload, ILeaderboardPdf, ILeaderBoardQuoteOfTheDayText } from '../group.state';
import { LeaderBoardQuoteAllAction, LeaderBoardQuoteAllFailAction, LeaderBoardQuoteSaveAction, LeaderBoardQuoteDeleteAction } from '../group.action';
import { leaderboardquoteall, leaderboardquoteallProcessing, leaderboardquotesaveProcessing, leaderboardquotedeleteProcessing } from '../group.selectors';

@Component({
  selector: 'app-leaderboardquoteoftheday',
  templateUrl: './leaderboardquoteoftheday.component.html',
  styleUrls: ['./leaderboardquoteoftheday.component.scss']
})
export class LeaderboardQuoteOfTheDayComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private store: Store<IAppState>,
    private encryption: EncryptionService
  ) {
    super(activatedRoute, snackBar, encryption);
  }

  //#region Snackbar Config
  private snackbarConfig = {
    duration: 5000,
    verticalPosition: "bottom",
    panelClass: "notifyPanel"
  } as MatSnackBarConfig;
  //#region

  private addEditPopupVisible: boolean = false;
  private viewQuoteVisible: boolean = false;
  private selectedQuote: ILeaderBoardQuoteOfTheDayText = {
  } as ILeaderBoardQuoteOfTheDayText;

  private leaderboardquoteall$ = this.store.pipe(select(leaderboardquoteall));
  private leaderboardquoteallProcessing$ = this.store.pipe(select(leaderboardquoteallProcessing));
  private leaderboardquotesaveProcessing$ = this.store.pipe(select(leaderboardquotesaveProcessing));
  private leaderboardquotedeleteProcessing$ = this.store.pipe(select(leaderboardquotedeleteProcessing));

  private leaderboardquoteall$_Subscription: Subscription;

  ngOnInit(): void {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new LeaderBoardQuoteAllAction(
          this.agencyKey,
          this.marketingGroupDashboardID
        )
      );
    }
  }
  ngOnDestroy(): void {
    if (!!this.leaderboardquoteall$_Subscription) this.leaderboardquoteall$_Subscription.unsubscribe();
  }

  //#region Events
  onAddEditQuoteClick(quote: ILeaderBoardQuoteOfTheDayText) {
    let auth = JSON.parse(localStorage.getItem("auth"));
    this.selectedQuote = {
    } as ILeaderBoardQuoteOfTheDayText;
    if (!!auth) {
      if (!quote) {
        //Add Quote
        this.addEditPopupVisible = true;
        this.leaderboardquoteall$_Subscription =
          this.leaderboardquoteall$.subscribe(lq => {
            if (!!lq && !!lq.length) {
              //let value = Math.max.apply(Math, lq.map(function (o) {
              //  return o.sortOrder;
              //}));
              let value = Math.max(...lq.map(o => o.sortOrder));
              this.selectedQuote.sortOrder = value + 1;
            }

          });
      }
      else {
        //Edit Quote
        this.selectedQuote = Object.assign({} as ILeaderBoardQuoteOfTheDayText, quote);
        this.addEditPopupVisible = true;
      }
    }
  }
  onViewQuoteClick(quote: ILeaderBoardQuoteOfTheDayText) {
    this.viewQuoteVisible = false;
    this.selectedQuote = {
    } as ILeaderBoardQuoteOfTheDayText;
    if (!!quote) {
      this.viewQuoteVisible = true;
      this.selectedQuote = Object.assign({} as ILeaderBoardQuoteOfTheDayText, quote);

    }
  }
  onQuoteSaveClick() {
    if (!(!!this.marketingGroupDashboardID && this.marketingGroupDashboardID.indexOf('00000000-') < 0)) {
      this.snackBar.open("Invalid marketing group dashboard id", "Close", this.snackbarConfig);
      return;
    }
    if (!(!!this.selectedQuote.quoteOfTheDay && !!this.selectedQuote.quoteOfTheDay.length)) {
      this.snackBar.open("Quote of the text is not entered", "Close", this.snackbarConfig);
      return;
    }
    if (!(!!this.selectedQuote.quoteWrittenBy && !!this.selectedQuote.quoteWrittenBy.length)) {
      this.snackBar.open("Quote written by is not entered", "Close", this.snackbarConfig);
      return;
    }
    if (this.selectedQuote.sortOrder < 0) {
      this.snackBar.open("Invalid quote sort order", "Close", this.snackbarConfig);
      return;
    }
    let id: string = null;
    if (!!this.selectedQuote && !!this.selectedQuote.id && !!this.selectedQuote.id.length && this.selectedQuote.id.indexOf('00000000-') < 0) {
      id = this.selectedQuote.id;
    }

    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new LeaderBoardQuoteSaveAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          this.marketingGroupDashboardID,
          this.selectedQuote.quoteOfTheDay,
          this.selectedQuote.quoteWrittenBy,
          this.selectedQuote.sortOrder,
          id
        )
      );

      setTimeout(() => {
        this.addEditPopupVisible = false;
      }, 5000);

    }
  }
  onDeleteQuoteClick(quote: ILeaderBoardQuoteOfTheDayText) {
    if (!(!!quote && !!quote.id && !!quote.id.length && quote.id.indexOf('00000000-') < 0)) {
      this.snackBar.open("Quote id not found to delete", "Close", this.snackbarConfig);
      return;
    }

    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {

      var confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '350px',
        data: {
          title: 'Confirm Quote Delete',
          message: 'Are you sure to delete this quote?'
        }
      });
      confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {

          this.store.dispatch(
            new LeaderBoardQuoteDeleteAction(
              this.agencyKey,
              this.agencyID,
              this.sessionID,
              this.marketingGroupDashboardID,
              quote.id
            )
          );

        }
      });

    }
  }
  //#endregion
  //#region Properties
  get quoteOfTheDayText(): string {
    if (!!this.selectedQuote && !!this.selectedQuote.quoteOfTheDay && !!this.selectedQuote.quoteOfTheDay.length) {      
      return this.selectedQuote.quoteOfTheDay.replace(/(\\r\\n)|([\r\n])/gmi, '<br/>');
    }
    return null;
  }
  //#endregion
}
