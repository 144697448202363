import { Injectable, Component, Inject } from "@angular/core";
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  ActivatedRoute,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { Observable, of, BehaviorSubject } from "rxjs";
import { select, Store } from "@ngrx/store";
import { first, map } from "rxjs/operators";

import { IAppState } from "../app.state";
import { isAuthenticated, token } from "./auth.selectors";
//import { AppSettingService } from './../util/appSettings/appsettings.service';
import { EncryptionService } from './../util/encryption/encryption.Service';
import { AppSettingService } from "../util/appsettings/appsettings.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private snackBar: MatSnackBar,
    private appSettingService: AppSettingService,
    private encryption: EncryptionService
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    let auth = this.encryption.decryptLocationStorage();
    
    if (
      (!!route.params && !!route.params.agencyKey) ||
      (!!auth && !!auth.agencyKey)
    ) {      
      let agencyKey: string = (!!route.params && !!route.params.agencyKey)
        ?
        route.params.agencyKey
        :
        ((!!auth && !!auth.agencyKey) ? auth.agencyKey : "");
      let group: string = null;
      if (!!route.url.filter(url => url.path.toLowerCase() == 'group').length) {
        group = "group";
      }
      else if (!(!!auth && auth.isAuthenticated)) {
      }
      else if (!!auth && !!auth.group) {
        group = auth.group;
      }
      
      if (!!agencyKey && !!this.appSettingService.AppSettings) {
        if (!this.appSettingService.AppSettings.Databases.filter(agk => agk.AgencyKey == agencyKey).length) {
          let snackBarRef = this.snackBar.openFromComponent(
            InvalidAgencyKeyComponent,
            { data: route.params.agencyKey + " is not a valid Agency Key!!" }
          );
          throw new Error(`${route.params.agencyKey} is not a valid Agency Key!!`);
        }
      }
      else {
        let snackBarRef = this.snackBar.openFromComponent(
          InvalidAgencyKeyComponent,
          { data: route.params.agencyKey + " is not a valid Agency Key!!" }
        );
        throw new Error(`${route.params.agencyKey} is not a valid Agency Key!!`);
      }


      if (!!auth && !!auth.group && auth.isAuthenticated) {
        if (route.url.filter(url => url.path.toLowerCase() == 'group').length <= 0) {
          return of(this.router.parseUrl(`/login/${group}/${agencyKey}`));
        }
      }
      else {
        if (!!route.url.filter(url => url.path.toLowerCase() == 'group').length) {
          if (route.url.filter(url => url.path.toLowerCase() == 'login').length <= 0) {
            return of(this.router.parseUrl(`/login/${agencyKey}`));
          }
        }
      }

      return this.store.pipe(
        select(token),
        first(),
        map(token => {
          let auth = this.encryption.decryptLocationStorage(token);
          if (!!auth && auth.isAuthenticated) {
            return true;
          } else {
            if (!!group) {
              return this.router.parseUrl(`/login/${group}/${agencyKey}`);
            }
            else {
              return this.router.parseUrl("/login");
            }            
          }
        })
      );

    }
    else {
      let snackBarRef = this.snackBar.openFromComponent(
        InvalidAgencyKeyComponent,
        { data: route.params.agencyKey + " is not a valid Agency Key!!" }
      );
      throw new Error(`${route.params.agencyKey} is not a valid Agency Key!!`);
    }

  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
}



@Injectable({
  providedIn: "root"
})
export class LoginGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private snackBar: MatSnackBar,
    private appSettingService: AppSettingService,
    private encryption: EncryptionService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    //let auth = JSON.parse(localStorage.getItem("auth"));
    let auth = this.encryption.decryptLocationStorage();
    if (
      (!!route.params && !!route.params.agencyKey) ||
      (!!auth && !!auth.agencyKey)
    ) {
      let agencyKey: string = (!!route.params && !!route.params.agencyKey)
        ?
        ((!!auth && auth.isAuthenticated) ? auth.agencyKey : route.params.agencyKey)
        :
        ((!!auth && !!auth.agencyKey) ? auth.agencyKey : "");
      let group: string = null;
      if (!!route.url.filter(url => url.path.toLowerCase() == 'group').length) {
        group = "group";
      }
      else if (!(!!auth && auth.isAuthenticated)) {
        if (!!auth && !!auth.group) {

          if (!!route.url.filter(url => url.path.toLowerCase() == 'group').length) {
            group = auth.group;
          }

          //group = auth.group;
        }
      }
      else if (!!auth && !!auth.group) {
        group = auth.group;
      }
      if (!!agencyKey && !!this.appSettingService.AppSettings) {
        if (!this.appSettingService.AppSettings.Databases.filter(agk => agk.AgencyKey == agencyKey).length) {
          let snackBarRef = this.snackBar.openFromComponent(
            InvalidAgencyKeyComponent,
            { data: route.params.agencyKey + " is not a valid Agency Key!!" }
          );
          throw new Error(`${route.params.agencyKey} is not a valid Agency Key!!`);
        }
      }
      else {
        let snackBarRef = this.snackBar.openFromComponent(
          InvalidAgencyKeyComponent,
          { data: route.params.agencyKey + " is not a valid Agency Key!!" }
        );
        throw new Error(`${route.params.agencyKey} is not a valid Agency Key!!`);
      }
      if (!!auth && !!auth.group && auth.isAuthenticated) {
        if (route.url.filter(url => url.path.toLowerCase() == 'group').length <= 0) {
          return of(this.router.parseUrl(`/login/${group}/${agencyKey}`));
        }
      }
      else {
        if (!!route.url.filter(url => url.path.toLowerCase() == 'group').length) {
          if (route.url.filter(url => url.path.toLowerCase() == 'login').length <= 0) {
            if (!!group) {
              return of(this.router.parseUrl(`/login/${group}/${agencyKey}`));
            }
            else {
              return of(this.router.parseUrl(`/login/${agencyKey}`));
            }
          }
        }
        else {
          if (!!route.url.filter(url => url.path.toLowerCase() == 'login').length) {
            if (!!group) {
              return of(this.router.parseUrl(`/login/${group}/${agencyKey}`));
            }
          }
        }
      }

      return this.store.pipe(
        select(token),
        first(),
        map(token => {
          auth = this.encryption.decryptLocationStorage(token);
          if (!(!!auth && auth.isAuthenticated)) {
            return true;
          } else {
            if (!!group) {
              return this.router.parseUrl(`/${group}/dashboard/${agencyKey}`);
            }
            else {
              return this.router.parseUrl("/home");
            }
          }
        })
      );

    }
    else {
      let snackBarRef = this.snackBar.openFromComponent(
        InvalidAgencyKeyComponent,
        { data: route.params.agencyKey + " is not a valid Agency Key!!" }
      );
      throw new Error(`${route.params.agencyKey} is not a valid Agency Key!!`);
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
}

@Component({
  selector: "snack-bar-component-invalid-agency",
  template: `
    <span class="invalid-agency"
      >{{ data }}
      😀</span
    >
  `,
  styles: [
    `
      .invalid-agency {
        color: hotpink;
      }
    `
  ]
})
export class InvalidAgencyKeyComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }  
}
