import { Component, Input, Output, OnInit, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable, of, Subscription } from 'rxjs';
import { first, map } from "rxjs/operators";

import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { LeaderBoardGCIGraphAction, LeaderBoardNumberOfSalesGraphAction } from '../group.action';
import { leaderboardgcigraph, leaderboardgcigraphProcessing/*, leaderboardnumberofsalesgraph, leaderboardnumberofsalesgraphProcessing*/ } from '../group.selectors';
import { IGCILeader, INumberOfSalesLeader } from '../group.state';

@Component({
  selector: 'multiarray-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class LeaderboardComponent extends ComponentBase implements OnInit, AfterViewInit, OnDestroy {

  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
    ) {
    super(activatedRoute, snackBar, encryption);
  }
  @Input() visible: boolean = false;
  @Output() gciscrollend = new EventEmitter<boolean>();
  //@Output() numberofsalesscrollend = new EventEmitter<boolean>();
  private leaderboardgcigraph$ = this.store.pipe(select(leaderboardgcigraph));
  private leaderboardgcigraphProcessing$ = this.store.pipe(select(leaderboardgcigraphProcessing));
  /*private leaderboardnumberofsalesgraph$ = this.store.pipe(select(leaderboardnumberofsalesgraph));
  private leaderboardnumberofsalesgraphProcessing$ = this.store.pipe(select(leaderboardnumberofsalesgraphProcessing));*/
  private scrollPosGci: number = 0;
  private scrollIntervalGci: any;
  /*private scrollPosNumberOfSales: number = 0;
  private scrollIntervalNumberOfSales: any;*/
  private scrollPlayPauseButtonIconInterval: any;

  private gciDataSource: Data[] = [];
  //private numberofsalesDataSource: Data[] =
  private fyStartDate: Date;
  private fyEndDate: Date;
  private dataRefreshed: boolean = false;
  //private gciBarDataReloaded: boolean = false;
  private dataRefreshInterval: any;

  private leaderboardgcigraph$_Subscription: Subscription;
  //private leaderboardnumberofsalesgraph$_Subscription: Subscription;

  private chartType: string = "bar";
  private chartColorsGci = [
    {
      backgroundColor: ['#EB5544', '#EB5544', '#EB5544', '#EB5544', '#EB5544'],//['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
      hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
      borderWidth: 2,
    },
  ];
  //private chartColorsNumberOfSales = this.chartColorsGci;
  private gci: IGCILeader[] = [];
  private gciLabels: string[] = [];
  /*private numberofsales: INumberOfSalesLeader[] = [];
  private numberofsalesLabels: string[] = [];*/
  //private data = [{ data: [300, 50, 100, 40, 120], label: 'My First dataset', indexAxis: 'y' }];
  private chartOptions = {/*https://www.chartjs.org/docs/latest/configuration/legend.html*/
    responsive: true,
    maintainAspectRatio: true,
    options: {
      responsive: true,
      legend: {
        display: false
      },
      scales: {
        y: {
          display: true,
          type: 'category',
          axis: 'x'
        }
      }
    },
    plugins: {
      weight: 5,
      animation: {
        animateRotate: true,
        animateScale: true,
      },
      legend: {
        display: true,
        labels: {
          color: "#ffffff"
        },
      },
      scaleLabel: {
        fontColor: "#ffffff"
      }
    }
  };

  ngOnInit(): void {
    //#region Data refresh when slide is visible
    this.dataRefreshInterval =
      setInterval(() => {
        if (this.visible) {
          let auth = JSON.parse(localStorage.getItem("auth"));
          /*this.gciBarDataReloaded = !!JSON.parse(localStorage.getItem("gciBarDataReloaded")) ? JSON.parse(localStorage.getItem("gciBarDataReloaded")) : false;
          if (!!auth && !this.gciBarDataReloaded) {
            localStorage.setItem("gciBarDataReloaded", "true");
            this.gciBarDataReloaded = true;
            window.location.reload();
          }*/
          if (!!auth && !this.dataRefreshed) {
            this.fetchData();
            this.dataRefreshed = true;
          }
        }
        else {

          this.dataRefreshed = false;
          /*localStorage.setItem("gciBarDataReloaded", "false");*/
          clearInterval(this.scrollIntervalGci);
          this.scrollPosGci = 0;

          //#region Destroy timers
          if (!!this.leaderboardgcigraph$_Subscription) this.leaderboardgcigraph$_Subscription.unsubscribe();
          clearInterval(this.scrollIntervalGci);
          clearInterval(this.scrollPlayPauseButtonIconInterval);
          this.scrollIntervalGci = undefined;
          this.scrollPlayPauseButtonIconInterval = undefined;
          //#endregion
        }

      }, 1000);
    //#endregion

    //this.fetchData();

    this.scrollPlayPauseButtonIconInterval =
      setInterval(() => {
        let playPauseButtonGci = document.getElementById("spGciPlayPause");
        if (!!this.scrollIntervalGci) {
          if (!!playPauseButtonGci) {
            playPauseButtonGci.classList.remove("play_btn");
            playPauseButtonGci.classList.remove("pause_btn");
            playPauseButtonGci.classList.add("pause_btn");
          }
        }
        else {
          if (!!playPauseButtonGci) {
            playPauseButtonGci.classList.remove("play_btn");
            playPauseButtonGci.classList.remove("pause_btn");
            playPauseButtonGci.classList.add("play_btn");
          }
        }

        /*let playPauseButtonNumberOfSales = document.getElementById("spNumberOfSalesPlayPause");
        if (!!this.scrollIntervalNumberOfSales) {
          if (!!playPauseButtonNumberOfSales) {
            playPauseButtonNumberOfSales.classList.remove("play_btn");
            playPauseButtonNumberOfSales.classList.remove("pause_btn");
            playPauseButtonNumberOfSales.classList.add("pause_btn");
          }
        }
        else {
          if (!!playPauseButtonNumberOfSales) {
            playPauseButtonNumberOfSales.classList.remove("play_btn");
            playPauseButtonNumberOfSales.classList.remove("pause_btn");
            playPauseButtonNumberOfSales.classList.add("play_btn");
          }
        }*/

      }, 100);

  }
  ngAfterViewInit() {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {

      this.leaderboardgcigraph$_Subscription =
        this.leaderboardgcigraph$.subscribe(lbgci => {
          if (!!lbgci && !!lbgci.length) {
            this.gci = lbgci;
            this.gci.forEach((value, index) => {
              this.fyStartDate = value.fyStartDate;
              this.fyEndDate = value.fyEndDate;
              if (index > 4) {
                this.chartColorsGci[0].backgroundColor.push('#EB5544'/*this.dynamicColorCode(value.staffName)*/);
                this.chartColorsGci[0].hoverBackgroundColor.push(this.dynamicColorCode(value.staffID));
              }
            });
            this.gciLabels = this.getGraphLabelGci();
            this.getGraphDataGciNew();
            setTimeout(() => {
              this.gciStartScroll();
              let playPauseButton = document.getElementById("spGciPlayPause");
              if (!!playPauseButton) {
                playPauseButton.classList.remove("play_btn");
                playPauseButton.classList.remove("pause_btn");
                playPauseButton.classList.add("pause_btn");
              }
            }, 10000);

          }
        });

      /*this.leaderboardnumberofsalesgraph$_Subscription =
        this.leaderboardnumberofsalesgraph$.subscribe(numberofsales => {
          if (!!numberofsales && !!numberofsales.length) {
            this.numberofsales = numberofsales;
            this.numberofsales.forEach((value, index) => {
              if (index > 4) {
                this.chartColorsNumberOfSales[0].backgroundColor.push('#EB5544');
                this.chartColorsNumberOfSales[0].hoverBackgroundColor.push(this.dynamicColorCode(value.staffID));
              }
            });
            this.numberofsalesLabels = this.getGraphLabelNumberOfSales();
            this.getGraphDataNumberOfSalesNew();
            setTimeout(() => {
              this.numberOfSalesStartScroll();
              let playPauseButton = document.getElementById("spNumberOfSalesPlayPause");
              if (!!playPauseButton) {
                playPauseButton.classList.remove("play_btn");
                playPauseButton.classList.remove("pause_btn");
                playPauseButton.classList.add("pause_btn");
              }
            }, 10000);

          }
        });*/

    }
  }
  ngOnDestroy() {
    if (!!this.leaderboardgcigraph$_Subscription) this.leaderboardgcigraph$_Subscription.unsubscribe();
    //if (!!this.leaderboardnumberofsalesgraph$_Subscription) this.leaderboardnumberofsalesgraph$_Subscription.unsubscribe();
    clearInterval(this.scrollIntervalGci);
    //clearInterval(this.scrollIntervalNumberOfSales);
    clearInterval(this.scrollPlayPauseButtonIconInterval);
    this.scrollPosGci = /*this.scrollPosNumberOfSales =*/ 0;
    this.scrollIntervalGci = /*this.scrollIntervalNumberOfSales =*/ undefined;
    this.scrollPlayPauseButtonIconInterval = undefined;
    clearInterval(this.dataRefreshInterval);
  }
  onGciPlayPauseClick(event) {
    let pause: boolean = event.target.classList.contains("pause_btn");
    if (pause) {
      event.target.classList.remove("pause_btn");
      event.target.classList.add("play_btn");
      clearInterval(this.scrollIntervalGci);
      this.scrollIntervalGci = undefined;
    }
    else {
      event.target.classList.remove("play_btn");
      event.target.classList.add("pause_btn");
      this.gciStartScroll();
    }
  }
  /*onNumberOfSalesPlayPauseClick(event) {
    let pause: boolean = event.target.classList.contains("pause_btn");
    if (pause) {
      event.target.classList.remove("pause_btn");
      event.target.classList.add("play_btn");
      clearInterval(this.scrollIntervalNumberOfSales);
      this.scrollIntervalNumberOfSales = undefined;
    }
    else {
      event.target.classList.remove("play_btn");
      event.target.classList.add("pause_btn");
      this.numberOfSalesStartScroll();
    }
  }*/
  fetchData() {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new LeaderBoardGCIGraphAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          this.marketingGroupDashboardID,
          null
        )
      );
      /*this.store.dispatch(
        new LeaderBoardNumberOfSalesGraphAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          this.marketingGroupDashboardID,
          null
        )
      );*/

      this.leaderboardgcigraph$_Subscription =
        this.leaderboardgcigraph$.subscribe(lbgci => {
          if (!!lbgci && !!lbgci.length) {
            this.gci = lbgci;
            this.gci.forEach((value, index) => {
              this.fyStartDate = value.fyStartDate;
              this.fyEndDate = value.fyEndDate;

              if (index > 4 && this.chartColorsGci[0].backgroundColor.length <= 0 && this.chartColorsGci[0].hoverBackgroundColor.length <= 0) {
                this.chartColorsGci[0].backgroundColor.push('#EB5544'/*this.dynamicColorCode(value.staffName)*/);
                this.chartColorsGci[0].hoverBackgroundColor.push(this.dynamicColorCode(value.staffID));
              }
            });
            this.gciLabels = this.getGraphLabelGci();
            this.getGraphDataGciNew();
            clearInterval(this.scrollIntervalGci);
            this.scrollPosGci = 0;
            this.gciStartScroll();
          }
        });

    }
  }
  gciStartScroll() {
    this.scrollIntervalGci =
      setInterval(() => {
        if (!this.visible) {
          this.scrollPosGci == 0;
          return;
        };
        if (!!document.getElementById("dvGraphGci")) {
          let scrollHeight: number = document.getElementById("dvGraphGci").scrollHeight;
          if (this.scrollPosGci < scrollHeight) {
            if (this.scrollPosGci == 0) {
              setTimeout(() => {
                this.scrollPosGci += 1;
                this.gciscrollend.emit(false);
              }, 5000);
            }
            else {
              if (this.scrollPosGci == 2600) {
                this.scrollPosGci = scrollHeight;
                this.gciscrollend.emit(false);
              }
              else {
                /*if (window.innerWidth <= 2560)
                  this.scrollPosGci += 1;
                else
                  this.scrollPosGci += 40;*/
                this.scrollPosGci += this.hbarGciScrollGapInPixel;
                this.gciscrollend.emit(false);
              }
            }
          }
          else if (this.scrollPosGci >= scrollHeight) {
            this.gciscrollend.emit(true);
            setTimeout(() => {
              this.gciscrollend.emit(true);
              this.scrollPosGci = 0;
              if (!!document.getElementById("dvGraphGci")) {
                document.getElementById("dvGraphGci").scrollTop = this.scrollPosGci;
              }
            }, 10000);

            /*setTimeout(() => {

              this.gciscrollend.emit(true);
              setTimeout(() => {
                if (!!document.getElementById("dvGraphGci")) {
                  this.scrollPosGci = 0;
                  document.getElementById("dvGraphGci").scrollTop = this.scrollPosGci;
                }
              }, 10000);

            }, 5000);*/
          }
          /*else {
            this.gciscrollend.emit(true);
            setTimeout(() => {
              this.scrollPosGci = 0;
              document.getElementById("dvGraphGci").scrollTop = this.scrollPosGci;
            }, 10000);
          }*/
          document.getElementById("dvGraphGci").scrollTop = this.scrollPosGci;
        }
      }, 5/*(window.innerHeight <= 670 ? 5 : 1)*/);
  }
  /*numberOfSalesStartScroll() {
    this.scrollIntervalNumberOfSales =
      setInterval(() => {
        if (!this.visible) return;
        if (!!document.getElementById("dvGraphNumberOfSales")) {
          let scrollHeight: number = document.getElementById("dvGraphNumberOfSales").scrollHeight;
          if (this.scrollPosNumberOfSales < scrollHeight) {
            if (this.scrollPosNumberOfSales == 0) {
              setTimeout(() => {
                this.scrollPosNumberOfSales += 1;
                this.numberofsalesscrollend.emit(false);
              }, 5000);
            }
            else {
              if (this.scrollPosNumberOfSales == 2600) {
                this.scrollPosNumberOfSales = scrollHeight;
                this.numberofsalesscrollend.emit(false);
              }
              else {
                this.scrollPosNumberOfSales += 1;
                this.numberofsalesscrollend.emit(false);
              }
            }
          }
          else if (this.scrollPosNumberOfSales >= scrollHeight) {
            this.numberofsalesscrollend.emit(true);
            setTimeout(() => {
              this.numberofsalesscrollend.emit(true);
              this.scrollPosNumberOfSales = 0;
              if (!!document.getElementById("dvGraphNumberOfSales")) {
                document.getElementById("dvGraphNumberOfSales").scrollTop = this.scrollPosNumberOfSales;
              }
            }, 10000);
            
            
          document.getElementById("dvGraphNumberOfSales").scrollTop = this.scrollPosNumberOfSales;
        }
      }, 25);
  }*/
  getGraphLabelGci(): string[] {
    let tempValues: IGCILeader[] = JSON.parse(JSON.stringify(this.gci));
    let labels: Array<string> = tempValues.sort((first, next) => first.position > next.position ? 1 : -1).map(value => value.staffName.replace("(", "").replace(")", ""));
    return labels;
  }
  getGraphDataGci(): { data: number[], label: string }[] {
    let tempValues: IGCILeader[] = JSON.parse(JSON.stringify(this.gci));
    let result = [{ data: tempValues.sort((first, next) => first.position > next.position ? 1 : -1).map(value => value.grossAmount), label: "Highest GCI", indexAxis: 'y' }];
    return result;
  }
  /*getGraphLabelNumberOfSales(): string[] {
    let tempValues: INumberOfSalesLeader[] = JSON.parse(JSON.stringify(this.numberofsales));
    let labels: Array<string> = tempValues.sort((first, next) => first.position > next.position ? 1 : -1).map(value => value.staffName.replace("(", "").replace(")", ""));
    return labels;
  }
  getGraphDataNumberOfSales(): { data: number[], label: string }[] {
    let tempValues: INumberOfSalesLeader[] = JSON.parse(JSON.stringify(this.numberofsales));
    let result = [{ data: tempValues.sort((first, next) => first.position > next.position ? 1 : -1).map(value => value.grossPercent), label: "Highest Number Of Sales", indexAxis: 'y' }];
    return result;
  }*/
  getGraphDataGciNew(): Data[] {
    let tempValues: IGCILeader[] = JSON.parse(JSON.stringify(this.gci)).sort((first, next) => first.position > next.position ? -1 : 1);
    //tempValues = tempValues.slice(0, 20);
    this.gciDataSource = tempValues.map((value) => ({
      name: value.staffName,
      value: value.grossAmount
    }));
    return this.gciDataSource;
  }
  get gciScaleDataSource(): Data[] {
    let ds: Data[] = [];
    if (!!this.gciDataSource && !!this.gciDataSource.length) {
      let dsScaleMax = this.gciDataSource.reduce((prev, current) => (prev.value > current.value) ? prev : current);
      let dsScaleMin = this.gciDataSource.reduce((prev, current) => (prev.value < current.value) ? prev : current);

      let largetext = this.gciDataSource.reduce((prev, current) => (prev.name.length > current.name.length) ? prev : current).name;

      dsScaleMax = { name: largetext, value: dsScaleMax.value };
      ds.push(Object.assign({}, dsScaleMin), Object.assign({}, dsScaleMax));
    }
    return ds;
  }
  /*getGraphDataNumberOfSalesNew(): Data[] {
    let tempValues: INumberOfSalesLeader[] = JSON.parse(JSON.stringify(this.numberofsales)).sort((first, next) => first.position > next.position ? -1 : 1);
    this.numberofsalesDataSource = tempValues.map((value) => ({
      name: value.staffName,
      value: value.grossPercent
    }));
    return this.numberofsalesDataSource;
  }*/
  get gciGrpahHeight(): number {
    return ((3000 / 50) * this.gciDataSource.length);
  }
  get gciScaleGrpahHeight(): number {
    return ((3000 / 50) * this.gciScaleDataSource.length);
  }
  /*get numberOfSalesGrpahHeight(): number {
    return ((3000 / 50) * this.numberofsalesDataSource.length);
  }*/
  customizeLabel(e) {
    return `${"$"}${e.valueText}`;
  }
  dynamicColorCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  //#region Events
  get hbarGciScrollGapInPixel(): number {
    let gap: number = parseInt(localStorage.getItem("hbarGciScrollGapInPixel"));
    return !!gap ? gap : 1;
  }
  onScrollGciSpeedChange(action: "increase" | "decrease") {
    debugger;
    if (action.toLowerCase() == "increase") {
      localStorage.setItem("hbarGciScrollGapInPixel", (this.hbarGciScrollGapInPixel + 1).toString());
    }
    else if (action.toLowerCase() == "decrease") {
      if (this.hbarGciScrollGapInPixel > 1) {
        localStorage.setItem("hbarGciScrollGapInPixel", (this.hbarGciScrollGapInPixel - 1).toString());
      }
      else {

      }
    }
  }
  onGciPinUnpinClick() {
    let target = document.getElementById("aGciSpeedController");
    if (!!target && !!target.classList) {
      if (target.classList.contains("unpin")) {
        target.classList.remove("unpin");
        document.getElementById("ulGciSpeedController").hidden = false;
      }
      else {
        target.classList.add("unpin");
        document.getElementById("ulGciSpeedController").hidden = true;
      }
    }
  }
  //#endregion

}
export class Data {
  name: string;
  value: number;
}
