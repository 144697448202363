import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { from, Observable, Observer, of } from "rxjs";
import { Action, select, Store } from "@ngrx/store";
import {
  catchError,
  filter,
  first,
  flatMap,
  map,
  mergeMap
} from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ActionTypes,
  LoginAction,
  LoginFailAction,
  LoginSuccessAction,
  LogoutAction,
  LogoutSuccessAction,
  LogoutFailAction
} from "./auth.action";
import { AuthService } from "./auth.service";
import { IAppState } from "../app.state";
import { EncryptionService, enLoginMode } from "./../util/encryption/encryption.Service";

@Injectable()
export class AuthEffects {
  constructor(
    private store: Store<IAppState>,
    private authService: AuthService,
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private encryption: EncryptionService
  ) { }

  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.Login),
    mergeMap((action: LoginAction) =>
      this.authService
        .login(action.agencyKey, action.username, action.password, action.group)
        .pipe(
          map(
            data =>
              new LoginSuccessAction(
                {
                  sessionID: data.sessionID,
                  group: action.group,
                  agencyID: data.agencyID,
                  agencyKey: data.agencyKey,
                  agencyImageUrl: data.agencyImageUrl,
                  userName: data.userName,
                  logon: data.logon,
                  marketingGroupDashboardID: data.marketingGroupDashboardID,
                  isAuthenticated: data.isAuthenticated
                },
                this.encryption.encryptLocalStorage({
                  sessionID: data.sessionID,
                  group: action.group,
                  agencyID: data.agencyID,
                  agencyKey: data.agencyKey,
                  agencyImageUrl: data.agencyImageUrl,
                  userName: data.userName,
                  logon: data.logon,
                  marketingGroupDashboardID: data.marketingGroupDashboardID,
                  isAuthenticated: data.isAuthenticated
                }),
                action.dontNavigate,
                action.redirectUrl
              )
          ),
          catchError(error => of(new LoginFailAction(error.message)))
        )
    )
  );

  @Effect({ dispatch: false })
  loginSuccess$: Observable<void> = this.actions$.pipe(
    ofType(ActionTypes.LoginSuccess),
    mergeMap((action: LoginSuccessAction) => {
      if (action.dontNavigate) {
        console.log("action.dontNavigate: if ", action.dontNavigate);
        return of(undefined);
      } else {
        console.log("action.dontNavigate: else ", action.dontNavigate);
        window.location.reload();
        /*return this.router.navigate([
          action.redirectUrl ? action.redirectUrl : "/"
        ]);*/
      }
    }),
    map(() => undefined)
  );

  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.Logout),
    mergeMap(() => {
      let auth = this.encryption.decryptLocationStorage();

      return this.authService.logout(auth.agencyKey, auth.sessionID).pipe(
        map(data => {
          if (!!data) {
            let storageData = {
              sessionID: "",
              group: auth.group ?? "",
              agencyID: "",
              agencyKey: auth.agencyKey,
              agencyImageUrl: "",
              userName: "",
              logon: enLoginMode.Undefined,
              marketingGroupDashboardID: "",
              isAuthenticated: false
            }
            let token: string;
            if (!!auth) {
              token = this.encryption.encryptLocalStorage(storageData);
            }
            if ((auth?.group ?? "") != "")
              this.router.navigate([`/login/group/${auth.agencyKey}`]);
            else
              this.router.navigate(["/login"]);
            return new LogoutSuccessAction(storageData, token)
          }
          else {
            throw new Error("Logout Failed!");
          }
        }),
        catchError(error => of(new LogoutFailAction(error.message)))
      );
    })
  );
}
