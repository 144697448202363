import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { GroupComponent } from './group.component';
/*import { LeaderBoardPdfComponent } from './leaderboardpdf/leaderboardpdf.component';*/
import { AuthGuard } from '../auth/auth.guard';
import { LeaderboardQuoteOfTheDayComponent } from './leaderboardquoteoftheday/leaderboardquoteoftheday.component';


const homeRoutes: Routes = [
  { path: 'group/dashboard', component: GroupComponent, canActivate: [AuthGuard] },
  { path: 'group/dashboard/:agencyKey', component: GroupComponent, canActivate: [AuthGuard] },
  //{ path: 'group/leaderboardpdf', component: LeaderBoardPdfComponent, canActivate: [AuthGuard] },  
  //{ path: 'group/leaderboardpdf/:agencyKey', component: LeaderBoardPdfComponent, canActivate: [AuthGuard] },
  { path: 'group/leaderboardquoteoftheday', component: LeaderboardQuoteOfTheDayComponent, canActivate: [AuthGuard] },
  { path: 'group/leaderboardquoteoftheday/:agencyKey', component: LeaderboardQuoteOfTheDayComponent, canActivate: [AuthGuard] }
]

@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule]
})
export class GroupRoutingModule { }
