import { Component, Input, Output, OnInit, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable, of, Subscription } from 'rxjs';
import { first, map } from "rxjs/operators";

import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { StatewiseNumberOfSoldListingsAction, SoldListingDetailsAction } from '../group.action';
import { statewisenumberofsoldlistings, statewisenumberofsoldlistingsProcessing, soldlistingdetails, soldlistingdetailsProcessing } from '../group.selectors';
import { IStatewiseNumberOfSoldListings, ISoldListing } from '../group.state';

@Component({
  selector: 'multiarray-soldlistings',
  templateUrl: './soldlistings.component.html',
  styleUrls: ['./soldlistings.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class SoldListingsComponent extends ComponentBase implements OnInit, AfterViewInit, OnDestroy {

  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
    ) {
    super(activatedRoute, snackBar, encryption);
  }
  @Input() visible: boolean = false;
  @Output() scrollend = new EventEmitter<boolean>();
  private statewisenumberofsoldlistings$ = this.store.pipe(select(statewisenumberofsoldlistings));
  private statewisenumberofsoldlistingsProcessing$ = this.store.pipe(select(statewisenumberofsoldlistingsProcessing));
  private soldlistingdetails$ = this.store.pipe(select(soldlistingdetails));
  private soldlistingdetailsProcessing$ = this.store.pipe(select(soldlistingdetailsProcessing));

  private statewisenumberofsoldlistings: IStatewiseNumberOfSoldListings[] = [];
  private soldlistingdetails: ISoldListing[] = [];
  private scrollSoldListingPos: number = 0;
  private scrollSLInterval: any;
  private scrollInitialWaitTimout: any;
  private scrollEndWaitTimout: any;
  private statewiseMonthlyNOSLShowInterval: any;
  private statewiseMonthlyNOSLShowIndex: number = 0;
  private dataSLRefreshed: boolean = false;
  private dataScrollSLEnabled: boolean = false;

  private statewisenumberofsoldlistings$_Subscription: Subscription;
  private soldlistingdetails$_Subscription: Subscription;

  ngOnInit(): void {

    //#region Data refresh when slide is visible
    setInterval(() => {
      if (this.visible) {        
        let auth = JSON.parse(localStorage.getItem("auth"));
        if (!!auth && !this.dataSLRefreshed) {
          this.fetchData();
          this.dataSLRefreshed = true;
        }
        else if (this.dataSLRefreshed && !this.dataScrollSLEnabled) {
          //if (!!this.soldlistingdetails && !!this.soldlistingdetails.length) {
            this.soldListingScroll();
            this.dataScrollSLEnabled = true;
          //}
        }
      }
      else {
        this.dataSLRefreshed = false;
        this.dataScrollSLEnabled = false;
        this.statewisenumberofsoldlistings = [];
        this.soldlistingdetails = [];
        if (!!this.statewisenumberofsoldlistings$_Subscription) this.statewisenumberofsoldlistings$_Subscription.unsubscribe();
        if (!!this.soldlistingdetails$_Subscription) this.soldlistingdetails$_Subscription.unsubscribe();
        clearInterval(this.scrollSLInterval);
      }

    }, 1000);
    //#endregion
    //this.fetchData();
  }
  ngAfterViewInit(): void {
    //this.soldListingScroll();
    this.statewiseMonthlyNOSLShow();
  }
  ngOnDestroy(): void {
    if (!!this.statewisenumberofsoldlistings$_Subscription) this.statewisenumberofsoldlistings$_Subscription.unsubscribe();
    if (!!this.soldlistingdetails$_Subscription) this.soldlistingdetails$_Subscription.unsubscribe();
  }
  fetchData() {
    this.statewisenumberofsoldlistings = [];
    this.soldlistingdetails = [];
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new StatewiseNumberOfSoldListingsAction(
          this.agencyKey,
          this.sessionID,
          this.marketingGroupDashboardID
        )
      );
      this.store.dispatch(
        new SoldListingDetailsAction(
          this.agencyKey,
          this.sessionID,
          this.marketingGroupDashboardID
        )
      );

      this.statewisenumberofsoldlistings$_Subscription =
        this.statewisenumberofsoldlistings$.subscribe(swsl => {
          this.statewisenumberofsoldlistings = JSON.parse(JSON.stringify(swsl)) as IStatewiseNumberOfSoldListings[];
          this.statewiseMonthlyNOSLShowIndex = 0;
        });

      this.soldlistingdetails$_Subscription =
        this.soldlistingdetails$.subscribe(sld => {
          if (!!sld && !!sld.length) {
            this.soldlistingdetails = sld;//.slice(0, 10);
            /*this.soldlistingdetails = Object.assign([] as ISoldListing[], sld);
            for (var i = soldlistingdetails.length; i <= 50; i++) {

              this.soldlistingdetails.push({
                id: null,
                dashboardSubgroupID: null,
                departmentSubgroupCode: null,
                departmentSubgroupName: null,
                sellerStaffID: null,
                sellerStaffName: null,
                addressID: null,
                textFormatLS: null,
                saleMethodID: null,
                saleMethodName: null,
                price: null,
                soldDate: null,
                weekStart: null,
                weekEnd: null,
                isPriceConfidential: null
              } as ISoldListing);

            }*/
          }
        });

    }
  }
  private height: number;
  soldListingScroll() {
    let scrollEndFlagReturnInterval;
    this.scrollSLInterval =
      setInterval(() => {
        if (!this.visible) {
          this.scrollSoldListingPos = 0;
          clearInterval(scrollEndFlagReturnInterval);
          return;
        }
        if (!!document.getElementById("dvSoldListings")) {
          let scrollHeight = document.getElementById("dvSoldListings").scrollHeight;
          this.height = scrollHeight;
          if (this.scrollSoldListingPos < scrollHeight) {
            if (this.scrollSoldListingPos == 0) {
              this.scrollInitialWaitTimout =
                setTimeout(() => {
                  if (!this.visible) {
                    this.scrollSoldListingPos = 0;
                    return;
                  }
                  this.scrollSoldListingPos += 1;
                  this.scrollend.emit(false);
                  if (!!document.getElementById("dvSoldListings")) {
                    document.getElementById("dvSoldListings").scrollTop = this.scrollSoldListingPos;
                  }
                  clearTimeout(this.scrollInitialWaitTimout);

                }, 5000);
            }
            else if (this.scrollSoldListingPos == (scrollHeight - 475)) {
              this.scrollSoldListingPos = scrollHeight;
              this.scrollend.emit(false);
            }
            else {
              this.scrollSoldListingPos += 1;
              this.scrollend.emit(false);
              document.getElementById("dvSoldListings").scrollTop = this.scrollSoldListingPos;
            }
          }
          else {
            this.scrollend.emit(true);
            this.scrollEndWaitTimout =
              setTimeout(() => {
                if (!this.visible) {
                  this.scrollSoldListingPos = 0;
                  return;
                }
                //this.scrollSoldListingPos = 0;
                this.scrollend.emit(true);
                //if (!!this.soldlistingdetails && this.soldlistingdetails.length < 33) {
                //  clearInterval(this.scrollSLInterval);
                //}

                scrollEndFlagReturnInterval =
                  setInterval(() => {
                    this.scrollend.emit(true);
                    //if (!!this.soldlistingdetails && this.soldlistingdetails.length < 33) {
                    //  clearInterval(this.scrollSLInterval);
                    //}

                }, 2000);
                /*if (!!document.getElementById("dvSoldListings")) {
                  document.getElementById("dvSoldListings").scrollTop = this.scrollSoldListingPos;
                }*/
                clearTimeout(this.scrollEndWaitTimout);

              }, 5000);
          }
        }
      }, 25);
  }
  statewiseMonthlyNOSLShow() {
    this.statewiseMonthlyNOSLShowInterval =
      setInterval(() => {
        if (!this.visible) {
          this.statewiseMonthlyNOSLShowIndex = 0;
          if (!!this.statewisenumberofsoldlistings && !!this.statewisenumberofsoldlistings.length) {
            this.statewisenumberofsoldlistings.forEach((value, index) => {
              value.showNumberOfSoldListings = false;
            });
            this.statewiseMonthlyNOSLShowIndex = 0;
          }
          return;
        }

        if (!!this.statewisenumberofsoldlistings && !!this.statewisenumberofsoldlistings.length) {
          if (this.statewiseMonthlyNOSLShowIndex < this.statewisenumberofsoldlistings.length) {
            this.statewisenumberofsoldlistings[this.statewiseMonthlyNOSLShowIndex++].showNumberOfSoldListings = true;
          }
          else {
            /*this.statewisenumberofsoldlistings.forEach((value, index) => {
              value.showNumberOfSoldListings = false;
            });*/
            this.statewiseMonthlyNOSLShowIndex = 0;
          }
        }

      }, 1000);

  }
  //#region Properties
  get weekStartDate(): Date {
    if (!!this.soldlistingdetails && !!this.soldlistingdetails.length) {
      return this.soldlistingdetails[0].weekStart;
    }
    else {
      return null;
    }
  }
  get weekEndDate(): Date {
    if (!!this.soldlistingdetails && !!this.soldlistingdetails.length) {
      return this.soldlistingdetails[0].weekEnd;
    }
    else {
      return null;
    }
  }
  get soldlistingCountThisWeek(): number {
    if (!!this.soldlistingdetails && !!this.soldlistingdetails.length) {
      return this.soldlistingdetails.length;
    }
    else {
      return null;
    }
  }
  //#endregion
}
