import { FormGroup } from '@angular/forms';

export function updateAndValidateForm(form: FormGroup): boolean {
  form.updateValueAndValidity();
  for (const item in form.controls) {
    if (item) {
      form.controls[item].markAsDirty();
    }
  }
  return form.valid;
}

export default updateAndValidateForm;