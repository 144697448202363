import { IDNamePairExtra } from "../app.state";
import { ActionTypes, HomeAction } from "./reports.action";
import { IReportsState, IDaysOnMarketStaff, IDaysOnMarketGraphSubGroup, IPerformanceSummary, IFinancialYear, IStateWiseFYPerformanceTarget } from "./reports.state";
const ageoflistings: IDaysOnMarketStaff[] = [];
const ageoflistingsgraphs: IDaysOnMarketGraphSubGroup[] = [];
const agencies: IDNamePairExtra<string, string>[] = [];
const staffperformances: IPerformanceSummary[] = [];
const financialyearends: IFinancialYear[] = [];
const financialyearperformancetargets: IStateWiseFYPerformanceTarget[] = [];
export const getInitialState = (): IReportsState => ({
  ageoflistings: ageoflistings,/*{
    id: "",
    staffName: "",
    address: {
      id: "",
      staffID: "",
      address: "",
      price: null,
      dateListed: new Date(),
      expiryDate: null,
      grossCom: 0,
      vc: 0,
      advSpent: 0,
      advReceived: 0
    },
    reports: [],
    showAddress: false
  },*/
  ageoflistingsgraphs: ageoflistingsgraphs,
  agencies: agencies,
  staffperformances: staffperformances,
  isAgeOfListingsProcessing: false,
  isAgeOfListingsGraphsProcessing: false,
  isAgenciesProcessing: false,
  isStaffPerformancesProcessing: false,
  financialyearends: financialyearends,
  financialyearendsProcessing: false,
  financialyearperformancetargets: financialyearperformancetargets,
  financialyearperformancetargetsProcessing: false,
  savecommissionbudget: null,
  savecommissionbudgetProcessing: false,
  errorMessage: null
});
export function reportsReducer(
  state: IReportsState,
  action: HomeAction
): IReportsState {
  switch (action.type) {
    case ActionTypes.AgeOfListings: {
      return { ...state, isAgeOfListingsProcessing: true };
    }
    case ActionTypes.AgeOfListingsSuccess: {
      return {
        ...state,
        ageoflistings: action.data,
        isAgeOfListingsProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.AgeOfListingsFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.AgeOfListingsAddressShowHide: {
      let ageoflistings = JSON.parse(JSON.stringify(state.ageoflistings));
      let ageoflisting = ageoflistings.find(aol => aol.id == action.staffID);
      if (!!ageoflisting) {
        ageoflisting.showAddress = action.show;
      }
      return {
        ...state,
        ageoflistings: ageoflistings
      };
    }
    case ActionTypes.AgeOfListingsGraph: {
      return { ...state, isAgeOfListingsGraphsProcessing: true };
    }
    case ActionTypes.AgeOfListingsGraphSuccess: {
      let subgroups: { departmentSubGroupCode: number, departmentSubGroupName: string }[] = action.data.filter((value, index) => action.data.findIndex(t => t.departmentSubGroupCode == value.departmentSubGroupCode) == index);
      let subgroupsgraph: IDaysOnMarketGraphSubGroup[] = [];
      subgroups.forEach((value, index) => {
        subgroupsgraph.push({ departmentSubGroupCode: value.departmentSubGroupCode, departmentSubGroupName: value.departmentSubGroupName, graphs: action.data.filter(d => d.departmentSubGroupCode == value.departmentSubGroupCode) });
      });
      return {
        ...state,
        ageoflistingsgraphs: subgroupsgraph,
        isAgeOfListingsGraphsProcessing: false
      };
    }
    case ActionTypes.AgeOfListingsGraphFail: {
      return {
        ...state,
        errorMessage: !!state.errorMessage ? state.errorMessage.concat("\n", action.message) : action.message
      };
    }
    case ActionTypes.StaffPerformancesAgencies: {
      return { ...state, isAgenciesProcessing: true };
    }
    case ActionTypes.StaffPerformancesAgenciesSuccess: {
      return {
        ...state,
        agencies: action.data,
        isAgenciesProcessing: false,
      };
    }
    case ActionTypes.StaffPerformancesAgenciesFail: {
      return {
        ...state,
        errorMessage: !!state.errorMessage ? state.errorMessage.concat("\n", action.message) : action.message
      };
    }
    case ActionTypes.StaffPerformances: {
      return { ...state, isStaffPerformancesProcessing: true };
    }
    case ActionTypes.StaffPerformancesSuccess: {
      return {
        ...state,
        staffperformances: action.data,
        isStaffPerformancesProcessing: false,
      };
    }
    case ActionTypes.StaffPerformancesFail: {
      return {
        ...state,
        errorMessage: !!state.errorMessage ? state.errorMessage.concat("\n", action.message) : action.message
      };
    }
    case ActionTypes.StaffPerformancesListingAchieverShowHide: {
      let staffperformances = JSON.parse(JSON.stringify(state.staffperformances));
      let staffperformance = staffperformances.find(sf => sf.id == action.typeID);
      if (!!staffperformance) {
        staffperformance.showChild = action.show;
      }
      return {
        ...state,
        staffperformances: staffperformances
      };
    }
    case ActionTypes.StaffPerformancesHighestNumberListingShowHide: {
      let staffperformances = JSON.parse(JSON.stringify(state.staffperformances));
      let staffperformance = staffperformances.find(sf => sf.id == action.typeID);
      if (!!staffperformance && !!staffperformance.listingAchievers) {
        let listingachiever = staffperformance.listingAchievers.find(la => la.typeID == action.typeID && la.staffID == action.staffID && la.departmentID == action.departmentID);
        if (!!listingachiever) {
          listingachiever.showChild = action.show;
        }
      }
      return {
        ...state,
        staffperformances: staffperformances
      };
    }
    case ActionTypes.SalesBudgetFinancialYearEnd: {
      return { ...state, financialyearendsProcessing: true };
    }
    case ActionTypes.SalesBudgetFinancialYearEndSuccess: {
      return {
        ...state,
        financialyearends: action.data,
        financialyearendsProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.SalesBudgetFinancialYearEndFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.SalesBudgetFinancialYearStateWisePerformanceTargets: {
      return { ...state, financialyearperformancetargetsProcessing: true };
    }
    case ActionTypes.SalesBudgetFinancialYearStateWisePerformanceTargetsSuccess: {
      return {
        ...state,
        financialyearperformancetargets: action.data,
        financialyearperformancetargetsProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.SalesBudgetFinancialYearStateWisePerformanceTargetsFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }
    case ActionTypes.SalesBudgetFinancialYearCommissionBudgetSave: {
      return { ...state, savecommissionbudgetProcessing: true };
    }
    case ActionTypes.SalesBudgetFinancialYearCommissionBudgetSaveSuccess: {
      return {
        ...state,
        savecommissionbudget: action.data,
        savecommissionbudgetProcessing: false,
        errorMessage: null
      };
    }
    case ActionTypes.SalesBudgetFinancialYearCommissionBudgetSaveFail: {
      return {
        ...state,
        errorMessage: action.message
      };
    }


    // this is necessary since we are using local storage to hydrate part of the state
    case '@ngrx/store/update-reducers' as any: {
      return { ...getInitialState(), ...state };
    }

    case ActionTypes.RemoveErrorMessage:
    case '@ngrx/router-store/navigated' as any: {
      return { ...state, errorMessage: null };
    }

    default: {
      return state || getInitialState();
    }

  }
}
