import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable, Subscription } from 'rxjs';
import { first, map } from "rxjs/operators";
import { DomSanitizer } from '@angular/platform-browser';

import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { LeaderBoardPdfShowAction, LeaderBoardPdfShowFailAction, LeaderBoardQuoteShowAction } from '../group.action';
import { leaderboardpdfshow, leaderboardpdfshowProcessing, leaderboardquoteshow, leaderboardquoteshowProcessing } from '../group.selectors';

@Component({
  selector: 'multiarray-leaderboardshowpdf',
  templateUrl: './leaderboardshowpdf.component.html',
  styleUrls: ['./leaderboardshowpdf.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class LeaderboardShowPdfComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private store: Store<IAppState>,
    private encryption: EncryptionService,
    private dom: DomSanitizer
  ) {
    super(activatedRoute, snackBar, encryption);
  }
  @Input() visible: boolean = false;
  @Output() pdfloaded = new EventEmitter<boolean>();
  private pdfUrl: any;
  private dataRefreshed: boolean = false;

  private leaderboardpdfshowProcessing$ = this.store.pipe(select(leaderboardpdfshowProcessing));
  private leaderboardpdfshow$ = this.store.pipe(select(leaderboardpdfshow));
  private leaderboardquoteshowProcessing$ = this.store.pipe(select(leaderboardquoteshowProcessing));
  private leaderboardquoteshow$ = this.store.pipe(select(leaderboardquoteshow));

  private leaderboardpdfshow$_Subscription: Subscription;

  ngOnInit(): void {
    //#region Data refresh when slide is visible
    setInterval(() => {
      if (this.visible) {
        let auth = JSON.parse(localStorage.getItem("auth"));
        this.dataRefreshed = !!JSON.parse(localStorage.getItem("quoteOfDayDataRefreshed")) ? JSON.parse(localStorage.getItem("quoteOfDayDataRefreshed")) : false;
        if (!!auth && !this.dataRefreshed) {
          /* today = new Date();
          let refreshTime: number = parseInt("0320");
          let currentTime: number = parseInt(today.getHours().padLeft(2, "0") + today.getMinutes().padLeft(2, "0"));
          if (currentTime >= parseInt("0300") && currentTime <= refreshTime) {*/
          this.fetchData();
          this.dataRefreshed = true;
          localStorage.setItem("quoteOfDayDataRefreshed", "true");
          window.location.reload();
          //}
        }
      }
      else {
        this.dataRefreshed = false;
        localStorage.setItem("quoteOfDayDataRefreshed", "false");
      }

    }, 1000);
    //#endregion
    setTimeout(() => {
      this.fetchData();
    }, 1000);

  }
  ngOnDestroy(): void {
    if (!!this.leaderboardpdfshow$_Subscription) this.leaderboardpdfshow$_Subscription.unsubscribe();
  }
  carriageReturnNewline(text): string {
    if (!text || !text.length) {
      return text;
    }
    return text.replace(/(\\r\\n)|([\r\n])/gmi, '<br/>');
  }
  fetchData() {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      /*
      this.store.dispatch(
        new LeaderBoardPdfShowAction(
          this.agencyKey,
          this.marketingGroupDashboardID
        )
      );

      this.leaderboardpdfshow$_Subscription =
        this.leaderboardpdfshow$
          .subscribe(file => {
            if (!!file) {
              this.pdfUrl = null
              if (!!file.fileAsBase64) {
                this.pdfUrl = this.dom.bypassSecurityTrustResourceUrl(`${file.pdfUrl}#toolbar=0&navpanes=0&scrollbar=0&view=Fit`);
                setTimeout(() => {
                  this.pdfloaded.emit(true);
                }, 10000);
              }
            }
          });
      */
      this.store.dispatch(
        new LeaderBoardQuoteShowAction(
          this.agencyKey,
          this.marketingGroupDashboardID
        )
      );

      setTimeout(() => {
        this.pdfloaded.emit(true);
      }, 15000);

    }
  }
}
