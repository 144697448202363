import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable } from 'rxjs';
import { first, map } from "rxjs/operators";

import { ComponentBase } from '../../util/componentBase';
import { IAppState } from '../../app.state';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { AgeOfListingsAction, AgeOfListingsAddressShowHideAction } from '../reports.action';
import { ageoflistings, isAgeOfListingsProcessing } from '../reports.selectors';
import { IDaysOnMarketStaff } from '../reports.state';


@Component({
  selector: 'app-ageoflistings',
  templateUrl: './ageoflistings.component.html',
  styleUrls: ['./ageoflistings.component.scss']
})
export class AgeOfListingsComponent extends ComponentBase implements OnInit {

  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
    ) {
    super(activatedRoute, snackBar, encryption);
  }

  private ageoflistings$ = this.store.pipe(select(ageoflistings));
  private isAgeOfListingsProcessing$ = this.store.pipe(select(isAgeOfListingsProcessing));

  ngOnInit(): void {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new AgeOfListingsAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          this.marketingGroupDashboardID
        )
      );
    }
  }

  onShowHideAddressClick(staff: IDaysOnMarketStaff) {
    this.store.dispatch(
      new AgeOfListingsAddressShowHideAction(
        staff.id,
        !staff.showAddress
      )
    );
  }

}
