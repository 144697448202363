import { Observable } from 'rxjs';
import { IDNamePairExtra } from '../app.state';
import {
  IFileToUpload
  , IGCILeader
  , IGCIStateStaffLeader
  , IGCIStateStaffDepartmentSubgroup
  , ILeaderboardPdf
  , ILeaderBoardQuoteOfTheDayText
  , INumberOfSalesLeader
  , IStatewiseNumberOfNewListings
  , INewListing
  , IStatewiseNumberOfSoldListings
  , ISoldListing
  , IActualVsBudget
} from './group.state';

export abstract class GroupService {

  /**
  * Fetches LeaderBoard GCI graph data
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param marketingGroupDashboardID Marketing Group Dashboard ID
  * @param date Optional date of a financial year
  */
  public abstract gciLeaderBoardGraph(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    date: Date | null
  ): Observable<IGCILeader[]>;

  /**
  * Fetches LeaderBoard Number Of Sales graph data
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param marketingGroupDashboardID Marketing Group Dashboard ID
  * @param date Optional date of a financial year
  */
  public abstract numberOfSalesLeaderBoardGraph(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    date: Date | null
  ): Observable<INumberOfSalesLeader[]>;

  /**
  * Fetches LeaderBoard GCI state staff data
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param marketingGroupDashboardID Marketing Group Dashboard ID
  */
  public abstract gciLeaderBoardStateStaff(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IGCIStateStaffDepartmentSubgroup[]>;

  /**
  * Upload leaderboard pdf
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param file File to upload details
  */
  public abstract uploadLeaderBoardPdf(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    file: ILeaderboardPdf
  ): Observable<ILeaderboardPdf[]>;

  /**
  * Delete leaderboard pdf
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param id File id to delete
  */
  public abstract deleteLeaderBoardPdf(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    id: string
  ): Observable<ILeaderboardPdf[]>;

  /**
  * Get leaderboard pdf
  *
  * @param agencyKey Agency key
  */
  public abstract getLeaderBoardPdf(
    agencyKey: string,
    marketingGroupDashboardID: string,
    id: string
  ): Observable<ILeaderboardPdf>;

  /**
  * Get all leaderboard pdfs
  *
  * @param agencyKey Agency key
  */
  public abstract getLeaderboardPDFs(
    agencyKey: string,
    marketingGroupDashboardID: string
  ): Observable<ILeaderboardPdf[]>;



  /**
  * Save leaderboard quote
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param quoteOfTheDay Quote of the day text to save
  * @param quoteWrittenBy Quote write by
  * @param sortOrder Sort order of the quote to show in the list
  * @param id Optional id of the saved quote
  */
  public abstract leaderboardQuoteSave(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    quoteOfTheDay: string,
    quoteWrittenBy: string,
    sortOrder: number,
    id: string
  ): Observable<ILeaderBoardQuoteOfTheDayText[]>;

  /**
  * Delete leaderboard quote
  *
  * @param agencyKey Agency key
  * @param agencyID Agency id
  * @param sessionID Login session id
  * @param id Quote id to delete
  */
  public abstract leaderboardQuoteDelete(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string,
    id: string
  ): Observable<ILeaderBoardQuoteOfTheDayText[]>;




  /**
* Get leaderboard quote of the day
*
* @param agencyKey Agency key
*/
  public abstract getLeaderboardQuote(
    agencyKey: string,
    marketingGroupDashboardID: string,
    id: string
  ): Observable<ILeaderBoardQuoteOfTheDayText>;

  /**
  * Get all leaderboard quotes
  *
  * @param agencyKey Agency key
  */
  public abstract getLeaderboardQuotes(
    agencyKey: string,
    marketingGroupDashboardID: string
  ): Observable<ILeaderBoardQuoteOfTheDayText[]>;

  /**
  * Fetches State wise number of listings
  *
  * @param agencyKey Agency key
  * @param sessionID Login session id
  * @param marketingGroupDashboardID Marketing Group Dashboard ID
  */
  public abstract statewiseNumberOfNewListings(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IStatewiseNumberOfNewListings[]>;
  /**
  * Fetches details of new listings
  *
  * @param agencyKey Agency key
  * @param sessionID Login session id
  * @param marketingGroupDashboardID Marketing Group Dashboard ID
  */
  public abstract newListingDetails(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<INewListing[]>;


  /**
  * Fetches State wise number of sold listings
  *
  * @param agencyKey Agency key
  * @param sessionID Login session id
  * @param marketingGroupDashboardID Marketing Group Dashboard ID
  */
  public abstract statewiseNumberOfSoldListings(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IStatewiseNumberOfSoldListings[]>;
  /**
    * Fetches details of new listings
    *
    * @param agencyKey Agency key
    * @param sessionID Login session id
    * @param marketingGroupDashboardID Marketing Group Dashboard ID
    */
  public abstract soldListingDetails(
    agencyKey: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<ISoldListing[]>;

 /**
 * Fetches Actual vs Budget this month graph data
 *
 * @param agencyKey Agency key
 * @param agencyID Agency id
 * @param sessionID Login session id
 * @param marketingGroupDashboardID Marketing Group Dashboard ID
 */
  public abstract actualVsBudgetThisMonth(
    agencyKey: string,
    agencyID: string,
    sessionID: string,
    marketingGroupDashboardID: string
  ): Observable<IActualVsBudget[]>;

}
