import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable, of } from 'rxjs';
import { first, map } from "rxjs/operators";

import { IAppState } from '../../app.state';
import { ComponentBase } from '../../util/componentBase';
import { EncryptionService } from '../../util/encryption/encryption.Service';
import { AgeOfListingsGraphAction } from '../reports.action';
import { ageoflistingsgraphs, isAgeOfListingsGraphsProcessing } from '../reports.selectors';
import { IDaysOnMarketGraph } from '../reports.state';


@Component({
  selector: 'app-ageoflistingschart',
  templateUrl: './ageoflistingschart.component.html',
  styleUrls: ['./ageoflistingschart.component.scss']
})
export class AgeOfListingsChartComponent extends ComponentBase implements OnInit {

  constructor
    (
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private store: Store<IAppState>,
      private encryption: EncryptionService
    ) {
    super(activatedRoute, snackBar, encryption);
  }

  private ageoflistingsgraphs$ = this.store.pipe(select(ageoflistingsgraphs));
  private isAgeOfListingsGraphsProcessing$ = this.store.pipe(select(isAgeOfListingsGraphsProcessing));
  private backgroundColors: string[] = ['#ec0a0a', '#ecb10a', '#deec0a', '#57ec0a', '#0aeccd', '#0a84ec', '#0a11ec', '#950aec', '#ec0abb', '#7d346e', '#5a347d', '#345f7d', '#347d73', '#347d4a', '#7d7b34', '#7d5934'];
  private hoverBackgroundColors: string[] = ['#ec0a0abf', '#ecb10ad4', '#deec0ad9', '#57ec0ad9', '#0aeccdd6', '#0a84ece0', '#0a11ecd9', '#950aecd4', '#ec0abbd9', '#6f195dd4', '#4b2d67', '#203b4e', '#204a44', '#275d37', '#5d5b27', '#5f4327'];
  private chartType: string = "pie";
  private chartOptions = {/*https://www.chartjs.org/docs/latest/configuration/legend.html*/
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Age of Listings",
        color: "#ffffff",
        font: { weight: 'bold', size: 30 },
      },
      weight: 5,
      animation: {
        animateRotate: true,
        animateScale: true,
      },
      legend: {
        display: true,
        labels: {
          color: "#ffffff"
        },
      },
      scaleLabel: {
        fontColor: "#ffffff"
      }
    }
  };
  private chartColors = [
    {
      backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1'],
      hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5'],
      borderWidth: 2,
    },
  ];
  private ageoflistingsgraphs: IChartDataSource[] = [];
  ngOnInit(): void {
    let auth = JSON.parse(localStorage.getItem("auth"));
    if (!!auth) {
      this.store.dispatch(
        new AgeOfListingsGraphAction(
          this.agencyKey,
          this.agencyID,
          this.sessionID,
          this.marketingGroupDashboardID
        )
      );
      setTimeout(() => {
        this.chartColors[0].backgroundColor = [];
        this.chartColors[0].hoverBackgroundColor = [];
        this.backgroundColors = this.backgroundColors.shuffle();

        this.ageoflistingsgraphs$.subscribe(aolg => {
          aolg.forEach((value, index) => {
            this.ageoflistingsgraphs.push(
              {
                departmentSubGroupCode: value.departmentSubGroupCode,
                departmentSubGroupName: value.departmentSubGroupName,
                graphs: value.graphs,
                labels: this.getGraphLabels(value.graphs)
              });
            value.graphs.forEach((item, index) => {
              if (index < this.backgroundColors.length) {
                this.chartColors[0].backgroundColor.push(this.backgroundColors[index]);
              }
              if (index < this.hoverBackgroundColors.length) {

                if (index < this.backgroundColors.length) {
                  this.chartColors[0].hoverBackgroundColor.push(this.hoverBackgroundColors[index]);
                }
              }
            });
          });

        });

      }, 2000);
    }
  }
  getGraphLabels(values: IDaysOnMarketGraph[]): string[] {
    let tempValues: IDaysOnMarketGraph[] = JSON.parse(JSON.stringify(values));
    let labels: Array<string> = tempValues.sort((first, next) => first.periodID > next.periodID ? -1 : 1).map(value => value.daysOnMarket);
    return labels;
  }
  getGraphData(data: IChartDataSource): { data: number[], label: string }[] {
    let tempValues: IDaysOnMarketGraph[] = JSON.parse(JSON.stringify(data.graphs));
    let result = [{ data: tempValues.sort((first, next) => first.periodID > next.periodID ? -1 : 1).map(value => value.numberOfListings), label: data.departmentSubGroupName }];
    return result;
  }
}
interface IChartDataSource {
  departmentSubGroupCode: number | null,
  departmentSubGroupName: string,
  graphs: IDaysOnMarketGraph[],
  labels: string[]
}
