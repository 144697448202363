import { Action } from '@ngrx/store';
import { IAllowedReport, IReportDetails } from './home.state';

export enum ActionTypes {
  AllowedReport = '[HOME] Fetching Allowed Reports',
  AllowedReportSuccess = '[HOME] Allowed Report Fetching Success',
  AllowedReportFail = '[HOME] Allowed Report Fetching Fail',
  ReportDetails = '[HOME] Fetching Report Details',
  ReportDetailsSuccess = '[HOME] Report Details Fetching Success',
  ReportDetailsFail = '[HOME] Report Details Fetching Fail',
  RemoveErrorMessage = '[HOME] Emptied Error Message'
}
export class AllowedReportAction implements Action {
  readonly type = ActionTypes.AllowedReport;

  constructor(
    public readonly agencyKey: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
  ) { }
}
export class AllowedReportSuccessAction implements Action {
  readonly type = ActionTypes.AllowedReportSuccess;

  constructor(
    public readonly data: IAllowedReport[]
  ) { }
}
export class AllowedReportFailAction implements Action {
  readonly type = ActionTypes.AllowedReportFail;

  constructor(public readonly message: string) { }
}
export class ReportDetailsAction implements Action {
  readonly type = ActionTypes.ReportDetails;

  constructor(
    public readonly agencyKey: string,
    public readonly agencyID: string,
    public readonly sessionID: string,
    public readonly marketingGroupDashboardID: string,
    public readonly reportID: number
  ) { }
}
export class ReportDetailsSuccessAction implements Action {
  readonly type = ActionTypes.ReportDetailsSuccess;

  constructor(
    public readonly reportID: number,
    public readonly data: IReportDetails[]
  ) { }
}
export class ReportDetailsFailAction implements Action {
  readonly type = ActionTypes.ReportDetailsFail;

  constructor(
    public readonly reportID: number,
    public readonly message: string
  ) { }
}
export class RemoveErrorMessageAction implements Action {
  readonly type = ActionTypes.RemoveErrorMessage;

  constructor() { }
}
export type HomeAction =
  | AllowedReportAction
  | AllowedReportSuccessAction
  | AllowedReportFailAction
  | ReportDetailsAction
  | ReportDetailsSuccessAction
  | ReportDetailsFailAction
  | RemoveErrorMessageAction;
